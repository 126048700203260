import "./Privacy.scss";

function Privacy(prop) {
  return (
    <div className="container py-4 header_title_caption">
      <div className="container-fluid">
        <div className="card sshadow-sm obx-blue p-4 mb-4">
          <div className="row">
            <div className="col obx-white header_title_head">
              <h4>Website Data Protection and Privacy Policy</h4>
            </div>
          </div>
        </div>
        <div className="custom">
        <div className="py-3">
          <h4>1. Introduction</h4>
          <text>
            This Privacy Policy outlines how we collect, use, and protect
            personal information provided by clients through our website. We are
            committed to safeguarding the privacy and security of our clients'
            data. By using our website and providing personal information,
            clients agree to the terms outlined in this policy.
          </text>
        </div>
        <div className="py-3">
          <h4>2. Scope</h4>
          <text>
            This policy applies to all clients who interact with our website and
            provide personal information through forms or other means. It covers
            all personal data collected, processed, stored, or transmitted on
            our website.
          </text>
        </div>
        <div className="py-3">
          <h4>3. Information We Collect</h4>
          <text>
            We may collect the following types of personal information from
            clients: Contact Information: Name, email address, phone number, and
            mailing address. Business Information: Company name, industry, role,
            and other relevant details. Transaction Details: Information related
            to business transactions, orders, and inquiries. Communication
            Records: Information exchanged during communications with clients.
          </text>
        </div>
        <div className="py-3">
          <h4>4. Use of Personal Information</h4>
          <text>
            We may collect the following types of personal information from
            clients: Contact Information: Name, email address, phone number, and
            mailing address. Business Information: Company name, industry, role,
            and other relevant details. Transaction Details: Information related
            to business transactions, orders, and inquiries. Communication
            Records: Information exchanged during communications with clients.
          </text>
        </div>
        <div className="py-3">
          <h4>5. Data Security</h4>
          <text>
            We implement technical and organizational measures to protect
            clients' personal information from unauthorized access, loss,
            disclosure, alteration, or destruction. However, no method of data
            transmission over the internet is entirely secure, and we cannot
            guarantee absolute security.
          </text>
        </div>
        <div className="py-3">
          <h4>6. Data Sharing</h4>
          <text>
            We do not sell, rent, or trade clients' personal information to
            third parties. We may share information with trusted service
            providers who assist us in delivering our services, subject to
            confidentiality and data protection agreements.
          </text>
        </div>
        <div className="py-3">
          <h4>7. Cookies and Tracking Technologies</h4>
          <text>
            Our website may use cookies and tracking technologies to improve
            user experience and collect analytical data. Clients can manage
            their cookie preferences through their browser settings.
          </text>
        </div>
        <div className="py-3">
          <h4>8. Client Rights</h4>
          <text>
            Clients have the right to access, correct, update, or delete their
            personal information. Requests to exercise these rights can be
            submitted through the contact information provided on the website.
          </text>
        </div>
        <div className="py-3">
          <h4>9. Third-Party Links</h4>
          <text>
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices or content of these websites.
            Clients are encouraged to review the privacy policies of those
            websites.
          </text>
        </div>
        <div className="py-3">
          <h4>10. Data Retention</h4>
          <text>
          We retain clients' personal information for as long as necessary to fulfill the purposes outlined in this policy, or as required by law.</text>
        </div>
        <div className="py-3">
          <h4>11. Data Breach Notification</h4>
          <text>
          In the event of a data breach involving clients' personal information, affected clients and relevant regulatory authorities will be notified as required by applicable laws and regulations.</text>
        </div>
        <div className="py-3">
          <h4>12. Updates to the Policy</h4>
          <text>
          This policy may be updated to reflect changes in data protection laws or our data processing practices. Clients will be informed of any significant updates.</text>
        </div>
        <div className="py-3">
          <h4>13. Compliance and Monitoring</h4>
          <text>
          We regularly review and assess our data protection practices to ensure compliance with this policy and relevant data protection laws.</text>
        </div>
        <div className="py-3">
          <h4>14. Contact Information</h4>
          <text>
          For questions, concerns, or requests related to data protection, clients can contact us using the provided contact information on the website.</text>
        </div>
        <div>
          <h4>15. Conclusion</h4>
        </div>
        <div>
          <text>
          This Privacy Policy underscores our commitment to safeguarding the personal information provided by clients on our website. By adhering to these principles and practices, we aim to maintain a secure and respectful environment for our clients' data and interactions with our website.</text>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;

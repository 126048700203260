import Logo from "../../../assets/images/obx-dark-logo.png";
import image1 from "../../../assets/images/Mar-Business_18.jpg";
import gif from "../../../assets/images/7efs.gif";
import { useLocation, useHistory } from "react-router-dom";
import { useMemo, useEffect, useState } from "react";
import AuthenticateAPI from "../../../apis/authenticate";
import { Link } from "react-router-dom";

const VerifyUser = () => {
  const { search } = useLocation();
  const data = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const userID = data.get("id");
  const hasIdParameter = data.has("id");
  const [verificationStatus, setVerificationStatus] = useState(false);

  useEffect(() => {
    AuthenticateAPI.VerifyEmail(userID)
      .then((response) => {
        setVerificationStatus(true);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (!hasIdParameter) {
      history.push("/login");
    }
  }, [hasIdParameter, history]);

  return (
    <>
      <div>
        <div style={{ textAlign: "center" }}>
          <img src={gif} style={{ width: 200 }} />
        </div>
        <h3
          style={{ marginTop: "-26px", marginBottom: 26 }}
          className='text-center'
        >
          Verification Successful
        </h3>
        <div style={{ marginTop: 10, textAlign: "center" }}>
          <Link
            to='/login'
            className='btn btn-lg btn-success'
            style={{ textAlign: "center" }}
          >
            Ok
          </Link>
        </div>
      </div>
    </>
  );
};

export default VerifyUser;

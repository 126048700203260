import { useEffect, useState } from "react";
import CommonUtils from "../../../utility/common-utilities";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const SiteDetails = (props) => {
  const [payload, setPayload] = useState([{ key: Math.random() }]);
  const [errorKey, setErrorKey] = useState("");
  const [errorIndex, setErrorIndex] = useState();

  useEffect(() => {
    setPayload(props.payload || [{ key: Math.random() }]);
  }, []);

  const handlePayloadChange = (value, key, index) => {
    let payloadObj = [...payload];
    payloadObj[index][key] = value;

    if (key === 'maxVisit') {
      payloadObj[index][key] = parseInt(value) ?? 0;
    }
    setPayloadObject(payloadObj);
  };

  const setPayloadObject = (payloadObj) => {
    setPayload(payloadObj);
    setErrorKey("");
    props.emitChanges && props.emitChanges(payloadObj, "6");
  };

  const handleNext = () => {
    for (let i = 0; i < payload?.length; i++) {
      let validation = CommonUtils.validateCreateTenderPayload(payload[i], 6);

      if (validation?.error) {
        setErrorKey(validation.key);
        setErrorIndex(i);
        return;
      }
    }
    props.next();
  };

  const handleDateEvent = (event, picker, index) => {
    let payloadObj = [...payload];
    payloadObj[index].startDate = picker.startDate.toDate();
    payloadObj[index].endDate = picker.endDate.toDate();

    setPayloadObject(payloadObj);
   
  };

 

  const onClickAdd = () => {
    for (let i = 0; i < payload?.length; i++) {
      let validation = CommonUtils.validateCreateTenderPayload(payload[i], 6);

      if (validation?.error) {
        setErrorKey(validation.key);
        setErrorIndex(i);
        return;
      }
    }
    let payloadObj = [...payload, { key: Math.random() }];
    setPayloadObject(payloadObj);
  };

  const onClickRemove = (index) => {
    let payloadObj = [...payload];

    let len = payloadObj.length;

    if (len === 1) {
      payloadObj = [{ key: Math.random() }];
    } else {
      payloadObj.splice(index, 1);
    }
    setPayloadObject([...payloadObj]);
  };

  return (
    <div>
      <span className='h6 header_title_caption fs-5 '>
        Schedule a Site Visit (Optional)
      </span>
      <hr></hr>
      <div className='card mt-3  header_title_caption tooltip-background-color'>
        <div className='card-body p-4'>
          {/* <div className="row">
            <div className="col">
              <h6 className="card-title mb-4">
                <i className="me-2 bi bi-info-circle"></i>Performance Metrics
                are configured in 3 stages
              </h6>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-1"></div>
            <div className="col-11">
              <div>
                <small>1. Select a KPI for each outcome</small>
              </div>
              <div>
                <small>2. Set the performance requirements for each KPI</small>
              </div>
              <div>
                <small>
                  <strong>
                    3. [Current] Set Contract Sum levels based on the total
                    score across all KPIs
                  </strong>
                </small>
              </div>
            </div>
          </div> */}
          <div className=''>
            <small>Schedule site visits for service providers to attend.</small>
          </div>
        </div>
      </div>
      {payload?.length
        ? payload.map((item, index) => (
            <div className='card mt-4 table_style' key={item.key}>
              <div className='card-body header_title_caption'>
                {payload?.length >= 1 && item?.startDate ? (
                  <div
                    className='cross-icon'
                    onClick={() => onClickRemove(index)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                ) : (
                  ""
                )}
                <div className='row'>
                  <div className='col-md-5 col-lg-5 col-sm-12'>
                    <DateRangePicker
                      onHide={(event, picker) =>
                        handleDateEvent(event, picker, index)
                      }
                      onCancel={() => {}}
                      autoApply
                      initialSettings={{
                        singleDatePicker: false,
                        timePicker: true,
                        timePicker24Hour: true,
                        autoUpdateInput: false,
                      }}
                      locale={{
                        format: "DD/MM/YYYY",
                      }}
                    >
                      <div className='mb-3'>
                        <label
                          htmlFor='formControlInput2'
                          className='form-label'
                        >
                          Date
                        </label>
                        <input
                          type='text'
                          className={`form-control ${
                            (errorKey == "startDate" ||
                              errorKey == "endDate") &&
                            errorIndex === index
                              ? "invalid-input"
                              : ""
                          }`}
                          placeholder='Visit Time'
                          value={
                            item.startDate
                              ? `${CommonUtils.datePipe(
                                  item?.startDate,
                                  "dd/MM/yyyy, hh:mm"
                                )} - ${CommonUtils.datePipe(
                                  item?.endDate,
                                  "dd/MM/yyyy, hh:mm"
                                )}`
                              : ""
                          }
                        />
                      </div>
                    </DateRangePicker>
                  </div>

                  <div className='col-md-4 col-lg-4 col-sm-12'>
                    <div className='mb-3'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Location
                      </label>
                      <textarea
                        type='text'
                        className={`form-control ${
                          errorKey == "location" && errorIndex === index
                            ? "invalid-input"
                            : ""
                        }`}
                        id='facilityControl'
                        value={item?.location}
                        onChange={(e) =>
                          handlePayloadChange(
                            e?.target?.value,
                            "location",
                            index
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className='col-md-2 col-lg-2 col-sm-12'>
                    <div className='mb-3'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Maximum Visitor
                      </label>
                      <input
                        type='number'
                        className={`form-control ${
                          errorKey == "maxVisit" && errorIndex === index
                            ? "invalid-input"
                            : ""
                        }`}
                        id='facilityControl'
                        value={item?.maxVisit}
                        onChange={(e) =>
                          handlePayloadChange(
                            e?.target?.value,
                            "maxVisit",
                            index
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : ""}
      <div className='text-center pt-4'>
        <button
          onClick={onClickAdd}
          type='button'
          className='btn btn-sm btn-success'
        >
          + Add
        </button>
      </div>

      <div className='row mt-4'>
        <div className='col'>
          <button
            onClick={props.back}
            type='button'
            className='btn btn-md btn-light float-left'
          >
            Back
          </button>
        </div>
        <div className='col'>
          <button
            onClick={handleNext}
            type='button'
            className='btn btn-md btn-primary obx-btn-color float-end'
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SiteDetails;

import "./questionersi.css";
// import {qyesStyle} from "./questionersi.module.css"
import { auto } from "@popperjs/core";
import { useEffect, useState } from "react";
import CommonUtils from "../../../utility/common-utilities";

const Questionnaire = (props) => {
  const [payload, setPayload] = useState({});
  const [errorKey, setErrorKey] = useState("");
  const [Proposal, setProposal] = useState([
    { question: "Analysis of risk and operational considerations", ans: false },
    {
      question:
        "Operational model (manpower, processes, infrastructure, technology)",
      ans: false,
    },
    { question: "Technology solutions", ans: false },
    {
      question:
        "Transition Plan (how services & technology will be implemented during transition)",
      ans: false,
    },
    {
      question: "Data collection plan",
      ans: false,
    },
    {
      question: "Other Proposal Documents",
      ans: false,
    },
  ]);
  const praposal = [
    "Tenderer to submit an analysis of security risk and operational considerations in relation to the Tender Specifications and site surveys.",
    "Tenderer is required to study the site and submit a proposal for the operational model covering how manpower, processes,  infrastructural and technology will be integrated to meet the security outcomes",
    "Tenderer must submit a resource plan listing out the optimum manpower structure, equipment and technologies that will becommitted to the operations",
    "Tenderer is to submit a transition plan illustrating how services and technology will be implemented during transition. The planshall include clear timeline and milestone of key activities when taking over.",
    "Tenderer is to submit a plan detailing how they will collect and disclose data each month to support the accurate measurement of KPIs",
    "",
  ];
  const [comapny_particualr, setCompany_particular] = useState([
    { question: "Latest ACRA Business Profile", ans: false },
    { question: "Testimonials and Project References", ans: false },
    { question: "Training & Competency Assessment Plans", ans: false },
    { question: "Certifications, Expertise Attained", ans: false },
    {
      question: "HR/Company Policies on Working Conditions and Officer Welfare",
      ans: false,
    },
    { question: "Audited Financial Statements", ans: false },
  ]);

  useEffect(() => {
    setPayload(props.payload || {});
  }, []);

  const Perposaltoggle = (event) => {
    const { name, checked, value } = event.target;

    setProposal((prevPropsal) =>
      prevPropsal.map((data) =>
        data.question === value ? { ...data, ans: checked } : data
      )
    );
  };

  const cmpyToggle = (event) => {
    const { name, checked, value } = event.target;
    setCompany_particular((prevPropsal) =>
      prevPropsal.map((data) =>
        data.question === value ? { ...data, ans: checked } : data
      )
    );
  };
  const setPayloadObject = (payloadObj) => {
    setPayload(payloadObj);
    setErrorKey("");
    props.emitChanges && props.emitChanges(payloadObj, "1");
  };

  const handleNext = () => {
    // let validation = CommonUtils.validateCreateTenderPayload(payload, 1);
    // if (validation?.error) {
    //   setErrorKey(validation.key);
    //   return;
    // }
    const payloadObj = {
      proposal: Proposal,
      comapny_particualr: comapny_particualr,
    };
    // alert("95");
    props.emitChanges && props.emitChanges(payloadObj, 9);
    props.next();
  };

  return (
    <div>
      <span className="h6 header_title_caption fs-5">
        Submission Requirements
      </span>
      <hr></hr>
      <div className="card mt-3  header_title_caption tooltip-background-color">
        <div className="card-body p-4">
          {/* <div className="row">
            <div className="col">
              <h6 className="card-title mb-4">
                <i className="me-2 bi bi-info-circle"></i>Performance Metrics
                are configured in 3 stages
              </h6>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-1"></div>
            <div className="col-11">
              <div>
                <small>1. Select a KPI for each outcome</small>
              </div>
              <div>
                <small>2. Set the performance requirements for each KPI</small>
              </div>
              <div>
                <small>
                  <strong>
                    3. [Current] Set Contract Sum levels based on the total
                    score across all KPIs
                  </strong>
                </small>
              </div>
            </div>
          </div> */}
          <div className="">
            <small>
              Please select if you require any of the following documents in
              bids for your tender.
            </small>
          </div>
        </div>
      </div>
      <div>
        <div className="row mt-4 ">
          <div className="toggle-bar-wrap">
            <h4>A. Proposal Documents</h4>

            <table
              className="table"
              style={{
                counterReset: "list",
                listStyle: "none",
                border: "1px solid black",
              }}
            >
              <thead>
                <tr className="text-center header_title_caption tables_header_rowborderbottom all-tables_header_only-bg text-light">
                  <th colSpan={2}>Document Type</th>
                  <th colSpan={3}>Description</th>
                  <th colSpan={1}>Required</th>
                </tr>
              </thead>
              <tbody>
                {Proposal.map((data, index) => {
                  return (
                    <tr
                      className={
                        index === Proposal.length - 1
                          ? `all_document_upload_hide`
                          : `""`
                      }
                      style={{
                        counterIncrement: "list",
                        marginBottom: "10px",
                        background: `${
                          index % 2 === 0 ? `#f5ffff` : `#E9FBFA`
                        }`,
                      }}
                    >
                      <td colSpan={2} className="fw-bold">
                        {data.question}
                      </td>
                      <td colSpan={3} style={{ textAlign: "justify" }}>
                        {praposal[index]}
                      </td>
                      <td className="p-0 text-center" colSpan={1}>
                        <input
                          type="checkbox"
                          name={`proposal_${index + 1}_ans`}
                          value={data.question}
                          checked={data.ans}
                          onChange={Perposaltoggle}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* <ol style={{ counterReset: "list", listStyle: "none" }}>
              {Proposal.map((data, index) => {
                return (
                  <>
                    <li
                      style={{
                        counterIncrement: "list",
                        marginBottom: "10px",
                      }}
                    >
                      {data.question}
                      <span>
                      
                        <input
                          type='checkbox'
                          name={`proposal_${index + 1}_ans`}
                          value={data.question}
                          checked={data.ans}
                          onChange={Perposaltoggle}
                        />
                      </span>
                    </li>
                  </>
                );
              })}
            </ol> */}

            <h4 className="mt-5">B. Company particulars</h4>
            <table
              className="table"
              style={{
                counterReset: "list",
                listStyle: "none",
                border: "1px solid black",
              }}
            >
              <thead>
                <tr className="text-center header_title_caption tables_header_rowborderbottom all-tables_header_only-bg text-light">
                  <th colSpan={5}>Document Type</th>
                  <th colSpan={1}>Required</th>
                </tr>
              </thead>
              <tbody>
                {comapny_particualr.map((data, index) => {
                  return (
                    <tr
                      style={{
                        counterIncrement: "list",
                        marginBottom: "10px",
                        background: `${
                          index % 2 === 0 ? `#f5ffff` : `#E9FBFA`
                        }`,
                      }}
                    >
                      <td colSpan={5} className="fw-bold">
                        {data.question}
                      </td>
                      <td className="p-0 text-center" colSpan={1}>
                        <input
                          type="checkbox"
                          name={`comapny_particular_${index + 1}_ans`}
                          value={data.question}
                          checked={data.ans}
                          onChange={cmpyToggle}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <ol style={{ counterReset: "list", listStyle: "none" }}>
              {comapny_particualr.map((data, index) => {
                return (
                  <>
                    <li
                      style={{
                        counterIncrement: "list",
                        marginBottom: "10px",
                      }}
                    >
                      {data.question}
                      <span>
                        <input
                          type="checkbox"
                          name={`comapny_particular_${index + 1}_ans`}
                          value={data.question}
                          checked={data.ans}
                          onChange={cmpyToggle}
                        />
                      </span>
                    </li>
                  </>
                );
              })}
            </ol> */}
          </div>
          <div className="row mt-4">
            <div className="col">
              <button
                onClick={props.back}
                type="button"
                className="btn btn-md btn-light float-left"
              >
                Back
              </button>
            </div>
            <div className="col">
              <button
                onClick={handleNext}
                type="button"
                className="btn btn-md btn-primary obx-btn-color float-end"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;

import { useEffect, useState } from "react";
import "./profile.scss";
import { Container } from "react-bootstrap";
import Urls from "../../../apis/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { getImageSource } from "../../../utility/image-source";
import ImageConstants from "../../../constants/image-constants";
import ChangePassword from "./change-password/change-password";
import AuthenticateAPI from "../../../apis/authenticate";
import Path from "../../routes/routes-path";
import KPI from "./kpi/kpi";
import SessionCache from "../../routes/session-cache";
import axios from "axios";
import { Form } from "react-bootstrap";
import CommonUtils from "../../../utility/common-utilities";

const Profile = (props) => {
  const token = window.localStorage.getItem("user");
  const [showChangePassword, setChangePassword] = useState(false);
  const [showKpi, setShowKpi] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isError, setError] = useState(false);

  const [name, setName] = useState();
  const [company, setCompany] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [uanNo, setUanNo] = useState("");
  const [manpower, setManpower] = useState(">500")
  const [OBCReady, setOBCReady] = useState(false)
  const [awards, setAwards] = useState({
    ssia2023: null,
    ssia2022: null,
    goldenCircle2023: null
  })
  const [financialGrade, setFinancialGrade] = useState("")
  const [FGAmount, setFGAmount] = useState("")
  const [plrd, setPlrd] = useState([])

  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState({});
  const [imageSrc, setImageSrc] = useState("");
  const [toggle, settoggle] = useState(false);
  const [manpowers, setManpowers] = useState([
    {value: "<200", label: "Below 200"},
    {value: "200-500", label: "200 - 500"},
    {value: ">500", label: "above 500"},
  ])
  const [electives, setElectives] = useState([
    {id: 1, elective_desc: "Continuous Professional Development" },
    {id: 2, elective_desc: "Simulation Training" },
    {id: 3, elective_desc: "Job Redesign" },
    {id: 4, elective_desc: "Business Continuity Plan" },
    {id: 5, elective_desc: "Security Checks" },
    {id: 6, elective_desc: "Standard Operating Procedures (SOPs)" },
    {id: 7, elective_desc: "Service Buyer Management Process" },
    {id: 8, elective_desc: "Supervision" },
    {id: 9, elective_desc: "Body Worn Cameras / Wireless Communications" },
    {id: 10, elective_desc: "Video Analytics" },
    {id: 11, elective_desc: "Remote Guarding" },
    {id: 12, elective_desc: "Technology Enabled Patrol" },
  ])
  const [financialGrades, setFinancialGrades] = useState([
    { grade: "S2", amount: "$100,000"},
    { grade: "S3", amount: "$250,000"},
    { grade: "S4", amount: "$500,000"},
    { grade: "S5", amount: "$1,000,000"},
    { grade: "S6", amount: "$3,000,000"},
    { grade: "S7", amount: "$5,000,000"},
    { grade: "S8", amount: "$10,000,000"},
    { grade: "S9", amount: ">$10,000,000"},
    { grade: "S10", amount: ">$15,000,000"},
    { grade: "Ungrade", amount: null}
  ])
  

  useEffect(() => {
    getUserProfile();
  }, []);

  const isAuthenticatedApi = () => {
    AuthenticateAPI.isLogin({})
      .then((response) => {
        let isAuthendicated = response?.isAuthorized;
        if (isAuthendicated) window.location.href = Path.BASE;
      })
      .catch((err) => {});
  };

  const getUserProfile = () => {
    AuthenticateAPI.getUserProfile()
      .then((response) => {
        response.awards = response.awards ? JSON.parse(response.awards) : awards
        response.plrd = response.plrd ? JSON.parse(response.plrd) : []
        setUser(response || {});
      })
      .catch((err) => {});
  };

  // const handleFileChange = async(event) => {
  //   //  const file = event.target.files[0];
  //   //  console.log(file)
  //   //  const reader = new FileReader();

  //   //  reader.onload = (e) => {
  //   //    console.log(e.target.result);
  //   //    setImageSrc(e.target.result);
  //   //  };

  //   //  if (file) {
  //   //    reader.readAsDataURL(file);
  //   // }
  //     const images = event.target.files[0];
  //     setImageSrc(images);
  //     const formData = new FormData();
  //     formData.append("image", images);
  //     console.log(formData);
  //     axios.put(`${Urls.BASE_URL}+${URL.PROFILE}`, formData).then(response)=>{
  //        setEdit(false);
  //         getUserProfile();
  //     }).catch((err)=>{})

  // };

  const handleFileChange = async (event) => {
    const selectedImage = event.target.files[0];
    setImageSrc(selectedImage);

    const formData = new FormData();
    formData.append("image", selectedImage);

    try {
      await axios.patch(`${Urls.BASE_URL}${Urls.UPLOADPROFILE}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          //  Authorization: token,
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      // Assuming `Urls.BASE_URL` and `URL.PROFILE` are properly defined elsewhere
      setEdit(false);
      window.location.reload()
      isAuthenticatedApi();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const renderNameBadge = (name, customeClass) => {
    if (!name) return "";
    let splitName = name.split(" ");
    let tag = "";
    if (splitName.length > 1) {
      tag = `${splitName[0][0] || ""}${splitName[1][0] || ""}`;
    } else if (splitName) {
      tag = `${splitName[0][0]}`;
    }
    tag = tag ? tag.toUpperCase() : "";
    return (
      <>
        <div className={`d-inline-block name-badge ${customeClass || ""}`}>
          <span className=''>
            {!user.profile ? (
              tag
            ) : (
              <div>
                <img className='cercleImage' src={user.profile} />
              </div>
            )}

            <div className='input--file'>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <circle cx='12' cy='12' r='3.2' />
                  <path d='M9 2l-1.83 2h-3.17c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2h-3.17l-1.83-2h-6zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z' />
                  <path d='M0 0h24v24h-24z' fill='none' />
                </svg>
              </span>
              <input
                name='Select File'
                type='file'
                accept='image/*'
                onChange={handleFileChange}
              />
            </div>
          </span>
        </div>
      </>
    );
  };

  const onClickChangePassword = () => {
    setChangePassword(true);
  };

  const hideChangePassword = () => {
    setChangePassword(false);
  };

  const togglehandler = (event) => {
    console.log(event.target.checked);
    let payload = {
      name: user.name,
      company: user.company,
      phoneNumber: user.phoneNumber,
      uanNo: user.uanNumber,
      address: user.address,
      pincode: user.pincode,
      visibility: event.target.checked,
    };
    console.log(payload);
    AuthenticateAPI.updateUser(payload)
      .then((response) => {
        setEdit(false);
        getUserProfile();
      })
      .catch((err) => {});
  };

  const onClickLogout = () => {
    AuthenticateAPI.logout({})
      .then((response) => {
        window.location.href = Path.LOGIN;
      })
      .catch((err) => {});
  };

  const onClickKpi = () => {
    setShowKpi(true);
  };

  const handleBack = () => {
    setShowKpi(false);
  };

  const handleSetEdit = () => {
    let parseAmount = user.financialGradeAmount
    if((user?.financialGrade || "").toLowerCase() === "ungrade") {
      parseAmount = parseAmount.replace('$', '').split(",").join("");
      parseAmount = parseInt(parseAmount || 0)
    }

    setName(user.name);
    setCompany(user.company);
    setAddress(user.address);
    setPhoneNumber(user.phoneNumber);
    setUanNo(user.uanNumber);
    setPostalCode(user.pincode);
    setManpower(user.manpower);
    setFinancialGrade(user.financialGrade);
    setFGAmount(parseAmount);
    setPlrd(user.plrd);
    setOBCReady(user.obc_ready);
    setAwards(user.awards);
    setEdit(true);
  };

  const handleUpdate = (event) => {
    setErrorMessage("");
    let payload = {}
    if(user.type === "VENDOR") {
      let parseAmount = financialGrade === "Ungrade" ? `$${CommonUtils.ThousandSeparator(Number(FGAmount))}` : FGAmount
      payload = {
        name,
        company,
        phoneNumber,
        uanNo,
        address,
        pincode: postalCode,
        obc_ready: OBCReady,
        financialGrade,
        financialGradeAmount: parseAmount,
        plrd: JSON.stringify(plrd),
        awards: JSON.stringify(awards),
        manpower
      };
    } else {
      payload = {
        name,
        company,
        phoneNumber,
        uanNo,
        address,
        pincode: postalCode,
      };
    }


    const validation = validatePayload(payload);

    if (!validation) {
      setError(true);
      return;
    }

    AuthenticateAPI.updateUser(payload)
      .then((response) => {
        setEdit(false);
        getUserProfile();
      })
      .catch((err) => {});
  };

  const validatePayload = (payload) => {
    if (
      !payload.name ||
      !payload.company ||
      !payload.phoneNumber ||
      !payload.uanNo ||
      !payload.address ||
      !payload.pincode
    )
      return false;

    return true;
  };

  if (showKpi) return <KPI back={() => setShowKpi(false)} />;

  return (
    <div className='profile'>
      {/* <TitleHeader title="Profile" /> */}
      <div className='container py-4'>
        <div className='container-fluid'>
          <div className='card sshadow-sm obx-blue p-4 mb-4'>
            <div className='row'>
              <div className='col obx-white'>
                <h4>Profile</h4>
              </div>
            </div>
          </div>
          <div className='card detail-card'>
            <div className='d-flex '>
              <div className='flex-1 text-center py-4 border-right-gray'>
                <div className='d-inline-block'>
                  {renderNameBadge(user?.name, "profile-icon")}
                </div>
                <div className='bold-text font-16 pt-2'> {user?.email}</div>
                <div className='bold-text font-14 logout-text pt-2 cursor-pointer'>
                  {" "}
                  <span onClick={onClickLogout}>Logout</span>
                </div>
              </div>
              <div className='flex-2 px-5'>
                {!isEdit ? (
                  <div className='edit-profile-button' onClick={handleSetEdit}>
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </div>
                ) : (
                  ""
                )}

                <div className='row'>
                  <div className='pt-4 col-lg-4 col-md-4 col-sm-6'>
                    <div className='font-14 bold-text'>Name</div>
                    {isEdit ? (
                      <div className='pt-2'>
                        <input
                          type='text'
                          className={`obx-small form-control ${
                            isError && !name ? "invalid-input" : ""
                          }`}
                          placeholder='Enter your  name'
                          value={name}
                          onChange={(event) => setName(event?.target?.value)}
                        />
                      </div>
                    ) : (
                      <div className='lable-text pt-2'>{user.name}</div>
                    )}
                  </div>
                  <div className='pt-4 col-lg-4 col-md-4 col-sm-6'>
                    <div className='font-14 bold-text '>Company </div>
                    {isEdit ? (
                      <div className='pt-2'>
                        <input
                          type='text'
                          className={`obx-small form-control ${
                            isError && !company ? "invalid-input" : ""
                          }`}
                          placeholder='Enter your company name'
                          value={company}
                          onChange={(event) => setCompany(event?.target?.value)}
                        />
                      </div>
                    ) : (
                      <div className='lable-text pt-2'>{user.company}</div>
                    )}
                  </div>
                  <div className='pt-4 col-lg-4 col-md-4 col-sm-6'>
                    <div className='font-14 bold-text '>UEN</div>
                    {isEdit ? (
                      <div className='pt-2'>
                        <input
                          type='text'
                          className={`obx-small form-control ${
                            isError && !uanNo ? "invalid-input" : ""
                          }`}
                          placeholder='Enter your postal code'
                          value={uanNo}
                          onChange={(event) => setUanNo(event?.target?.value)}
                        />
                      </div>
                    ) : (
                      <div className='lable-text pt-2'> {user.uanNumber}</div>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='pt-4 col-lg-4 col-md-4 col-sm-6'>
                    <div className='font-14 bold-text'>Phone Number</div>
                    {isEdit ? (
                      <div className='pt-2'>
                        <input
                          type='text'
                          className={`obx-small form-control ${
                            isError && !phoneNumber ? "invalid-input" : ""
                          }`}
                          placeholder='Enter your phone no'
                          value={phoneNumber}
                          onChange={(event) =>
                            setPhoneNumber(event?.target?.value)
                          }
                        />
                      </div>
                    ) : (
                      <div className='lable-text pt-2'> {user.phoneNumber}</div>
                    )}
                  </div>
                  <div className='pt-4 col-lg-4 col-md-4 col-sm-6'>
                    <div className='font-14 bold-text'>Address</div>
                    {isEdit ? (
                      <div className='pt-2'>
                        <textarea
                          type='text'
                          className={`obx-small form-control ${
                            isError && !address ? "invalid-input" : ""
                          }`}
                          placeholder='Enter your address'
                          value={address}
                          onChange={(event) => setAddress(event?.target?.value)}
                        />
                      </div>
                    ) : (
                      <div className='lable-text pt-2'> {user.address}</div>
                    )}
                  </div>
                  <div className='pt-4 col-lg-4 col-md-4 col-sm-6'>
                    <div className='font-14 bold-text'>Postal Code</div>
                    {isEdit ? (
                      <div className='pt-2'>
                        <input
                          type='text'
                          className={`obx-small form-control ${
                            isError && !postalCode ? "invalid-input" : ""
                          }`}
                          placeholder='Enter your postal code'
                          value={postalCode}
                          onChange={(event) =>
                            setPostalCode(event?.target?.value)
                          }
                        />
                      </div>
                    ) : (
                      <div className='lable-text pt-2'> {user.pincode}</div>
                    )}
                  </div>
                </div>

                {
                  user.type === "VENDOR" && <>
                    <div className='row'>
                      <div className='pt-4 col-lg-4 col-md-4 col-sm-6'>
                        <div className='font-14 bold-text'>Manpower Strength</div>
                        {isEdit ? (
                          <div className='pt-2'>
                            <Form.Select
                              value={manpower}
                              onChange={(e) => setManpower(e.target.value)}
                            >
                              {
                                manpowers.map((mp, id) => <option key={id} value={mp.value} selected={mp.value === manpower}>{mp.label}</option> )
                              }
                            </Form.Select>
                          </div>
                        ) : (
                          <div className='lable-text pt-2'> {user.manpower}</div>
                        )}
                      </div>
                      <div className='pt-4 col-lg-4 col-md-4 col-sm-6'>
                        <div className='font-14 bold-text'>Financial Grades</div>
                        {isEdit ? (
                          <div className='pt-2'>
                            <Form.Select
                              id="financial_select" 
                              aria-label="financial selection"
                              value={financialGrade}
                              onChange={(e) => {
                                setFinancialGrade(e?.target?.value || "")
                                let findFGA = financialGrades.find(v => v.grade === e?.target?.value || "")
                                setFGAmount(findFGA ? findFGA.amount : null)
                              }}
                            >
                              {financialGrades.map((fg, id) => 
                                  <option value={fg.grade} key={id}>{fg.grade} {fg.amount && `(${fg.amount})`}</option>
                                )
                              }
                            </Form.Select>
                            {
                              financialGrade === "Ungrade" &&
                              <div className="form-floating mt-3 mb-3">
                                <input
                                  type="number"
                                  className="obx-small form-control "
                                  id="fgAmount"
                                  name="fgAmount"
                                  value={FGAmount}
                                  onChange={(e) => setFGAmount(e.target.value)}
                                  required
                                />
                                <label className="obx-small" htmlFor="fgAmount">
                                  Enter Amount ($) *
                                </label>
                              </div>
                            }
                          </div>
                        ) : (
                          <div className='lable-text pt-2'> 
                          {user.financialGrade ? `${user.financialGrade} (${user.financialGradeAmount})` : ""}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='pt-4 col-lg-4 col-md-4 col-sm-6'>
                        <div className='font-14 bold-text'>OBC Ready</div>
                        {isEdit ? (
                          <div className='pt-2'>
                            <p className="mb-0" style={{fontSize:"0.9rem"}}>Please tick if your security agency is OBC ready. Requirement for being ready:</p>
                            <ol className="mb-1" style={{fontSize:"0.9rem"}}>
                              <li>Security Agency has an in-house Accredited Security Consultant (“ASC”) or a tie up with an ASC to provide consultancy.</li>
                              <li>Security Agency has in-house capabilities for systems installation/integration or a formal tie up with a systems company. This includes having a signed Agreement for Services, a Memorandum of Understanding or Partnership for Services.</li>
                            </ol>
                            <div>
                              <label className="fw-bold mr-2">OBC Ready</label>
                              <div class="form-check form-check-inline" onChange={() => setOBCReady(true)}>
                                <input class="form-check-input" type="radio" name="obc_ready_options" id="obcYes" value="yes" required checked={OBCReady}/>
                                <label class="form-check-label" for="obcYes">Yes</label>
                              </div>
                              <div class="form-check form-check-inline"  onChange={() => setOBCReady(false)}>
                                <input class="form-check-input" type="radio" name="obc_ready_options" id="obcNo" value="no" required checked={!OBCReady} />
                                <label class="form-check-label" for="obcNo">No</label>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className='lable-text pt-2'> {user?.obc_ready ? "Yes" : "No"}</div>
                        )}
                      </div>
                      <div className='pt-4 col-lg-4 col-md-4 col-sm-6'>
                        <div className='font-14 bold-text'>Awards</div>
                        {isEdit ? (
                          <div className='pt-2'>
                            <p className="mb-0" style={{fontSize:"0.9rem"}}>Please tick if your agency is a SSIA award winner. </p>
                            <div>
                              <label className="fw-bold mr-2">SSIA award winner for 2023</label>
                              <div>
                                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, ssia2023: true})} >
                                  <input class="form-check-input" type="radio" name="SSIA_2023_opt" id="ssiaWinner23Yes" value="yes" required checked={awards.ssia2023} />
                                  <label class="form-check-label" for="ssiaWinner23Yes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, ssia2023: false})} >
                                  <input class="form-check-input" type="radio" name="SSIA_2023_opt" id="ssiaWinner23No" value="no" required checked={!awards.ssia2023} />
                                  <label class="form-check-label" for="ssiaWinner23No">No</label>
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <label className="fw-bold mr-2">SSIA award winner for 2022</label>
                              <div>
                                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, ssia2022: true})} >
                                  <input class="form-check-input" type="radio" name="SSIA_2022_opt" id="ssiaWinner22Yes" value="yes" required checked={awards.ssia2022} />
                                  <label class="form-check-label" for="ssiaWinner22Yes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, ssia2022: false})} >
                                  <input class="form-check-input" type="radio" name="SSIA_2022_opt" id="ssiaWinner22No" value="no" required checked={!awards.ssia2022} />
                                  <label class="form-check-label" for="ssiaWinner22No">No</label>
                                </div>
                              </div>
                                          </div>

                            <p className="mb-0" style={{fontSize:"0.9rem"}}>Please tick if your agency is a Golden Circle award winner</p>
                            <div>
                              <label className="fw-bold mr-2">Golden Circle Award winners 2023</label>
                              <div>
                                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, goldenCircle2023: true})} >
                                  <input class="form-check-input" type="radio" name="gcaw_options" id="goldenYes" value="yes" required checked={awards.goldenCircle2023} />
                                  <label class="form-check-label" for="goldenYes">Yes</label>
                                </div>
                                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, goldenCircle2023: false})} >
                                  <input class="form-check-input" type="radio" name="gcaw_options" id="goldenNo" value="no" required checked={!awards.goldenCircle2023} />
                                  <label class="form-check-label" for="goldenNo">No</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {user?.awards?.ssia2023 && <div className='lable-text pt-2'>SSIA award winner for 2023</div>}
                            {user?.awards?.ssia2022 && <div className='lable-text pt-2'>SSIA award winner for 2022</div>}
                            {user?.awards?.goldenCircle2023 && <div className='lable-text pt-2'>Golden Circle Award winners 2023</div>}
                          </div>
                        )}
                      </div>
                      <div className='pt-4 col-lg-4 col-md-4 col-sm-6'>
                        <div className='font-14 bold-text'>PLRD Electives</div>
                        {isEdit ? (
                          <div className="pt-2">
                            <span className="mb-2">*Please tick the electives that your company is PLRD elective competence. </span>
                          {electives.map((el,i) => 
                            <div class="form-check mb-1" onChange={() => {
                              if (plrd.map((v) => v.id).includes(el.id)) {
                                setPlrd(plrd.filter((item) => item.id !== el.id));
                              } else {
                                setPlrd([...plrd, el]);
                              }
                            }}>
                              <input 
                                class="form-check-input" 
                                type="checkbox"
                                value={el.id}
                                id={"elective_check"+i+el.id}
                                checked={plrd.map((v) => v.id).includes(el.id)}
                              />
                              <label class="form-check-label" for={"elective_check"+i+el.id}>
                                {el.elective_desc}
                              </label>
                            </div>
                          )}
                          </div>
                        ) : (
                          <div className='lable-text pt-2'> {user?.plrd ? user.plrd.map(e =>  e.elective_desc).join(", ") : ""}</div>
                        )}
                      </div>
                    </div>
                  </>
                }


                {isEdit ? (
                  <div className='text-right py-4'>
                    <button
                      onClick={() => setEdit(false)}
                      className={`btn space-between-btn btn-block btn-light align-center `}
                    >
                      {`Cancel`}
                    </button>
                    <button
                      onClick={handleUpdate}
                      type='submit'
                      className={`btn btn-block btn-dark align-center`}
                    >
                      {`Update`}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {/* start*/}
          {user.type === "VENDOR" ? (
            <div className='mt-4 cursor-pointer'>
              <div className='p-4 d-flex align-items-center'>
                <div className='pos_left'>
                  <label htmlFor='toggle'>Visible to me</label>
                  <input
                    type='checkbox'
                    id='toggle'
                    className='togglecheckbox'
                    checked={user.visibility}
                    onChange={togglehandler}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {/* end*/}
          <div
            className='mt-4 card cursor-pointer'
            onClick={onClickChangePassword}
          >
            <div className='p-4 d-flex align-items-center'>
              <div className='cp-flex-1 text-center'>
                <img
                  className='icon-20'
                  alt=''
                  src={getImageSource(ImageConstants.LOCK)}
                />
              </div>
              <div className='cp-flex-2'>
                <span className='pl-4 semi-bold-text'>Change Password </span>
              </div>
              <div className='cp-flex-3 px-4'>
                <div className='float-right'>
                  <img
                    className='icon-10'
                    alt=''
                    src={getImageSource(ImageConstants.RIGHT_ARROW_BLUE)}
                  />
                </div>
              </div>
            </div>
          </div>
          {SessionCache.getUserType() === "BUYER" ? (
            <div className='mt-4 card cursor-pointer' onClick={onClickKpi}>
              <div className='p-4 d-flex align-items-center'>
                <div className='cp-flex-1 text-center'>
                  <img
                    className='icon-20'
                    alt=''
                    src={getImageSource(ImageConstants.EYE_OPEN)}
                  />
                </div>
                <div className='cp-flex-2'>
                  <span className='pl-4 semi-bold-text'>Manage Kpi's</span>
                </div>
                <div className='cp-flex-3 px-4'>
                  <div className='float-right'>
                    <img
                      className='icon-10'
                      alt=''
                      src={getImageSource(ImageConstants.RIGHT_ARROW_BLUE)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {showChangePassword ? <ChangePassword close={hideChangePassword} /> : ""}
    </div>
  );
};

export default Profile;

import React, { useState } from "react";
import ReactSlider from "react-slider";
import styled from "styled-components";
import "./slider.css";

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 8px;
`;

const StyledThumb = styled.div`
  position: relative;
  height: 19px;
  line-height: 15px;
  width: 19px;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  border: 1px solid black;
  background-color: white;
  color: black;
  border-radius: 50%;
  cursor: grab;
  bottom: -6px;
`;

const Thumb = (props, state, inverseScale) => {
  let key = !inverseScale
  ? props.key || ""
  : props.key === `thumb-0` 
    ? `thumb-2` 
    : props.key === `thumb-1` 
    ? `thumb-1`
    : props.key === `thumb-2` 
    ? `thumb-0`
    : props.key === `thumb-3` 
    ? `thumb-3`    
    : ``
  return (
    <StyledThumb
      {...props}
      className={`d-flex ${key}`}
    >
      <span style={{position: 'absolute', top:20}}>
        {state.valueNow}
      </span>
    </StyledThumb>
  );
};

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;

  background: ${(props) =>
    props.index === 4
      ? "#f17151"
      : props.index === 3
      ? "#f89659"
      : props.index === 2
      ? "#f0ea0e"
      : props.index === 1
      ? "#c3da58"
      : props.index === 5
      ? `#ddd`
      : `#7fff00`};
  overflow: hidden;
  border-radius: 999px;
`;
const StyledTrack1 = styled.div`
  top: 0;
  bottom: 0;

  background: ${(props) =>
    props.index === 4
      ? "#f89659"
      : props.index === 3
      ? "#7fff00"
      : props.index === 2
      ? "#c3da58"
      : props.index === 1
      ? "#f0ea0e"
      :props.index === 0
      ? "#f89659"
      : "#ddd"};
  overflow: hidden;
  border-radius: 999px;
`;
const Track = (props, state) => (
  <StyledTrack {...props} index={state.index}>
    <div
      className="position-absolute top-50 start-50 translate-middle fw-bold"
      style={{ fontSize: "8px" }}
    >
      <span>{`${
        state.index === 1
          ? `3 points`
          : state.index === 2
          ? `2 points`
          : state.index === 3
          ? `1 points`
          : state.index === 0
          ? `4 points`
          : state.index === 5
          ? `0 points`
          : ``
      }`}</span>
    </div>
  </StyledTrack>
);

const inverseTrack = (props, state) => (
  <StyledTrack1 {...props} index={state.index}>
    <div
      className="position-absolute top-50 start-50 translate-middle fw-bold "
      style={{ fontSize: "10px" }}
    >
      <span>{`${
        state.index === 0
          ? `1 point`
          : state.index === 1
          ? `2 points`
          : state.index === 2
          ? `3 points`
          : state.index === 3
          ? `4 points`
          : state.index === 4
          ? `5 points`
          : `0 points`
      }`}</span>
    </div>
  </StyledTrack1>
);
const CustomSlider = (props) => {
  let SliderValues = [];
  const { failThreshold, scale, Index, onSliderChange, inverseScale, allowFractions } = props;
  scale.map((scale, index) => {
    if (index < 4) {
      SliderValues.push(scale.low);
    }
  });
  let SliderRange = [...scale];
  const OnSliderChangeHandler = (value, index) => {
    const IndexScaleValueForKapis = Number(scale.length - index - 2);
    const ValueForModify = value[index];
    if (ValueForModify) {
      SliderRange[IndexScaleValueForKapis] = {
        ...SliderRange[IndexScaleValueForKapis],
        low: ValueForModify,
      };
      let FormulatedData = failThreshold >= 5 ? [] : SliderRange;
      if (failThreshold >= 5) {
        SliderRange.map((scale, index) => {
          if (scale.title === "Fail") {
            FormulatedData.push(scale);
          }
          if (scale.title === "Poor") {
            const UpdatedData = {
              ...scale,
              high: SliderRange[index - 1].low - 1,
            };
            FormulatedData.push(UpdatedData);
          }
          if (scale.title === "Passable") {
            const UpdatedData = {
              ...scale,
              high: SliderRange[index - 1].low - 1,
            };
            FormulatedData.push(UpdatedData);
          }
          if (scale.title === "Good") {
            const UpdatedData = {
              ...scale,
              high: SliderRange[index - 1].low - 1,
            };
            FormulatedData.push(UpdatedData);
          }
          if (scale.title === "Excellent") {
            const UpdatedData = {
              ...scale,
              low: SliderRange[index - 1].low - 1,
            };
            FormulatedData.push(UpdatedData);
          }
        });
      }
      onSliderChange(FormulatedData, Index);
    }
  };

  return (
    <div className="d-flex">
      <StyledSlider
        renderTrack={(props, state) => inverseScale ? inverseTrack(props, state) : Track(props, state)}
        value={SliderValues.reverse()}
        renderThumb={(props, state) => Thumb(props, state, inverseScale)}
        min={0}
        max={failThreshold}
        step={allowFractions ? 0.01 : 1}
        onChange={OnSliderChangeHandler}
        minDistance={1}
        className={"slider-invert"}
      />
      <span className="thumb-point tp-invert">
        <span style={{position: 'absolute', top:20}}>
          {failThreshold}
        </span>
      </span>
    </div>
  );
};

export default CustomSlider;

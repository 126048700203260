import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import SessionCache from './session-cache';
import AuthenticateRoute from './authenticate-route';
import Path from './routes-path';
import Login from '../authentication/login/login';

let public_routes = [

    Path.LOGIN,
    Path.ADMIN_LOGIN,
    Path.REGISTER,
    Path.FORGOT_PASSWORD,
    Path.OPPORTUNITIES,
    Path.VENDOR_DIRECTORY,
    Path.FAQ,
    Path.BASE,
    Path.ANNOUNCEMENTS,
    Path.CONTACT,
    Path.VERIFICATION,
    Path.TERMS,
    Path.PRIVACY,
];

export default function RouteWrapper({
    component: Component,
    isPrivate,
    ...rest
}) {

    if (public_routes.indexOf(rest.path) != -1) {
        return <Route {...rest} component={Component} />;
    }

    let status = SessionCache.isAuthenticated();
    // let status = true
    if (!status) {
        return <Route {...rest} render={(props) => {
            return <AuthenticateRoute {...rest} component={Component}></AuthenticateRoute>
        }} />
    }
    if (status) {
        return <Route {...rest} component={Component} />;
    }

    return <Route path={Path.LOGIN} component={Login} />
}

RouteWrapper.propTypes = {

    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {

    isPrivate: false
}
import React from 'react';
import "./change-password.scss";
import AuthenticateAPI from '../../../../apis/authenticate'
// import SessionCache from '../routes/session-cache';
import { Modal, ModalBody, Tooltip, Overlay } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import CommonUtils from '../../../../utility/common-utilities'
import { getImageSource } from '../../../../utility/image-source';
import ImageConstants from '../../../../constants/image-constants';
class ChangePassword extends React.Component {
    state = {
    }

    oldPassworRef = null

    componentDidMount() {

    }

    onChangeHandler(event, key) {
        this.setState({ [key]: event?.target?.value }, () => {
            this.validatePayload()
        })
    }

    onClickShowPassword = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    validatePayload(showError) {

        let isValidForm = true

        if (!this.state.oldPassword) {
            showError && this.setState({ isInvalidOldPassword: true, oldPasswordErrorMessage: "* Please enter details (Mandatory)" })
            isValidForm = false
        } else this.setState({ isInvalidOldPassword: false, oldPasswordErrorMessage: "" })

        if (this.state.password && this.state.reEnterPassword) {
            if (!CommonUtils.validatePassword(this.state.password)) {
                showError && this.setState({ isInvalidNewPassword: true, newPasswordErrorMessage: "Password must be at least 8 characters" })
                isValidForm = false
            } else if ((this.state.password !== this.state.reEnterPassword)) {
                showError && this.setState({ isInvalidReEnterPassword: true, reEnterPasswordErrorMessage: "Password mismatch", isInvalidNewPassword: false })
                isValidForm = false
            } else this.setState({ isInvalidReEnterPassword: false, reEnterPasswordErrorMessage: "", isInvalidNewPassword: false, newPasswordErrorMessage: "" })
        } else {
            if (!this.state.password) {
                showError && this.setState({ isInvalidNewPassword: true, newPasswordErrorMessage: "* Please enter details (Mandatory)" })
                isValidForm = false
            } else if (!CommonUtils.validatePassword(this.state.password)) {
                showError && this.setState({ isInvalidNewPassword: true, newPasswordErrorMessage: "Password must be at least 8 characters" })
                isValidForm = false
            } else this.setState({ isInvalidNewPassword: false, newPasswordErrorMessage: "" })

            if (!this.state.reEnterPassword) {
                showError && this.setState({ isInvalidReEnterPassword: true, reEnterPasswordErrorMessage: "* Please enter details (Mandatory)" })
                isValidForm = false
            } else this.setState({ isInvalidReEnterPassword: false, reEnterPasswordErrorMessage: "" })

        }


        this.setState({ isValidForm, errMessage: "" })
        return isValidForm
    }


    onClickUpdatePassword = () => {

        let payload = {
            newPassword: this.state.password,
            oldPassword: this.state.oldPassword,
        }

        let validate = this.validatePayload(true)

        if (!validate) return

        AuthenticateAPI.updatePassword(payload).then((response) => {
            this.props.close()
        }).catch((err) => {

            if (err?.response?.data?.code === 900) {
                this.setState({ isInvalidOldPassword: true, oldPasswordErrorMessage: "Incorrect old password" })
            }
        })
    }


    render() {

        return (

            <Modal className="change-password-custom-modal" show={true} onHide={this.props.close} size="md">
                <Modal.Body className="" >
                    <div className="change-password">
                        <div className="row">
                            <div className="col text-right">
                                <FontAwesomeIcon className="cursor-pointer" onClick={this.props.close} icon={faTimes} />
                            </div>
                        </div>

                        <div className="m-4 ">
                            <div className="row">
                                <div className="col">
                                    <span className="header-text">Change Password</span>
                                </div>
                            </div>

                            <div className="pt-4">
                                <div className={`bw-spaces-input ${this.state.isInvalidOldPassword ? "invalid-input-container" : ""} position-relative`}>
                                    <div className='app-input-label'>Old Password<span className='star' id="old-password-tooltip">*</span></div>
                                    <input ref={this.oldPassworRef} id="old-password" className={`app-input`} placeholder="Enter your old password"
                                        value={this.state.oldPassword}
                                        type={this.state.showOldPassword ? "" : "password"}
                                        onChange={(e) => this.onChangeHandler(e, 'oldPassword')} />
                                    <span className="password-icon">
                                        <img className="cursor-pointer icon-20" alt=""
                                            src={getImageSource(this.state.showOldPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                            onClick={() => this.onClickShowPassword("showOldPassword")}
                                        />
                                    </span>
                                    {
                                        this.state.isInvalidOldPassword ? <div className="app-input-label"> {this.state.oldPasswordErrorMessage} </div> : ""
                                    }
                                </div>

                                <div className={`bw-spaces-input ${this.state.isInvalidNewPassword ? "invalid-input-container" : ""} position-relative`}>
                                    <div className='app-input-label'>New Password<span className='star' id="new-password-tooltip">*</span></div>
                                    <input id="new-password" className={`app-input`} placeholder="Enter your new password"
                                        value={this.state.password}
                                        type={this.state.showNewPassword ? "" : "password"}
                                        onChange={(e) => this.onChangeHandler(e, 'password')} />
                                    <span className="password-icon">
                                        <img className="cursor-pointer icon-20" alt=""
                                            src={getImageSource(this.state.showNewPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                            onClick={() => this.onClickShowPassword("showNewPassword")}
                                        />
                                    </span>
                                    {
                                        this.state.isInvalidNewPassword ? <div className="app-input-label"> {this.state.newPasswordErrorMessage} </div> : ""
                                    }
                                </div>

                                <div className={`bw-spaces-input ${this.state.isInvalidReEnterPassword ? "invalid-input-container" : ""} position-relative`}>
                                    <div className='app-input-label'>Re Enter Password<span className='star' id="reenter-password-tooltip" >*</span></div>
                                    <input id="new-password" className={`app-input`} placeholder="Enter your new password again"
                                        value={this.state.reEnterPassword}
                                        type={this.state.showReEnterPassword ? "" : "password"}
                                        onChange={(e) => this.onChangeHandler(e, 'reEnterPassword')} />
                                    <span className="password-icon">
                                        <img className="cursor-pointer icon-20" alt=""
                                            src={getImageSource(this.state.showReEnterPassword ? ImageConstants.EYE_OPEN : ImageConstants.EYE_CLOSE)}
                                            onClick={() => this.onClickShowPassword("showReEnterPassword")}
                                        />
                                    </span>
                                    {
                                        this.state.isInvalidReEnterPassword ? <div className="app-input-label"> {this.state.reEnterPasswordErrorMessage} </div> : ""
                                    }
                                </div>

                                <div className="pt-4 text-right">
                                    <button className={`app-button-secondary mr-2 `} onClick={this.props.close}>
                                        Cancel
                                    </button>

                                    <button className={`app-button-primary ${this.state.isValidForm ? "" : "app-button-disabled"}`} onClick={this.onClickUpdatePassword}>
                                        Change Password
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

}

export default ChangePassword;
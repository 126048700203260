import React, { useState } from "react";
import "./faq.scss";
import TitleHeader from "../common/title-header/title-header";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import "../../styles/fonts.scss";

function FAQ(props) {
  const items = [
    {
      title: "What is OBX?",
      body: [
        "OBX is a Singapore based platform that assist the creation of Security Service Outcome-Based Contract (OBC) tenders. It is also a repository for tenders (OBC or regular) to be listed for security providers to bid on.  ",
      ],
    },
    {
      title: "What is an Outcome-based Contract (OBC) in the security context?",
      body: [
        "Outcome-Based Contracting (OBC) is a contract that focuses on achieving specific outcomes or results rather than fixed resources.",
        "For example:",
        <React.Fragment key="examples">
          <strong>a. Traditional Head Count tender:</strong>
          <p>
            1x officer at the guardhouse is to record all visitors or
            contractors who come into the premise.
          </p>
          <strong>b. Outcome Based Contract:</strong>
          <p>
            Ensure all visitors or contractors are registered with Name, contact
            number, unit to visit, and purpose when entering the premise.
          </p>
        </React.Fragment>,
        "Various security providers will offer their proposal(s) to meet the outcome(s) or Key Performance Indicators (KPIs). This gives the buyers multiple options to decide which solutions will best fit their requirements and budget.",
      ],
    },
    {
      title:
        "In what way is OBC better than a traditional headcount-based contract?",
      body: [
        "Outcome-Based Contracts (OBCs) will encourage security providers to propose innovative security solutions that meet desired security outcomes. By incentivising outcomes rather than just the provision of services, OBCs give security providers more flexibility to use technology and redesign work processes to achieve the desired results with a score range. This can lead to cost savings and a more effective security posture overall.",
      ],
    },
    {
      title: "Is OBC cheaper than a traditional contract?",
      body: [
        "In the long term, yes. As the rising manpower cost due to progressive wage model (PWM) for the next few years (see table below) and manpower shortage expected in the industry, a long-term OBC will be more cost effective due cheaper use of technologies and less reliance of Manpower.",
      ],
      table: {
        headers: [
          { title: "Job level", key: "job" },
          { title: "2023", key: "2023" },
          { title: "2024", key: "2024" },
          { title: "2025", key: "2025" },
          { title: "2026", key: "2026" },
          { title: "2027", key: "2027" },
          { title: "2028", key: "2028" },
        ],
        data: [
          {
            job: "Security officer",
            2023: "≥ $1,650",
            2024: "≥ $2,650",
            2025: "≥ $2,870",
            2026: "≥ $3,090",
            2027: "≥ $3,310",
            2028: "≥ $3,530",
          },
          {
            job: "Senior security officer",
            2023: "≥ $1,830",
            2024: "≥ $2,950",
            2025: "≥ $3,170",
            2026: "≥ $3,390",
            2027: "≥ $3,610",
            2028: "≥ $3,830",
          },
          {
            job: "Security supervisor",
            2023: "≥ $2,040",
            2024: "≥ $3,250",
            2025: "≥ $3,470",
            2026: "≥ $3,690",
            2027: "≥ $3,910",
            2028: "≥ $4,130",
          },
          {
            job: "Senior security supervisor",
            2023: "≥ $2,240",
            2024: "≥ $3,550",
            2025: "≥ $3,770",
            2026: "≥ $3,990",
            2027: "≥ $4,210",
            2028: "≥ $4,430",
          },
        ],
      },
    },
    {
      title: "Why is a long-term contract better?",
      body: [
        "Long-term contracts can offer greater “cash flow” from the buyers by allowing them to spread out the cost of expensive technology equipment over a longer period.",
      ],
    },
    {
      title: "Can you tell me more about why OBCs are becoming popular?",
      body: [
        "Outcome-based contracts are becoming increasingly popular in various industries, including security, technology, healthcare, and government. There are several reasons why organizations might prefer an outcome-based contract over a traditional input-based contract.",
        "Focus on results: With an outcome-based contract, the focus is on achieving specific outcomes or results that align with the customer's goals.",
        "Reduced risk and Increased innovation.",
        "Increased innovation: Outcome-based contracts can encourage service providers to innovate and find new and more effective ways of achieving the desired outcomes.This can lead to better outcomes for the client and can help to drive innovation in the industry.",
        "Improved collaboration: Outcome-based contracts require a higher level of collaboration between the client and the service provider. This can lead to better communication, alignment, and trust between the two parties.",
      ],
    },
    {
      title: "How is the quality of service measured in OBCs?",
      body: [
        "When generating the OBC through the platform, buyers can set the performance evaluation matrix for each required task, duty or outcome. This evaluation can be customised in through a scoring point system, where the expected KPI or performance outcome is compared to the actual achievements/performance. ",
      ],
    },
    {
      title: "How will the quality be improved?",
      body: [
        "This above score can be linked to performance-based payments or used as a benchmark to monitor progress towards goals. The evaluation process should be transparent, allowing both parties to provide feedback and adjust as needed to improve performance.",
      ],
    },
    {
      title: "How are liquidated damages be compensated?",
      body: [
        "There will not any Liquidated Damages (LD) in OBC. OBC uses customised point scoring matrix to determine the security outcome and the contract payment sum.",
      ],
    },
  ];

  return (
    <div className="container py-4 header_title_caption">
      <div className="container-fluid">
        <div className="card sshadow-sm obx-blue p-4 mb-4">
          <div className="row">
            <div className="col obx-white header_title_head">
              <h4>FAQ's</h4>
            </div>
          </div>
        </div>
        <Accordion defaultActiveKey={"0"}>
          {items.map((element, index) => (
            <Accordion.Item eventKey={`${index}`}>
              <Accordion.Header>{element.title}</Accordion.Header>
              <Accordion.Body>
                <div>
                  <div>
                    {element.body.map((content) => (
                      <p>{content}</p>
                    ))}
                  </div>
                  {element.table ? (
                    <div>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            {element.table?.headers.map((item, i) => (
                              <th
                                className={`text-bg-light ${
                                  i === 0 ? "" : "text-center"
                                }`}
                              >
                                {item.title}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {element.table?.data.map((item) => (
                            <tr style={{ height: "70px" }}>
                              <td className="align-middle">{item.job}</td>
                              <td className="align-middle text-center">
                                {item["2023"]}
                              </td>
                              <td className="align-middle text-center">
                                {item["2024"]}
                              </td>
                              <td className="align-middle text-center">
                                {item["2025"]}
                              </td>
                              <td className="align-middle text-center">
                                {item["2026"]}
                              </td>
                              <td className="align-middle text-center">
                                {item["2027"]}
                              </td>
                              <td className="align-middle text-center">
                                {item["2028"]}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default FAQ;

import React, { useState } from "react";
import TenderCard from "../cards/tender-card/tender-card";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import TenderList from "../tender-list/tender-list";
import TenderAPI from "../../apis/tender";
import PreviewTender from "../create-tender/preview-tender/preview-tender";
import Path from "../routes/routes-path";
import Loader from "../common/loading/loading";
import SessionCache from "../routes/session-cache";
import AwsApi from "../../apis/aws";
import "../../styles/fonts.scss";

const stageStatus = {
  1: "ALL",
  2: "PUBLISHED",
  3: "SCHEDULED",
  4: "DRAFT",
  5: "SUSPENDED",
  6: "CLOSED",
  7: "CANCELLED",
  8: "MESSAGE",
  9: "AWARDED",
};

const DLeftPanel = (props) => {
  let handler = props.handleClickEvent;
  let position = props.position;
  const [stage, setStage] = useState(position);

  const handleSelection = (position) => {
    setStage(position);
    handler(position);
  };

  return (
    <div className="shadow rounded p-3">
      <div className="row">
        <div className="col create-tender">
          <div
            onClick={() => {
              handleSelection(1);
            }}
            className={
              "header_title_head obx-btn obx-bb p-3 " +
              (stage >= 1 && stage <= 7 ? "obx-header-btn-selected" : "")
            }
          >
            {" "}
            Manage Tenders
          </div>
          {
            <div>
              <div
                onClick={() => {
                  handleSelection(1);
                }}
                className={
                  "header_title_head obx-btn obx-bb p-3 sub-header ms-4 " +
                  (stage == 1 ? "obx-btn-selected" : "")
                }
              >
                ALL
              </div>
              <div
                onClick={() => {
                  handleSelection(2);
                }}
                className={
                  "header_title_head obx-btn obx-bb p-3 sub-header ms-4 " +
                  (stage == 2 ? "obx-btn-selected" : "")
                }
              >
                Published
              </div>
              <div
                onClick={() => {
                  handleSelection(3);
                }}
                className={
                  "header_title_head obx-btn obx-bb p-3 sub-header ms-4 " +
                  (stage == 3 ? "obx-btn-selected" : "")
                }
              >
                Scheduled
              </div>
              <div
                onClick={() => {
                  handleSelection(4);
                }}
                className={
                  "header_title_head obx-btn obx-bb p-3 sub-header ms-4 " +
                  (stage == 4 ? "obx-btn-selected" : "")
                }
              >
                Draft
              </div>
              <div
                onClick={() => {
                  handleSelection(5);
                }}
                className={
                  "header_title_head obx-btn obx-bb p-3 sub-header ms-4 " +
                  (stage == 5 ? "obx-btn-selected" : "")
                }
              >
                Suspended
              </div>
              <div
                onClick={() => {
                  handleSelection(6);
                }}
                className={
                  "header_title_head obx-btn obx-bb p-3 sub-header ms-4 " +
                  (stage == 6 ? "obx-btn-selected" : "")
                }
              >
                Closed
              </div>
              <div
                onClick={() => {
                  handleSelection(7);
                }}
                className={
                  "header_title_head obx-btn obx-bb p-3 sub-header ms-4 " +
                  (stage == 7 ? "obx-btn-selected" : "")
                }
              >
                Cancelled
              </div>
            </div>
          }
          {/* <div onClick={() => { handleSelection(8) }} className={'obx-btn obx-bb p-3 ' + (stage == 8 ? "obx-header-btn-selected" : "")}>Messages</div> */}
          <div
            onClick={() => {
              handleSelection(9);
            }}
            className={
              "obx-btn obx-bb p-3 header_title_head obx-btn obx-bb p-3 " +
              (stage == 9 ? "obx-header-btn-selected" : "")
            }
          >
            Award Tenders
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = (props) => {
  const [selected, setSelected] = useState(1);
  const [previewUrl, setPreviewURL] = useState("");
  const [isLoading, setLoader] = useState(true);
  const [isPreview, setPreview] = useState(false);
  const [selectedTender, setTender] = useState(null);
  const [showTenderDetail, setShowTenderDetail] = useState(false);
  const [reload, setReloader] = useState(false);

  useEffect(() => {
    let user = SessionCache.getUser();
    if (user.type === "VENDOR") {
      window.location.href = Path.DASHBOARD;
    }
    if (user.type === "ADMIN") {
      window.location.href = Path.ADM_DASHBOARD;
    }
    setLoader(false);
  }, []);

  const handler = (position) => {
    setSelected(position);
    setShowTenderDetail(false);
    setTender(null);
    reloadRightPanel();
  };

  const reloadRightPanel = () => {
    setReloader(true);
    setTimeout(() => {
      setReloader(false);
    }, 100);
  };

  const handleDocumentPreview = (tender) => {
    console.log(tender, "code happy hai");
    setTender(tender);
    if (tender.status === "DRAFT") {
      console.log("code here", tender.createBY);
      if (tender.createBY === "Custom") {
        let url = tender.pdfDocumentUrl;
        setPreviewURL(url);
        setPreview(true);
        setLoader(false);
      } else {
        getPublishedDocumentPreviewUrl(tender?.pdfDocumentKey);
      }
    } else if (tender.status === "PUBLISHED") {
      if (tender.createBY === "Custom") {
        let url = tender.pdfDocumentUrl;
        setPreviewURL(url);
        setPreview(true);
        setLoader(false);
      } else {
        getPublishedDocumentPreviewUrl(tender?.pdfDocumentKey);
      }
    } else {
      getPublishedDocumentPreviewUrl(tender?.pdfDocumentKey);
    }
  };

  const handleAnotherDocumentPreview = (url) => {
    setPreviewURL(url);
    setPreview(true);
    setLoader(false);
  };

  const getPublishedDocumentPreviewUrl = (pdfDocumentKey, tender) => {
    setLoader(true);
    // if(tender.createBY=="Custom"){
    //    console.log("company type")
    //   let url =tender.pdfDocumentUrl
    //   setPreviewURL(url);
    //   setPreview(true);
    //   setLoader(false);
    // }else{
    //   console.log("user type")
    AwsApi.getSignedUrlToView({ url: pdfDocumentKey })
      .then((response) => {
        let url = response.signedUrl;
        setPreviewURL(url);
        setPreview(true);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
    // }
  };

  const getPreviewUrl = (url) => {
    setLoader(true);
    TenderAPI.getPreviewUrl({ url })
      .then((response) => {
        let url = response?.data.previewUrl;
        setPreviewURL(url);
        setPreview(true);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const getEditUrl = (url) => {
    setLoader(true);
    TenderAPI.getEditUrl({
      url: selectedTender?.documentUrl,
      tid: selectedTender.tenderNumber,
    })
      .then((response) => {
        let url = response?.data.editUrl;
        setPreviewURL(url);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onPublishTender = (url) => {
    setLoader(true);
    TenderAPI.publishTender({ tenderNumber: selectedTender.tenderNumber })
      .then((response) => {
        setPreview(false);
        setLoader(false);
        window.location.href = response.data.paymentIntent;
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleDocumentBack = () => {
    setPreviewURL("");
    setPreview(false);
    setShowTenderDetail(true);
  };

  return (
    <div className="container py-4">
      {isLoading ? <Loader /> : ""}
      <div className="container-fluid">
        <div className="card sshadow-sm obx-blue p-4 mb-4">
          <div className="row">
            <div className="col obx-white header_title_head">
              <h4>Dashboard</h4>
            </div>
            <div className="col header_title_head">
              <Link
                exact
                to="/create-tender"
                className="btn btn-light float-end"
              >
                Create OBC Tender
              </Link>
            </div>

            <div className="col header_title_head">
              <Link
                exact
                to="/upload-tender"
                className="btn btn-light float-end"
              >
                Upload Regular Tender
              </Link>
            </div>
          </div>
        </div>
        <div className="create-tender">
          {isPreview ? (
            <PreviewTender
              previewURL={previewUrl}
              onEditDocument={getEditUrl}
              onPublish={onPublishTender}
              isTenderPublished={selectedTender?.status === "PUBLISHED"}
              showBack={true}
              back={handleDocumentBack}
            />
          ) : (
            <div className="row ">
              <div className="col-lg-3 mb-4">
                <DLeftPanel
                  key={selected}
                  handleClickEvent={handler}
                  position={selected}
                />
              </div>
              <div className="col-lg-9">
                <div className="">
                  {!reload ? (
                    <TenderList
                      showTenderDetail={showTenderDetail}
                      selectedTender={selectedTender}
                      status={stageStatus[selected]}
                      handleDocumentPreview={handleDocumentPreview}
                      handleAnotherDocumentPreview={
                        handleAnotherDocumentPreview
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import CommonUtils from "../../utility/common-utilities";
import "./chat.scss";
import MessageAPI from '../../apis/message';
import { useState, useEffect, useRef } from "react";
import SessionCache from "../routes/session-cache";
import NoRecords from "../no-records/no-records";
import Loader from '../common/loading/loading'

const Chat = (props) => {
    const [isLoading, setLoader] = useState(false)
    const [isEnd, setEnd] = useState(true)
    const [pageObject, setPageObject] = useState({})
    const [chats, setChats] = useState([])
    const [vendorChats, setVendorChats] = useState([])
    const myElementRef = useRef(null);
    let scrollBottomRef = useRef(null);

    const [message, setMessage] = useState("")
    const [selectedUser, setSelectedUser] = useState(null)

    let user = SessionCache.getUser()

    useEffect(() => {
        if (SessionCache.getUserType() === "BUYER") {
            getMessages()
        } else {
            getMessagesConversation({ page: 1 }, false, true)
        }

    }, [])

    const scrollToBottom = () => {
        scrollBottomRef.current?.scrollIntoView({ behavior: "smooth" })
    }


    const getMessages = () => {
        let payload = {
            tid: props.tender?.tenderNumber,
            page: 1
        }
        setLoader(true)
        MessageAPI.getMessage(payload).then((response) => {
            setLoader(false)
            setPageObject(response?.pagination || {})
            setVendorChats(response?.data || [])
        }).catch((err) => {
            setLoader(false)
        })
    }

    const getMessagesConversation = (payload = {}, append = false, scroll) => {

        payload.tid = props.tender?.tenderNumber
        if (!payload.page) payload.page = pageObject.page || 1
        setLoader(true)
        MessageAPI.getMessageConversation(payload).then((response) => {
            setLoader(false)
            if (response?.data?.length) setPageObject(response?.pagination || {})
            else setEnd(true)

            if (append) setChats([...response?.data, ...chats,])
            else setChats(response?.data || [])

            if (scroll) {
                setTimeout(() => {
                    scrollToBottom()
                }, 100)
                setEnd(false)
            }
        }).catch((err) => {
            setLoader(false)
        })
    }

    const onClickSend = () => {
        if (!message || !message.trim()) return

        let payload = {
            message,
            tenderNumber: props.tender?.tenderNumber,
            toUserId: SessionCache.getUserType() == "VENDOR" ? props.tender?.userId : selectedUser?.senderUserId
        }

        MessageAPI.postMessage(payload).then((response) => {
            payload.createdDate = new Date()
            payload.senderUserId = user.id
            setChats([...chats, payload])
            setMessage("")

        }).catch((err) => {
            setMessage("")

        })

    }

    const handleChatScroll = () => {
        const el = myElementRef.current;

        if (!isEnd || el.scrollTop == 0) {
            let page = +pageObject.currentPage || 0

            let payload = {
                page: page ? page + 1 : 1
            }
            getMessagesConversation(payload, true)
        }
    }

    const onClickUser = (user) => {
        setSelectedUser(user)
        getMessagesConversation({ userId: user.senderUserId, page: 1 }, false, true)
    }

    const backToVendorList = () => {
        setSelectedUser(null)
    }

    return (
        <div className="py-2 text shadow rounded">
            {isLoading && <Loader />}
            <div className="chat">
                <div>
                    <div className='row m-2'>
                        <div className='col'>
                            <button onClick={props.back} className='btn btn-light'><i class="bi bi-arrow-left-short"></i>Back</button>
                        </div>
                    </div>
                    <hr />
                </div>
                {
                    selectedUser ?
                        <div className="hide-web-view px-4 pb-2">
                            <div className="cursor-pointer" onClick={backToVendorList}> <i class="bi bi-arrow-left-short"></i>Back to vendor list</div>
                        </div> : ""
                }

                <div className="row m-0">

                    {
                        SessionCache.getUserType() === "BUYER" ?
                            <div className={`col-md-4 col-lg-4 col-sm-12 px-0 ${selectedUser ? "hide-mobile-view" : ""}`}>
                                <div className="user-list px-3">
                                    {
                                        vendorChats.map(item => (
                                            <div className={`user-item-wrapper mt-2 ${selectedUser?.senderUserId == item?.senderUserId ? "selected" : ""}`} onClick={() => onClickUser(item)}>
                                                <div className="user-item">
                                                    <div className="h6 obx-ls-t">{item?.senderName}</div>
                                                    <div>{CommonUtils.ellipsis(item?.message, 32)}</div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            : ""
                    }

                    <div className={`col p-0 ${!selectedUser ? "hide-mobile-view" : ""}`}>
                        <div className="px-4">


                            <div className="chat-list" id="chat-list" ref={myElementRef} onScroll={handleChatScroll}>
                                {
                                    !selectedUser && SessionCache.getUserType() === "BUYER" ? <NoRecords title="No conversation selected" /> :
                                        <div>
                                            {
                                                chats.map((item, index) => (
                                                    <div className={`message-item-wrapper ${user.id === item.senderUserId ? "r-reverse" : ""}`}>
                                                        <div className={`message-item rounded ${user.id === item.senderUserId ? "message-item-self" : ""}`}>
                                                            <div>{item?.message}</div>
                                                            <div className="time">{CommonUtils.datePipe(item?.createdDate, "hh:mm")}</div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                }
                                <div ref={scrollBottomRef}> &nbsp;</div>
                            </div>

                        </div>
                        {
                            selectedUser || SessionCache.getUserType() === "VENDOR" ?
                                <>
                                    <hr />

                                    <div className="card-footer px-4">
                                        <div className="chat-input-wrapper">
                                            <div className="row">
                                                <div className="col-11">
                                                    <input className="form-control msg-input" placeholder="Enter your message here..." value={message} onChange={event => setMessage(event?.target?.value)} />
                                                </div>
                                                <div className="col-1 my-auto">
                                                    <div className="send-button" onClick={onClickSend}>
                                                        <i class="bi bi-send"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : ""
                        }

                    </div>
                </div>
            </div>
        </div >
    );

};

export default Chat
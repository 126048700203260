import { useEffect, useState } from "react";
import CommonUtils from "../../../utility/common-utilities";
import { getImageSource } from "../../../utility/image-source";
import ImageConstants from "../../../constants/image-constants";

const SecutiryOutcome = (props) => {
  const [payload, setPayload] = useState({});
  useEffect(() => {
    setPayload(props.payload || {});
  }, []);
  const handleServiceClick = (item) => {
    let SelectedOutcomesInfo = [];
    let payloadObj = { ...payload };
    let selectedOutcomes = payloadObj.selectedOutcomes || [];
    if (selectedOutcomes?.includes(item._id)) {
      selectedOutcomes = selectedOutcomes.filter((s) => s !== item._id);
    } else {
      selectedOutcomes.push(item._id);
    }

    payloadObj.selectedOutcomes = selectedOutcomes;
    const SelectedOutcomesInfoHandler = payloadObj.selectedOutcomes.map(
      (id) => {
        const OutcomeInfo = props.outcomes.filter((data) => data._id === id);
        SelectedOutcomesInfo.push({ OutcomeDescription: OutcomeInfo[0].title });
      }
    );
    payloadObj["SelectedOutcomesInfo"] = SelectedOutcomesInfo;
    console.log(payloadObj);
    setPayloadObject(payloadObj);
  };

  const setPayloadObject = (payloadObj) => {
    setPayload(payloadObj);
    props.emitChanges && props.emitChanges(payloadObj, "2");
  };
  const onClickNext = () => {
    let validation = CommonUtils.validateCreateTenderPayload(payload, 2);
    if (validation?.error) {
      return;
    }
    props.next();
  };

  return (
    <div>
      <span className="h6 header_title_caption fs-5">Security Outcomes</span>
      <hr></hr>
      <div className="card mt-3 tooltip-background-color">
        <div className="card-body p-4 ">
          <div className="row">
            <div className="col">
              <h7 className="card-title mb-4 header_title_caption">
                <i className="me-2 bi bi-info-circle"></i>Please select outcomes
                relevant to your service requirements. Your chosen outcomes will
                be used to define and set Key Performance Indicators (KPIs) in
                the next section. Resources
              </h7>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-4 table_style">
        <div className="card-body">
          <table className="table">
            <thead>
              <tr className="header_title_caption tables_header_rowborderbottom">
                <th colSpan={1}>S/N</th>
                <th scope="col" colSpan={5}>
                  <span className="ms-5"> Outcome Description</span>
                </th>
                <th scope="col" colSpan={3}>
                  Service Type
                </th>
                {/* <th scope="col">Suggested KPIs</th> */}
              </tr>
            </thead>
            <tbody className="hover selected-hover">
              {props?.outcomes?.length
                ? props?.outcomes.map((item, index) => (
                    <tr
                      className={`${
                        payload?.selectedOutcomes?.includes(item._id)
                          ? "options-selected-color header_title_caption"
                          : "header_title_caption"
                      }`}
                      onClick={() => handleServiceClick(item)}
                      // style={{
                      //   background: `${
                      //     index % 2 === 0 ? `#f5ffff` : `#E9FBFA`
                      //   }`,
                      // }}
                    >
                      <td colSpan={1}>{index + 1}</td>
                      <td colSpan={5}>
                        {" "}
                        <div className="d-flex  align-items-center mt-2">
                          <div
                            className={`${
                              item.Recommended ? `` : `all_recommneded_hide`
                            }`}
                          >
                            <img
                              src={getImageSource(
                                ImageConstants.RECOMMENDEDKPI
                              )}
                              style={{ width: "30px" }}
                              alt="recommended"
                              title="Recommended"
                              className="me-2"
                            />
                          </div>

                          <span>{item.title}</span>
                        </div>
                      </td>
                      <td colSpan={3}>
                        <div className="d-flex justify-content-start">
                          <span>{item.serviceTitle}</span>
                          <input
                            type="checkbox"
                            className="ms-3"
                            checked={
                              payload?.selectedOutcomes?.includes(item._id)
                                ? true
                                : false
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              handleServiceClick(item);
                            }}
                          />
                        </div>
                      </td>
                      {/* <td className="overflow-hidden">
                        {item?.suggestedKpis?.length
                          ? item?.suggestedKpis?.map((element) => (
                              <span className="me-2 badge text-bg-light obx-text-dgray">
                                {element?.title}
                              </span>
                            ))
                          : ""}
                      </td> */}
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <button
            onClick={props.back}
            type="button"
            className="btn btn-md btn-light float-left"
          >
            Back
          </button>
        </div>
        <div className="col">
          <button
            onClick={onClickNext}
            type="button"
            className="btn btn-md btn-primary obx-btn-color float-end"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecutiryOutcome;

import HttpClient from './http-client';
import URL from './url';

export default class MessageAPI {

    static postMessage(payload) {
        return HttpClient.post(URL.BASE_URL + URL.MESSAGE, null, payload);
    }

    static getMessage(payload) {
        let params = HttpClient.getParams(payload)
        let paramsList = params.length ? URL.GET_MESSAGE + "?" + params.join('&') : URL.GET_MESSAGE;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getMessageConversation(payload) {
        let params = HttpClient.getParams(payload)
        let paramsList = params.length ? URL.MESSAGE_CONVERSATION + "?" + params.join('&') : URL.MESSAGE_CONVERSATION;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthenticateAPI from "../../../apis/authenticate";
import TitleHeader from "../../common/title-header/title-header";
import Path from "../../routes/routes-path";
import SessionCache from "../../routes/session-cache";
import "./login.scss";
import Button from "react-bootstrap/Button";
import { getImageSource } from "../../../utility/image-source";
import ImageConstants from "../../../constants/image-constants";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

function AdminLogin(props) {
  const [email, setEmail] = useState("");
  const [submit, setSubmit] = useState(false);
  const [type, setType] = useState("ADMIN");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    isAuthenticatedApi();
  }, []);

  const isAuthenticatedApi = () => {
    AuthenticateAPI.isLogin({})
      .then((response) => {
        let isAuthendicated = response?.isAuthorized;
        if (isAuthendicated) window.location.href = Path.BASE;
      })
      .catch((err) => {});
  };

  const handleEmailChange = (event) => {
    setErrorMessage("");
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setErrorMessage("");
    setPassword(event.target.value);
  };

  const ResendVerifactionLink = () => {
    let payload = {
      email,
      password,
      type,
    };
    AuthenticateAPI.ResendVerifyLink(payload)
      .then((response) => {
        setErrorMessage(response?.data?.message);
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");

    if (submit) return;
    let payload = {
      email,
      password,
      type,
    };

    setSubmit(true);
    AuthenticateAPI.login(payload)
      .then((response) => {
          window.location.href = Path.ADM_DASHBOARD;
        setSubmit(false);
      })
      .catch((err) => {
        setSubmit(false);
        setErrorMessage(err?.response?.data?.message || "Invalid credentials");
      });
  };

  return (
    <>
      {/* <TitleHeader title="LOGIN" /> */}
      <div className='row LoginForm justify-content-center py-5'>
        <div className='col-md-6 col-sm-12 col-xs-12 col-lg-6 hide-mobile-view'>
          <div className='d-flex justify-content-center mh-container'>
            <img
              className='login-image'
              src={getImageSource(ImageConstants.OBX_LOGIN)}
              alt=''
            />
          </div>
        </div>

        <div className='col-md-6 col-sm-12 col-xs-12 col-lg-6 py-4'>
          <div className='d-flex justify-content-center align-items-center'>
            <div className='position-relative'>
              <div className='position-relative rounded p-5 shadow-lg login-card'>
                <div className='icon-wrapper rounded shadow-lg'>
                  <FontAwesomeIcon icon={faUser} />
                </div>

                <h2 className='text-center pt-4'>Admin Login</h2>
                <form onSubmit={handleSubmit} className='mt-4'>
                  <div className='form-floating mb-3'>
                    <input
                      type='email'
                      className='obx-small form-control '
                      id='email'
                      placeholder='Enter email'
                      name='email'
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                    <label className='obx-small' htmlFor='email'>
                      Email *
                    </label>
                  </div>
                  <div className='form-floating mt-4'>
                    <input
                      type='password'
                      className='obx-small form-control'
                      id='password'
                      placeholder='Enter password'
                      name='password'
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                    <label className='obx-small' htmlFor='password'>
                      Password *
                    </label>
                  </div>
                  {errorMessage ? (
                    <div
                      className='text-center error-message pt-4'
                      // onClick={
                      //   errorMessage == "user is not verified yet."
                      //     ? ResendVerifactionLink
                      //     : null
                      // }
                    >
                      <i>
                        {errorMessage == "Account is not verified yet." ? (
                          <>
                            <p>
                              {errorMessage}{" "}
                              <span
                                style={{ color: "#324a98", cursor: "pointer" }}
                                onClick={ResendVerifactionLink}
                              >
                                Click to resend verification email
                              </span>
                            </p>
                          </>
                        ) : (
                          errorMessage
                        )}
                      </i>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className='text-center pt-4'>
                    <button
                      type='submit'
                      className={`w-100 btn btn-block btn-dark align-center ${
                        type === "VENDOR" ? "vendor-button" : "buyer-button"
                      }`}
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;

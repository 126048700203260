import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {!isMobile ? (
      <>
        <BrowserView />
        <App />
        <BrowserView />
      </>
    ) : (
      <MobileView>
        <App />
        <div id='cookie-popup'>
          <p>For a better experience, please use desktop version.</p>
          {/* <button class='cookie-popup__button'>OK</button> */}
        </div>
      </MobileView>
    )}
  </React.StrictMode>
);

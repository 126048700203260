import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Path from "../routes/routes-path";

const Footer = () => {
  return (
    <>
      <Container fluid className="footer">
        <Row className="full-height">
          <Col className="obx-white d-flex align-items-center justify-content-center">
            <img alt="" className="img-120" src="./obx-logo-white.png"></img>
          </Col>
          <Col className="my-auto">
            <div className=" text-white">
              <div className="pt-2 font-24">Quicklinks</div>
              <div className="pt-2">
                <Link
                  to={"/"}
                  className="pt-2 text-white remove-text-decoration"
                >
                  Home
                </Link>
              </div>
              {/* <div className="pt-2 text-white">About</div> */}
              <div className="pt-2 text-white">Announcements</div>
              <div className="pt-2 ">
                <Link
                  to={Path.FAQ}
                  className="text-white remove-text-decoration"
                >
                  FAQ
                </Link>
              </div>
            </div>
          </Col>
          <Col className="my-auto">
            <div className="text-white">
              <div className="pt-2 font-24">Contact</div>
              <div className="pt-2">
                <Link
                  to={Path.CONTACT}
                  className="pt-2 text-white remove-text-decoration"
                >
                  Contact Us
                </Link>
              </div>

              {/* <div className="pt-2">Cookies Policy</div> */}
              <div className="pt-2">
                <Link
                  to={Path.PRIVACY}
                  className="pt-2 text-white remove-text-decoration"
                >
                  Privacy Policy
                </Link>
              </div>
              <div className="pt-2">
                <Link
                  to={Path.TERMS}
                  className="pt-2 text-white remove-text-decoration"
                >
                  Terms and Conditions
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <div className="text-center pt-2">
                ©2023 OBX Pte Ltd. All rights reserved. 
                OBX is developed by <a href="#">Asgardian Labs.</a>
            </div> */}
    </>
  );
};

export default Footer;

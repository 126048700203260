import HttpClient from './http-client';
import URL from './url';
import axios from "axios";

export default class AwsApi {



    static getSignedUrlToView(payload) {
        let params = HttpClient.getParams(payload)
        let paramsList = params.length ? URL.AWS_SIGNED_URL + "?" + params.join('&') : URL.AWS_SIGNED_URL;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static getSignedUrl(payload) {
        let params = HttpClient.getParams(payload)
        let paramsList = params.length ? URL.SIGNED_URL + "?" + params.join('&') : URL.SIGNED_URL;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static uploadFileAwsS3(signedUrl, file, progressCallback) {
        let options = {
            headers: {
                "Content-Type": file.type
            },
            onUploadProgress: progressCallback
        };
        return axios.put(signedUrl, file, options);
    }

}

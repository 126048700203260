import React, { useEffect, useState } from 'react';
import TenderAPI from '../../../apis/tender';
import Loader from '../../common/loading/loading'
import Path from '../../routes/routes-path';
import SessionCache from '../../routes/session-cache';
import ActiveBids from '../active-bids/active-bids';
import VendorHome from '../home/home';
import WatchList from '../watch-list/watch-list';


const stageStatus = {
    1: "Dashboard",
    2: "Active Bids",
    3: "Watch List",
}

const DLeftPanel = (props) => {


    let handler = props.handleClickEvent
    let position = props.position
    const [stage, setStage] = useState(position)

    const handleSelection = (position) => {
        setStage(position)
        handler(position)
    }

    return (

        <div className="shadow rounded p-3">

            <div className='row'>
                <div className='col create-tender'>
                    <div onClick={() => { handleSelection(1) }} className={'obx-btn obx-bb p-3 ' + (stage == 1 ? "obx-btn-selected" : "")}> Dashboard</div>
                    <div onClick={() => { handleSelection(2) }} className={'obx-btn obx-bb p-3 ' + (stage == 2 ? "obx-btn-selected" : "")}> Active Bids</div>
                    <div onClick={() => { handleSelection(3) }} className={'obx-btn obx-bb p-3 ' + (stage == 3 ? "obx-btn-selected" : "")}> Watch List</div>
                </div>
            </div>

        </div>
    );
};

const VendorDashboard = (props) => {

    const [selected, setSelected] = useState(1)
    const [previewUrl, setPreviewURL] = useState("")
    const [isLoading, setLoader] = useState(true)
    const [isPreview, setPreview] = useState(false)
    const [selectedTender, setTender] = useState(null)
    const [showTenderDetail, setShowTenderDetail] = useState(false)
    const [reload, setReloader] = useState(false)

    useEffect(() => {
        let user = SessionCache.getUser()
        if (user.type === "BUYER") {
            window.location.href = Path.BUYER_DASHBOARD
        }
        setLoader(false)
    }, [])

    const handler = (position) => {
        setSelected(position)
        setShowTenderDetail(false)
        setTender(null)
        reloadRightPanel()
    }

    const reloadRightPanel = () => {
        setReloader(true)
        setTimeout(() => {
            setReloader(false)
        }, 100)

    }

    const handleDocumentPreview = (tender) => {
        setTender(tender)
        getPreviewUrl(tender?.documentUrl)
    }

    const getPreviewUrl = (url) => {
        setLoader(true)
        TenderAPI.getPreviewUrl({ url }).then((response) => {
            let url = response?.data.previewUrl
            setPreviewURL(url)
            setPreview(true)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
        })
    }

    const handleHomePageClick = (value) => {
        if (value == 1) props.history.replace(Path.OPPORTUNITIES)
        if (value == 2) setSelected(3)
        if (value == 3) setSelected(2)
    }

    return (
        <div className="container py-4">
            {
                isLoading ? <Loader /> : ""
            }
            <div className="container-fluid">
                <div className='card sshadow-sm obx-blue p-4 mb-4'>

                    <div className='row'>
                        <div className='col obx-white'>
                            <h4>{stageStatus[selected]}</h4>
                        </div>
                    </div>
                </div>
                <div className="create-tender">
                    {
                        <div className="row ">
                            <div className="col-lg-3">
                                <DLeftPanel key={selected} handleClickEvent={handler} position={selected} />
                            </div>
                            <div className="col-lg-9">
                                <div className="">
                                    {selected === 1 && <VendorHome handler={handleHomePageClick} />}
                                    {selected === 2 && <ActiveBids />}
                                    {selected === 3 && <WatchList />}
                                </div>
                            </div>

                        </div>
                    }
                </div>
            </div>
        </div>

    );
};

export default VendorDashboard;

import React, { useEffect, useState, forwardRef } from "react";
import "./tender-detail.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faFileAlt,
  faEye,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import CommonUtils from "../../../utility/common-utilities";
import TenderAPI from "../../../apis/tender";
import AppConfirm from "../../common/app-confirm/app-confirm";
import AwsApi from "../../../apis/aws";
import Loader from "../../common/loading/loading";
import { getImageSource } from "../../../utility/image-source";
import ImageConstants from "../../../constants/image-constants";
import ToasterComponent from "../../common/toaster/toaster";

import SessionCache from "../../routes/session-cache";
import AuthenticateAPI from "../../../apis/authenticate";
import CreateBid from "../submit-bid/submit-bid";
import Chat from "../../chat/chat";

const VendorTenderDetail = (props) => {
  
  const token = window.localStorage.getItem('user')

  const [isLoading, setLoader] = useState(false);
  const [isViewBidDetail, setViewBidDetail] = useState(false);
  const [previewUrl, setPreview] = useState("");
  const [corrigendaPreviewObject, setPreviewCorrigendaObject] = useState(null);
  const [tender, setTender] = useState(props.tender || {});
  const [corrigenda, setcorrigenda] = useState(props.tender?.corrigenda || []);
  const [AvailableVisit,setAvailableVisit] =useState(false)
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [siteDetails, setSiteDetails] = useState([])

  const [showToaster, setToaster] = useState(false);
  const [toaster, setToasterObj] = useState({});
  const [isAuthenticated, setAutheticated] = useState(false);
  const [isViewOnly, setViewMode] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [userInfo, setUserInfo] = useState(null)

  useEffect(() => {
    let isAuthenticated = SessionCache.isAuthenticated();
    if (isAuthenticated) {
      let user = SessionCache.getUser();
      setUserInfo(user)
      setAutheticated(isAuthenticated);
      getIttTenderDetails();
    } else {
      isLoggedin()
    };

  }, []);

  useEffect(() => {
    if(tender?.tenderNumber && userInfo?.id) {
      TenderAPI
        .checkAppointmentAvailability({
          tenderNumber: tender.tenderNumber,
          userId: userInfo.id
        })
      .then((res) => {
        const {available} = res
        setAvailableVisit(available)
      })
      .catch(e => {
        console.error('Error:', e);
        return
      })
    }
  }, [tender]);

  const handleVisitChange = (visitKey) => {
    setSelectedVisit(visitKey);
  };

  const onConfirmVisit = () => {
    let payload = {
      tenderNumber: tender.tenderNumber,
      visitKey: selectedVisit,
      userId: userInfo.id
    }

    TenderAPI
      .createAppointment(payload)
      .then((res) => {
        alert('success set appointment')
        setAvailableVisit(false)
      })
      .catch(e => {
        const {message} = e?.response?.data
        alert(message || e.message)
        console.error('Error:', e);
        return
      })
  }

  const isLoggedin = () => {
    AuthenticateAPI.isLogin({})
      .then((response) => {
        if (response) {
          SessionCache.setUser(response);
          let isAuthenticated = SessionCache.isAuthenticated();
          setAutheticated(isAuthenticated);
          if (isAuthenticated) getIttTenderDetails();
        }
      })
      .catch((err) => {});
  };
  const getIttTenderDetails = (payload = {}) => {
    payload.tid = props?.tender?.tenderNumber || props.tender;
    TenderAPI.getIttTenderDetails(payload)
      .then((response) => {
        setTender(response);
        setcorrigenda(response?.corrigenda || []);
        setSiteDetails(response?.siteDetails || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPreviewUrl = (pdfDocumentKey) => {
    setLoader(true);
    AwsApi.getSignedUrlToView({ url: pdfDocumentKey })
      .then((response) => {
        let url = response.signedUrl;
        setPreview(tender.pdfDocumentUrl);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const closePreview = () => {
    setLoader(false);
    setPreview("");
    setPreviewCorrigendaObject(null);
  };

  const onClickViewBidDetails = () => {
    setViewMode(true);
    setViewBidDetail(true);
  };

  const onClickDocumentPreview = (item, documentKey, index, key) => {
    let corrigendaObj = [...corrigenda];
    item.isLoading = true;
    item.loadingKey = key;
    corrigendaObj[index] = item;
    setcorrigenda(corrigendaObj);

    AwsApi.getSignedUrlToView({ url: item.oldDocumentKey })
      .then((response) => {
        AwsApi.getSignedUrlToView({ url: item.newDocumentKey })
          .then((response1) => {
            setPreviewCorrigendaObject({
              ...item,
              oldDocumentPreviewUrl: response.signedUrl,
              newDocumentPreviewUrl: response1.signedUrl,
            });
            item.isLoading = true;
            item.loadingKey = "";
            corrigendaObj[index] = item;
            setcorrigenda(corrigendaObj);
          })
          .catch((error) => {
            item.isLoading = true;
            item.loadingKey = "";
            corrigendaObj[index] = item;
            setcorrigenda(corrigendaObj);
          });
      })
      .catch((error) => {
        item.isLoading = true;
        item.loadingKey = "";
        corrigendaObj[index] = item;
        setcorrigenda(corrigendaObj);
      });
  };

  const enableToaster = (type, message) => {
    setToasterObj({ type, message });
    setToaster(true);
    setTimeout(() => hideToaster(), 3000);
  };

  const hideToaster = () => {
    setToaster(false);
  };

  const renderPreviewDocument = () => {
    return (
      <div className='vendor-tender-detail text shadow rounded'>
        <div className='row m-2 pt-4'>
          <div className='col'>
            <button onClick={closePreview} className='btn btn-light'>
              <i class='bi bi-arrow-left-short'></i>Back
            </button>
          </div>
        </div>
        <hr />
        <iframe className='preview-iframe' src={previewUrl} />
      </div>
    );
  };



  const getadditonalDoc =(docurl)=>{
    setPreview(docurl)
    return (
      <div className='vendor-tender-detail text shadow rounded'>
        <div className='row m-2 pt-4'>
          <div className='col'>
            <button onClick={closePreview} className='btn btn-light'>
              <i class='bi bi-arrow-left-short'></i>Back
            </button>
          </div>
        </div>
        <hr />
         <iframe className='preview-iframe' src={docurl} /> 
      </div>
    );
  }

  const renderCorrigendaPreviewDocument = () => {
    return (
      <div className='vendor-tender-detail text shadow rounded'>
        <div className='row m-2 pt-4'>
          <div className='col'>
            <button onClick={closePreview} className='btn btn-light'>
              <i class='bi bi-arrow-left-short'></i>Back
            </button>
          </div>
        </div>
        <hr />

        <div className='p-4'>
          <div className='h5 text-center'>{corrigendaPreviewObject?.title}</div>
          <div>
            <div className='pt-4'>
              <lable>Old Document Title : </lable>{" "}
              <b>{corrigendaPreviewObject?.oldDocumentTitle}</b>
            </div>
            <div className='pt-4'>
              <iframe
                className='preview-iframe-1'
                src={corrigendaPreviewObject?.oldDocumentPreviewUrl}
              />
            </div>
          </div>
          <div className='pt-4'>
            <div className='pt-4'>
              <lable>New Document Title : </lable>{" "}
              <b>{corrigendaPreviewObject?.newDocumentTitle}</b>
            </div>
            <div className='pt-4'>
              <iframe
                className='preview-iframe-1'
                src={corrigendaPreviewObject?.newDocumentPreviewUrl}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleBack = () => {
    setViewBidDetail(false);
    getIttTenderDetails();
    setViewMode(false);
  };

  const closeChatHandler = () => {
    setShowChat(false);
  };

  if (isViewBidDetail)
    return (
      <CreateBid
        isAward={props.isAward}
        isViewOnly={isViewOnly}
        showEdit={props.showEdit}
        tender={
          tender?.isAlreadySubmitted ? tender?.tenderSubmission : props.tender
        }
        back={handleBack}
      />
    );
  if (showChat) return <Chat tender={tender} back={closeChatHandler} />;

  let user = SessionCache.getUser();

  const SiteVisitLabel = ({item}) => {
    return (
      <div className='row pt-2'>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          <div className='mb-3'>
            <label
              htmlFor='formControlInput1'
              className='form-label'
            >
              Location: <b>{item?.location || "Not Specified"}</b>{" "}
            </label>
          </div>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12'>
          <div className='mb-3'>
            <label
              htmlFor='formControlInput1'
              className='form-label'
            >
              Visit Time :{" "}
              <b>
                {item?.startDate
                  ? `${CommonUtils.datePipe(
                      item?.startDate,
                      "dd/MM/yyyy hh:mm"
                    )} - ${CommonUtils.datePipe(
                      item?.endDate,
                      "dd/MM/yyyy hh:mm"
                    )}`
                  : "Not Specified"}
              </b>{" "}
            </label>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {!isLoading && previewUrl ? (
        renderPreviewDocument()
      ) : !isLoading && corrigendaPreviewObject ? (
        renderCorrigendaPreviewDocument()
      ) : (
        <div className='vendor-tender-detail py-2 text shadow rounded'>
          {isLoading ? <Loader /> : ""}
          <div className='row m-2 view_docs'>
            <div className='col-3 back-button'>
              <button onClick={props.back} className='btn btn-light'>
                <i class='bi bi-arrow-left-short'></i>Back
              </button>
            </div>
            {(SessionCache.getUserType() === "BUYER" &&
              tender?.status == "AWARDED") ||
            tender?.awardedVendor?.userId === user?.id ? (
              <div
                style={{
                  float: "right",
                  margin: "0 10rem",
                  position: "absolute",
                }}
              >
              {
                tender?.status == "AWARDED"?(
                <img
                  style={{
                    width: "8rem",
                    position: "absolute",
                    rotate: "-24deg",
                  }}
                  src={getImageSource(ImageConstants.AWARDED)}
                ></img>
            ):null}
              </div>
            ) : (
              ""
            )}
            {isAuthenticated ? (
              <div className='col d-flex justify-content-end'>
                {tender?.pdfDocumentKey ? (
                  <button
                    onClick={() => getPreviewUrl(tender.pdfDocumentKey)}
                    type='button'
                    className='btn btn-sm btn-primary obx-btn-color mr-2'
                  >
                    {" "}
                    <FontAwesomeIcon icon={faEye} /> View Docs
                  </button>
                ) : (
                  ""
                )}
              {
                  tender.pdfDocumentUrlanothetr?(
                    <button
                    onClick={() => getadditonalDoc(tender.pdfDocumentUrlanothetr)}
                    type='button'
                    className='btn btn-sm btn-primary obx-btn-color mr-2'
                  >
                    {" "}
                    <FontAwesomeIcon icon={faEye} /> view additonal doc
                  </button>
                  ):null
              }
              <button
                  type='button'
                  onClick={() => setShowChat(true)}
                  className='btn btn-sm btn-primary obx-btn-color'
                >
                  {" "}
                  <FontAwesomeIcon icon={faMessage} /> {" "}
                </button>
                
              </div>
            ) : (
              ""
            )}
             
         
            
          </div>
          <hr />
          <div className='position-relative m-4'>
            <div>
              <div>
                <span className='h6 obx-ls-t'>
                  Facility Details - {tender?.tenderNumber}
                </span>

                <hr />
              </div>
              <div className=''>
                <div className='row  obx-ls-st'>
                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Facility Name : <b>{tender?.facilityName}</b>{" "}
                      </label>
                    </div>
                  </div>
                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Facility Type :{" "}
                        <b>
                          {CommonUtils.camelCase(tender?.facilityType || "")}
                        </b>{" "}
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Facility Postal Code :{" "}
                        <b>{tender?.facilityPostalCode}</b>{" "}
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Street Address : <b>{tender?.streetAddress}</b>{" "}
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Status : <b>{tender?.status}</b>{" "}
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-2'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Services :{" "}
                        {tender?.selectedServices?.map((service) => (
                          <span className='me-2 badge text-bg-light obx-text-dgray text'>
                            {service?.title}
                          </span>
                        ))}
                      </label>
                    </div>
                  </div>

                  <div className='mb-4'></div>

                  <div className=''>
                    <span className='h6 obx-ls-t'>Important Dates</span>
                  </div>
                  <div>
                    <hr />
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className='mb-1'>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Publication Date :
                        <b>
                          {CommonUtils.datePipe(
                            tender?.terms?.publicationDate,
                            "dd/MM/yyyy"
                          )}
                        </b>
                      </label>
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12'>
                    <div className=''>
                      <label htmlFor='formControlInput1' className='form-label'>
                        Submission Deadline Date :
                        <b>
                          {CommonUtils.datePipe(
                            tender?.terms?.submissionDeadlineDate,
                            "dd/MM/yyyy"
                          )}
                        </b>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-4 text'>
              <div>
                <span className='h6 obx-ls-t'>Site Visit Slots</span>
                <hr />
              </div>

              {
                siteDetails.length > 0 &&
                  <div style={{marginLeft:'1rem'}}>
                    {siteDetails.map((item) => SessionCache.getUserType() === "VENDOR" 
                      ? (
                        <div className='row pt-2 mb-2' key={item.key}>
                          <div 
                            class="form-check form-check-inline"  
                            onChange={() => handleVisitChange(item.key)}
                          >
                            <input 
                              className="form-check-input" 
                              type="radio" 
                              id={`site-${item.key}`}
                              name="visit-site"
                              value={item.key}
                              disabled={
                                !AvailableVisit ||
                                item.disabled || 
                                tender?.status==='CLOSED'
                              }
                              checked={selectedVisit === item.key}
                            />
                            <label 
                              class="form-check-label row" 
                              for={`site-${item.key}`}
                            >
                              <span className="col-12 col-md-6">
                                Location: <b>{item?.location || "Not Specified"}</b>
                              </span>
                              <span className="d-block col-12 col-md-6 pl-sm-0" >
                                Visit Time:{" "}
                                <b>{
                                  item?.startDate
                                    ? `${CommonUtils.datePipe(
                                        item?.startDate,
                                        "dd/MM/yyyy hh:mm"
                                      )} - ${CommonUtils.datePipe(
                                        item?.endDate,
                                        "dd/MM/yyyy hh:mm"
                                      )}`
                                    : "Not Specified"
                                }</b>
                              </span>
                            </label>
                          </div>
                        </div>
                      )
                      : <SiteVisitLabel item={item}/>
                    )}
                    {
                      (SessionCache.getUserType() === "VENDOR" && 
                      tender?.status !== 'CLOSED') &&
                      <button
                        disabled={!AvailableVisit || !selectedVisit}
                        type='button'
                        className='btn btn-md btn-primary mr-2'
                        onClick={onConfirmVisit}
                      >Confirm</button>
                    }
                  </div>
              }
            </div>
            {corrigenda?.length ? (
              <div className='pt-4'>
                <div className=''>
                  <span className='h6 obx-ls-t'>Corrigenda</span>
                </div>
                <div>
                  <hr />
                </div>

                <div className=''>
                  {corrigenda?.length ? (
                    corrigenda?.map((item, index) => (
                      <div
                        className='p-4 mt-4 border-wrapper position-relative'
                        key={item.key}
                      >
                        <div>
                          <label className='pb-1'>
                            Title : <b>{item?.title}</b>
                          </label>
                        </div>
                        <div className='row pt-4'>
                          <div className='col-6'>
                            <div>
                              <label className='pb-1'>
                                Old Document Title :{" "}
                                <b>{item?.oldDocumentTitle}</b>{" "}
                              </label>
                            </div>
                            <div className='pt-4'>
                              <label className='pb-1'>Old Document </label>

                              <div>
                                <div
                                  className='doument-preview-wrapper text-center mr-2'
                                  onClick={() =>
                                    onClickDocumentPreview(
                                      item,
                                      item.oldDocumentKey,
                                      index,
                                      "oldDocument"
                                    )
                                  }
                                >
                                  <div className='doument-preview'>
                                    <div className='text-center'>
                                      {item.isLoading &&
                                      item?.loadingKey === "oldDocument" ? (
                                        <div className=''>
                                          <FontAwesomeIcon
                                            className='icon '
                                            spin
                                            icon={faSpinner}
                                          />
                                        </div>
                                      ) : (
                                        <div className=''>
                                          <FontAwesomeIcon
                                            className='icon'
                                            icon={faFileAlt}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-6'>
                            <div>
                              <label className='pb-1'>
                                New Document Title :{" "}
                                <b>{item?.newDocumentTitle}</b>{" "}
                              </label>
                            </div>

                            <div className='pt-4'>
                              <label className='pb-1'>New Document </label>
                              <div>
                                <div
                                  className='doument-preview-wrapper text-center mr-2'
                                  onClick={() =>
                                    onClickDocumentPreview(
                                      item,
                                      item.oldDocumentKey,
                                      index,
                                      "newDocument"
                                    )
                                  }
                                >
                                  <div className='doument-preview'>
                                    <div className='text-center'>
                                      {item.isLoading &&
                                      item?.loadingKey === "newDocument" ? (
                                        <div className=''>
                                          <FontAwesomeIcon
                                            className='icon '
                                            spin
                                            icon={faSpinner}
                                          />
                                        </div>
                                      ) : (
                                        <div className=''>
                                          <FontAwesomeIcon
                                            className='icon'
                                            icon={faFileAlt}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className='text-center'>No records found</div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className='pt-4 text-right'>
              {isAuthenticated ? (
                <div>
                  {SessionCache.getUserType() === "VENDOR" ? (
                    <div>
                      {tender?.isAlreadySubmitted ? (
                        <button
                          onClick={onClickViewBidDetails}
                          type='button'
                          className='btn btn-md btn-primary mr-2'
                        >
                          View Bid Details
                        </button>
                      ) : (
                        (tender?.isMaxedBidder || tender?.status === 'CLOSED')
                          ? ""
                          :<button
                              onClick={() => setViewBidDetail(true)}
                              type='button'
                              className='btn btn-md btn-primary mr-2'
                            >
                              Bid for Tender
                            </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <button
                  onClick={props.onClickLogin}
                  type='button'
                  className='btn btn-md btn-primary mr-2'
                >
                  Login to Participate
                </button>
              )}
              {props.isAward ? (
                <button
                  onClick={onClickViewBidDetails}
                  type='button'
                  className='btn btn-md btn-primary mr-2'
                >
                  View Bid Details
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
      {showToaster ? (
        <ToasterComponent type={toaster?.type} message={toaster?.message} />
      ) : (
        ""
      )}
    </>
  );
};

export default VendorTenderDetail;

import HttpClient from "./http-client";
import HttpClients from './http-clinet-with-image'
import URL from "./url";

export default class TenderAPI {
  static getTenders(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.PUBLIC_TENDERS + "?" + params.join("&")
      : URL.PUBLIC_TENDERS;

      console.log(URL.BASE_URL + paramsList, payload,"code her")
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getAnnouncement(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.ANNOUNCEMENT + "?" + params.join("&")
      : URL.ANNOUNCEMENT;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getIttTenderFilterByStatus(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.TENDER + "?" + params.join("&")
      : URL.TENDER;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getTenderDetails(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.BUYER_TENDERS_DETAILS + "?" + params.join("&")
      : URL.BUYER_TENDERS_DETAILS;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getVendors(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.VENDORS + "?" + params.join("&")
      : URL.VENDORS;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getServices(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.SERVICES + "?" + params.join("&")
      : URL.SERVICES;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getOutComes(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.OUTCOMES + "?" + params.join("&")
      : URL.OUTCOMES;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getKpis(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.KPIS + "?" + params.join("&")
      : URL.KPIS;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static createTender(payload, file) {
    return HttpClient.post(URL.BASE_URL + URL.CREATE_TENDER, null, payload);
  }

  static getPreviewUrl(payload) {
    return HttpClient.post(URL.BASE_URL + URL.PREVIEW_URL, null, payload);
  }

  static getEditUrl(payload) {
    return HttpClient.post(URL.BASE_URL + URL.EDIT_URL, null, payload);
  }

  static publishTender(payload) {
    return HttpClient.post(URL.BASE_URL + URL.PUBLISH_TENDERS, null, payload);
  }

  static updateTender(payload) {
    return HttpClient.put(URL.BASE_URL + URL.TENDER, null, payload);
  }

  static updateTenderStatus(payload) {
    return HttpClient.put(URL.BASE_URL + URL.TENDER_STATUS, null, payload);
  }

  static getBuyerTenders(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.GET_BUYER_TENDERS + "?" + params.join("&")
      : URL.GET_BUYER_TENDERS;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static ITTTenderSubmission(payload) {
    return HttpClient.post(URL.BASE_URL + URL.ITT_TENDER, null, payload);
  }

  static getIttTenderDetails(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.ITT_TENDERS_DETAILS + "?" + params.join("&")
      : URL.ITT_TENDERS_DETAILS;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getIttTenderSubmissionList(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.ITT_TENDER + "?" + params.join("&")
      : URL.ITT_TENDER;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getIttTenderSubmissionDetail(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.ITT_TENDER_SUBMISSION_DETAILS + "?" + params.join("&")
      : URL.ITT_TENDER_SUBMISSION_DETAILS;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getIttTenderSubDetail(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.ITT_TENDER_SUB_DETAILS + "?" + params.join("&")
      : URL.ITT_TENDER_SUB_DETAILS;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static updateIttTenderDetail(payload) {
    return HttpClient.put(URL.BASE_URL + URL.ITT_TENDER, null, payload);
  }

  static addBookmark(payload) {
    return HttpClient.post(URL.BASE_URL + URL.WATCH_LIST, null, payload);
  }

  static removeBookmark(payload) {
    return HttpClient.delete(URL.BASE_URL + URL.WATCH_LIST, null, payload);
  }

  static awardTender(payload) {
    return HttpClient.put(URL.BASE_URL + URL.AWARD_TENDER, null, payload);
  }

  static getBookmarks(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.WATCH_LIST + "?" + params.join("&")
      : URL.WATCH_LIST;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getIttTenderSubmissionForBuyer(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.ITT_TENDER_FOR_BUYER + "?" + params.join("&")
      : URL.ITT_TENDER_FOR_BUYER;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static getTenderTransaction(pageNumber, perPage, payload) {
    return HttpClient.get(
      URL.BASE_URL +
        URL.BUYER_INVOICES +
        `?page=${pageNumber}&limit=${perPage}`,
      null,
      payload
    );
  }

  static getVendorTransaction(payload, perPage, pageNumber) {
    return HttpClient.get(
      URL.BASE_URL +
        URL.VENDOR_INVOICES +
        `?page=${pageNumber}&limit=${perPage}`,
      null,
      payload
    );
  }

  static getFilterIttTenderCategory(payload){
     return HttpClient.post(URL.BASE_URL+URL.ITT_TENDER_CATEGORY,null,payload)
  }


  static createTenderByCustom(payload) {
    return HttpClient.post(URL.BASE_URL + URL.CREATE_TENDER_CUSTOM, null, payload);
  }

  static UpladAfile(payload) {
    return HttpClient.post(URL.BASE_URL + URL.CREATE_TENDER_CUSTOM, null, payload);
  }

  static createAppointment(payload) {
    return HttpClient.post(URL.BASE_URL + URL.CREATE_APPOINTMENT, null, payload);
  }
  static checkAppointmentAvailability(payload) {
    return HttpClient.get(
      URL.BASE_URL + 
      URL.CHECK_USER_APPOINTMENT_AVAILABILITY +
      `?tenderNumber=${payload.tenderNumber}`,
      null, payload);
  }
}
 
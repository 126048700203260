import React, { useEffect, useState } from 'react';
import TenderCard from '../../cards/tender-card/tender-card';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CommonUtils from '../../../utility/common-utilities';
import TenderDetails from '../tender-detail/tender-detail';
import './watch-list.scss'
import Path from "../../routes/routes-path";
import { getImageSource } from '../../../utility/image-source';
import ImageConstants from '../../../constants/image-constants';
import TenderAPI from '../../../apis/tender';
import Pagination from '../../common/pagination/pagination';
import AuthenticateAPI from '../../../apis/authenticate';
import SessionCache from '../../routes/session-cache';
import NoRecords from '../../no-records/no-records';

const WatchList = (props) => {

  const [dueDate, setDueDate] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [tenderId, setTenderId] = useState("");
  const [budget, setBudget] = useState("");
  const [isViewTender, setViewTender] = useState(false);
  const [selectedTender, setSelectedTender] = useState(null);
  const [tenders, setTenders] = useState([])
  const [pageObject, setPageObject] = useState({})

  const [isLoading, setLoading] = useState(true);
  const [isAuthenticated, setAutheticated] = useState(false);


  useEffect(() => {
    getOppertunities()
  }, [])


  const onViewTender = (tender) => {
    setViewTender(true)
    setSelectedTender(tender)
  }

  const onClickLogin = () => {
    props.history.push(Path.LOGIN)
  }



  const getOppertunities = (payload = {}) => {
    if (!payload.page) payload.page = pageObject.currentPage || 1

    TenderAPI.getBookmarks(payload).then((response) => {
      let data = response.data
      setPageObject(response?.pagination || {})
      setTenders(data)
      setLoading(false)
    }).catch((err) => {
      setLoading(false)

    })

  }

  const onPageSelected = (selectedPage, startIndex) => {
    pageObject.currentPage = selectedPage
    pageObject.startIndex = startIndex
    setPageObject(pageObject)

    getOppertunities({ page: selectedPage })

  }

  const handleBack = () => {
    setViewTender(false)
  }

  const onClickBookmark = (tender, index) => {
    tender.isBookmark = !tender.isBookmark
    let tenderArr = [...tenders]
    tenderArr[index] = tender
    setTenders(tenderArr)

    if (!tender.isBookmark) TenderAPI.removeBookmark({ "tid": tender.tenderNumber })
    else TenderAPI.addBookmark({ "tenderNumber": tender.tenderNumber })
  }

  return (

    <>


      <div className="watch-list">

        <div className="pb-4">
          {
            isViewTender ? <TenderDetails tender={selectedTender} onClickLogin={onClickLogin} back={handleBack} /> :

              <div className="">
                {
                  tenders?.length ? tenders.map((tender, index) => (<div className='mb-3'><TenderCard tender={tender} onView={onViewTender} showBookmark onClickBookmark={(tender) => onClickBookmark(tender, index)} /></div>))
                    : !isLoading ?
                      <NoRecords /> : ""
                }
              </div>
          }
        </div>
        {
          pageObject?.totalPages && !isViewTender ?
            <Pagination
              startIndex={pageObject?.startIndex || 1}
              selectedPage={pageObject?.currentPage || 1}
              totalPages={pageObject?.totalPages || 0}
              onPageSelected={onPageSelected.bind(this)} /> : ""
        }

      </div>
    </>
  );
};

export default WatchList;

import { useEffect, useState } from "react";
import axios from "axios";
import Urls from "../../apis/url";
import Loading from "../common/loading/loading";

const BuyerTableList = (props) => {
  return (
    <>
      <tr>
        <th scope="row">{props.index}</th>
        <td>{props.name}</td>
        <td>{props.address}</td>
        <td>{props.uen}</td>
      </tr>
    </>
  );
};

const BuyerList = (props) => {
  const [BuyerList, SetBuyerList] = useState([]);
  const [loading, SetLoading] = useState(true);
  useEffect(() => {
    async function getBuyerList() {
      try {
        const res = await axios.post(`${Urls.BASE_URL}${Urls.BUYER_LIST}`);
        SetLoading(false);
        SetBuyerList(res.data.FilteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    getBuyerList();
  }, []);

  const UpdatedBuyerList = BuyerList.map((data, index) => {
    return (
      <BuyerTableList
        index={index + 1}
        key={data._id}
        name={data.name}
        address={data.address}
        uen={data.uanNumber}
      />
    );
  });

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="m-4 border rounded">
          <table className="table text-leftr">
            <thead>
              <tr className="fs-5">
                <th scope="col">#</th>
                <th scope="col">Buyer Name</th>
                <th scope="col">Address</th>
                <th scope="col">Buyer UEN</th>
              </tr>
            </thead>
            <tbody>{UpdatedBuyerList}</tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default BuyerList;

import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../common/loading/loading";
import "../../../styles/fonts.scss";
import "./admin-dashboard.scss";
import "../../create-tender/create-tender.scss";
import AdminSettings from "../../../apis/admin-settings";
import Path from '../../routes/routes-path';
import SessionCache from '../../routes/session-cache';
import BuyerSubscriptionSetting from "./buyer-setting/subscription-setting";
import VendorSubscriptionSetting from "./vendor-setting/subscription-setting";

const stageStatus = {
  1: "BUYER_SUBSCRPTION",
  2: "PUBLISH_PAYMENT",
  3: "VENDOR_SUBSCRIPTION",
  4: "BIDDING_PAYMENT"
};

const DLeftPanel = (props) => {
  let handler = props.handleClickEvent;
  let position = props.position;
  const [stage, setStage] = useState(position);

  const handleSelection = (position) => {
    setStage(position);
    handler(position);
  };

  return (
    <div className="shadow rounded p-3">
      <div className="row">
        <div className="col create-tender">
          <div
            onClick={() => {
              handleSelection(1);
            }}
            className={
              "header_title_head obx-btn obx-bb p-3 " +
              (stage >= 1 && stage <= 2 ? "obx-header-btn-selected" : "")
            }
          >
            {" "}
            Buyer Setting
          </div>
          {
            <div>
              <div
                onClick={() => {
                  handleSelection(1);
                }}
                className={
                  "header_title_head obx-btn obx-bb p-3 sub-header ms-4 " +
                  (stage == 1 ? "obx-btn-selected" : "")
                }
              >
                Subscription Setting
              </div>
              {/* <div
                onClick={() => {
                  handleSelection(2);
                }}
                className={
                  "header_title_head obx-btn obx-bb p-3 sub-header ms-4 " +
                  (stage == 2 ? "obx-btn-selected" : "")
                }
              >
                Publish Payment Setting
              </div> */}
            </div>
            
          }

          {/* Vendor Setting */}
          <div
            onClick={() => {
              handleSelection(3);
            }}
            className={
              "header_title_head obx-btn obx-bb p-3 " +
              (stage >= 3 && stage <= 4 ? "obx-header-btn-selected" : "")
            }
          >
            {" "}
            Vendor Setting
          </div>
          {
            <div>
              <div
                onClick={() => {
                  handleSelection(3);
                }}
                className={
                  "header_title_head obx-btn obx-bb p-3 sub-header ms-4 " +
                  (stage == 3 ? "obx-btn-selected" : "")
                }
              >
                Subscription Setting
              </div>
              {/* <div
                onClick={() => {
                  handleSelection(4);
                }}
                className={
                  "header_title_head obx-btn obx-bb p-3 sub-header ms-4 " +
                  (stage == 4 ? "obx-btn-selected" : "")
                }
              >
                Bidding Payment Setting
              </div> */}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

const Dashboard = (props) => {
  const [selected, setSelected] = useState(1);
  const [isLoading, setLoader] = useState(true);
  const [showTenderDetail, setShowTenderDetail] = useState(false);
  const [reload, setReloader] = useState(false);
  const [errorKey, setErrorKey] = useState("");
  const [payload, setPayload] = useState({
    is_subscription_active: false,
    subscription_amount: null,
    subscription_duration_month: null
  });

  useEffect(() => {
    let user = SessionCache.getUser();
    if (user.type === "VENDOR") {
      window.location.href = Path.DASHBOARD;
    }else if (user.type === "BUYER") {
        window.location.href = Path.BUYER_DASHBOARD
    } else {
      getBuyerSetting()
      setLoader(false);
    }

    
  }, []);

  const getBuyerSetting = () => {
    setLoader(true)
    AdminSettings
      .getBuyerSubscriptionSetting({})
      .then((res) => {
        console.log('buyersetting', res);
        setPayload(res);
        setErrorKey("");
        setLoader(false);
      }).catch((err) => {
        console.log(err)
        // setErrorKey(JSON.parse(err).message);
        setLoader(false);
      })
  }

  const handler = (position) => {
    setSelected(position);
    setShowTenderDetail(false);
    reloadRightPanel();
  };

  const reloadRightPanel = () => {
    setReloader(true);
    setTimeout(() => {
      setReloader(false);
    }, 100);
  };

  const handlePayloadChange = (value, key) => {
    let payloadObj = { ...payload };
    payloadObj[key] = value;
    setPayload(payloadObj);
  };

  return (
    <div className="container py-4">
      {isLoading ? <Loader /> : ""}
      <div className="container-fluid">
        <div className="card shadow-sm obx-blue p-4 mb-4">
          <div className="row">
            <div className="col obx-white header_title_head">
              <h4>Administrator Dashboard</h4>
            </div>
          </div>
        </div>
        <div className="admin-setting">
          <div className="row ">
            <div className="col-lg-3 mb-4">
              <DLeftPanel
                key={selected}
                handleClickEvent={handler}
                position={selected}
              />
            </div>
            <div className="col-lg-9">
              {
                !reload
                  ? selected == 1
                    ? <BuyerSubscriptionSetting/>
                    : selected == 3
                    ? <VendorSubscriptionSetting/>
                    : ""
                  : null
              }
              {/* <div className="shadow rounded p-3 justify-content-center">
                <span className="h6 header_title_caption fs-5">Buyer Setting</span>
                <hr></hr>
                <div className="mb-3">
                  <div className="row">
                    <label
                      htmlFor="formControlInput1"
                      className="form-label header_title_caption col-3"
                    >
                      Is Subscription Active
                    </label>
                    <div className="col-4">
                      <Switch 
                      onChange={() =>{}} 
                      checked={payload.is_subscription_active} 
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="row">
                    <label
                      htmlFor="formControlInput1"
                      className="form-label header_title_caption col-3"
                    >
                      Subscription Amount (SGD)
                    </label>
                    <div className="col-4">
                      <input
                        placeholder="$"
                        type="number"
                        className={`form-control custom-input ${
                          errorKey === "subscription_amount"
                            ? "invalid-input"
                            : ""
                        }`}
                        value={payload.subscription_amount}
                        onChange={(event) =>
                          handlePayloadChange(
                            event?.target?.value,
                            "subscription_amount"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="row">
                    <label
                      htmlFor="formControlInput1"
                      className="form-label header_title_caption col-3"
                    >
                      Subscription Duration (in month)
                    </label>
                    <div className="col-4">
                      <input
                        type="number"
                        step={1}
                        className={`form-control custom-input ${
                          errorKey == "subscription_duration_month" ? "invalid-input" : ""
                        }`}
                        id="facilityControl"
                        value={payload.subscription_duration_month}
                        onChange={(event) =>
                          handlePayloadChange(
                            event?.target?.value,
                            "subscription_duration_month"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center pt-4">
                    <button
                      onClick={()=>{}}
                      type="button"
                      className="btn btn-sm btn-primary obx-btn-color float-end"
                      style={{paddingLeft:15, paddingRight:15}}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

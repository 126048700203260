import HttpClient from "./http-client";
import URL from "./url";

export default class AuthenticateAPI {
  static isLogin(payload) {
    let params = [];
    if (payload) {
      Object.keys(payload).forEach((key) => {
        params.push(`${key}=${payload[key]}`);
      });
    }
    let paramsList = params.length
      ? URL.IS_LOGGED_IN + "?" + params.join("&")
      : URL.IS_LOGGED_IN;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static logout(payload) {
    return HttpClient.post(URL.BASE_URL + URL.LOGOUT, null, payload);
  }

  static login(payload) {
    return HttpClient.post(URL.BASE_URL + URL.LOGIN, null, payload);
  }

  static signup(payload) {
    return HttpClient.post(URL.BASE_URL + URL.SIGNUP, null, payload);
  }

  static forgortPassword(payload) {
    let params = [];
    if (payload) {
      Object.keys(payload).forEach((key) => {
        params.push(`${key}=${payload[key]}`);
      });
    }
    let paramsList = params.length
      ? URL.REQUEST_FORGOT_PASSWORD + "?" + params.join("&")
      : URL.REQUEST_FORGOT_PASSWORD;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static VerifyEmail(userId, payload) {
    let paramsList = URL.EMAIL_VERIFY + `?id=${userId}`;
    return HttpClient.get(URL.BASE_URL + paramsList, null, payload);
  }

  static ResendVerifyLink(payload) {
    return HttpClient.post(URL.BASE_URL + URL.ResendVerifyLink, null, payload);
  }

  static validateForgortPassword(payload) {
    return HttpClient.put(
      URL.BASE_URL + URL.VERIFY_FORGOT_PASSWORD,
      null,
      payload
    );
  }

  static updatePassword(payload) {
    return HttpClient.put(URL.BASE_URL + URL.RESET_PASSWORD, null, payload);
  }

  static updateUser(payload) {
    console.log(payload, "hello");
    return HttpClient.put(URL.BASE_URL + URL.PROFILE, null, payload);
  }

  static getUserProfile(payload) {
    let params = HttpClient.getParams(payload);
    let paramsList = params.length
      ? URL.PROFILE + "?" + params.join("&")
      : URL.PROFILE;
    return HttpClient.get(URL.BASE_URL + paramsList, payload);
  }

  static postContactMessage(payload) {
    return HttpClient.post(URL.BASE_URL + URL.CONTACT, null, payload);
  }
}

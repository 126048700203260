// import "./transactions.css";
// import TitleHeader from "../common/title-header/title-header";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import TenderAPI from "../../apis/tender";
import Loader from "../common/loading/loading";

const columns = [
  {
    name: "S/N",
    selector: (row, index) => index + 1,
  },
  {
    name: "Payment Date",
    selector: (row) => row.createdDate.substring(0, 10),
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row) =>
      !row.payment_intent ? null : row.payment_intent.amount_total?row.payment_intent.amount_total/100:row.payment_intent.amount/100,
    sortable: true,
  },
  {
    name: "Tender ID",
    selector: (row) => row.tenderNumber,
    sortable: true,
  },
  {
    name: "Payment Status",
    selector: (row) => row.payment_status,
  },
];

const BuyerTranasction = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setLoader] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const GetViewTransaction = (page, perPage) => {
    TenderAPI.getTenderTransaction(page, perPage)
      .then((response) => {
        console.log(response.data);
        setTransactions(response.data);
        console.log(response.data.payment_intent);
        setTotalRows(response.pagination.totalPages);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    setLoader(false);
    GetViewTransaction(1, perPage); // Initialize the data with page 1
  }, []);

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='row mt-5'>
              <div className='col-lg-12'>
                <h5 className='heading-invoice'>Invoices</h5>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <DataTable
                  columns={columns}
                  data={transactions.length > 0 ? transactions : []}
                  pagination
                  paginationResetDefaultPage={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerTranasction;

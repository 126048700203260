import React, { useEffect, useState, forwardRef } from "react";
import "./tender-list.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faFileAlt,
  faSave,
  faGlobe,
  faEdit,
  faEye,
  faSpinner,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import CommonUtils from "../../utility/common-utilities";
import TenderAPI from "../../apis/tender";
import AppConfirm from "../common/app-confirm/app-confirm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AwsApi from "../../apis/aws";
import Loader from "../common/loading/loading";
import { getImageSource } from "../../utility/image-source";
import ImageConstants from "../../constants/image-constants";
import ToasterComponent from "../common/toaster/toaster";
import NoRecords from "../no-records/no-records";
import SessionCache from "../routes/session-cache";
import Chat from "../chat/chat";
import { useHistory } from "react-router-dom";
import Urls from "../../apis/url";

const TenderDetail = (props) => {
  let token =window.localStorage.getItem('user')
  const history = useHistory();
  const [isLoading, setLoader] = useState(false);
  const [previewUrl, setPreview] = useState("");
  const [corrigendaPreviewObject, setPreviewCorrigendaObject] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState({});
  const [errorKey, setErrorKey] = useState("");
  const [errorIndex, setErrorIndex] = useState("");
  const [isEdit, setEdit] = useState(false);
  const[btndisable,setbtndisable]=useState(false)
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedAction, setActionType] = useState("");
  const [tender, setTender] = useState(props.tender || {});
  const [corrigenda, setcorrigenda] = useState(props.tender?.corrigenda || []);
  const [payload, setPayload] = useState({
    publicationDate: new Date(props?.tender?.terms?.publicationDate),
    submissionDeadlineDate: new Date(
      props?.tender?.terms?.submissionDeadlineDate
    ),
    file:props.tender.pdfDocumentKeyanother
  });
  const [showModal, setShowModal] = useState(false);
  const [confirmModalObj, setConfirmModalObj] = useState({});

  const [showToaster, setToaster] = useState(false);
  const [toaster, setToasterObj] = useState({});
  const [showChat, setShowChat] = useState(false);
  const [siteDetails, setSiteDetails] = useState(props?.tender?.siteDetails || []);

  useEffect(() => {
    getBuyerTenderDetails();
  }, []);
  console.log(tender, "mera name ");

  const handlePayloadChange = (value, key) => {
    let payloadObj = { ...payload };
    payloadObj[key] = value;
    if (payloadObj.publicationDate > payloadObj.submissionDeadlineDate) {
      payloadObj.submissionDeadlineDate = "";
    }
    setPayloadObject(payloadObj);
  };

  const handleSiteDetailsChange = (value, key, index) => {
    let payloadObj = [...siteDetails];
    payloadObj[index][key] = value;

    if (key === 'maxVisit') {
      payloadObj[index][key] = parseInt(value) ?? 0;
    }
    setSiteDetails(payloadObj);
  };

  const handleDateEvent = (event, picker, index) => {
    let payloadObj = [...siteDetails];
    payloadObj[index].startDate = picker.startDate.toDate();
    payloadObj[index].endDate = picker.endDate.toDate();

    setSiteDetails(payloadObj);
   
  };

  const onClickRemove = (index) => {
    let payloadObj = [...siteDetails];

    let len = payloadObj.length;

    if (len === 1) {
      payloadObj = [];
    } else {
      payloadObj.splice(index, 1);
    }
    setSiteDetails([...payloadObj]);
  };

  const onClickAddVisitDetails = () => {
    for (let i = 0; i < siteDetails?.length; i++) {
      let validation = CommonUtils.validateCreateTenderPayload(siteDetails[i], 6);

      if (validation?.error) {
        setErrorKey(validation.key);
        setErrorIndex(i);
        return;
      }
    }
    let payloadObj = [...siteDetails, { key: Math.random() }];
    setSiteDetails(payloadObj);
  };

  const saveVisitDetails = () => {
    let payloadObj = [...siteDetails];
    payloadObj = payloadObj.filter(v => v.startDate && v.endDate)
    let updatePayload = {
      tenderNumber: tender?.tenderNumber,
      siteDetails: payloadObj,
    };

    setLoader(true);
    TenderAPI.updateTender(updatePayload)
      .then((response) => {
        setLoader(false);
        enableToaster("success", "Saved Successfully");
        setSiteDetails(payloadObj)
        history.push('/buyer/dashboard')
        // window.location.reload()
      })
      .catch((err) => {
        setLoader(false);
        enableToaster("error", "Oops, Something went wrong");
      });
  };

  const setPayloadObject = (payloadObj) => {
    setPayload(payloadObj);
  };

  const getBuyerTenderDetails = (payload = {}) => {
    payload.id = props?.tender?.tenderNumber;
    TenderAPI.getTenderDetails(payload)
      .then((response) => {
        setTender(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickVersion = (item) => {
    setSelectedVersion(item);
    getPreviewUrl(item.documentUrl);
  };

  const getPreviewUrl = (url) => {
    setLoader(true);
    TenderAPI.getPreviewUrl({ url })
      .then((response) => {
        let url = response?.data.previewUrl;
        setLoader(false);
        setPreview(url);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const closePreview = () => {
    setLoader(false);
    setPreview("");
    setSelectedVersion(null);
    setPreviewCorrigendaObject(null);
  };

  const addcorrigenda = () => {
    let corrigendaObj = [...corrigenda, { key: Math.random(), isNew: true }];
    setcorrigenda(corrigendaObj);
  };

  const removeCorrigenda = (index) => {
    let corrigendaObj = [...corrigenda];
    corrigendaObj.splice(index, 1);
    setcorrigenda(corrigendaObj);
  };

  const handleCorigenda = (value, index, key) => {
    let corigentaObj = [...corrigenda];
    corigentaObj[index][key] = value;
    setcorrigenda(corigentaObj);
    setErrorKey("");
  };

  const onSelectFile = (event, index, key) => {
    let file = event.target.files[0];
    if (!file) return;
    let fileName = file.name.split(".");
    let extention = fileName[fileName.length - 1];
    fileName.length = fileName.length - 1;
    let name = `${fileName.join("")}_${new Date().getTime()}.${extention}`;
    let payload = {
      fileName: name,
      type: "buyer",
      tid: tender?.tenderNumber,
    };
    setLoader(true);
    setErrorKey("");
    AwsApi.getSignedUrl(payload)
      .then((response) => {
        uploadFile(response.signedUrl, file, index, key);
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const uploadFile = (signedUrl, file, key, index) => {
    AwsApi.uploadFileAwsS3(signedUrl, file)
      .then((response) => {
        let urls = signedUrl.split("?");
        let splittedSignedUrl = urls[0].split(".com/");
        let image = splittedSignedUrl[splittedSignedUrl.length - 1];

        let corrigendaObj = [...corrigenda];
        corrigendaObj[index][`${key}Url`] = urls[0];
        corrigendaObj[index][`${key}Key`] = image;
        setcorrigenda(corrigendaObj);
        setLoader(false);
        enableToaster("success", "Uploads Done");
      })
      .catch((error) => {
        setLoader(false);
        enableToaster("error", "Oops, Something went wrong");
      });
  };

  const saveDates = () => {
    let updatePayload = {
      tenderNumber: tender?.tenderNumber,
      publicationDate: payload?.publicationDate,
      submissionDeadlineDate: payload?.submissionDeadlineDate,
      pdfDocumentKeyanother:payload?.file
    };
    if (!payload?.publicationDate) {
      setErrorKey("publicationDate");
      return;
    }
    if (!payload?.submissionDeadlineDate) {
      setErrorKey("submissionDeadlineDate");
      return;
    }
  console.log(updatePayload,"updatePayloadupdatePayload")

    setLoader(true);
    TenderAPI.updateTender(updatePayload)
      .then((response) => {
        setLoader(false);
        enableToaster("success", "Saved Successfully");
        history.push('/buyer/dashboard')
        // window.location.reload()
      })
      .catch((err) => {
        setLoader(false);
        enableToaster("error", "Oops, Something went wrong");
      });
  };

  const savecorrigenda = () => {
    let corrigendaObj = corrigenda.map((item) => {
      return {
        title: item.title,
        oldDocumentTitle: item.oldDocumentTitle,
        newDocumentTitle: item.newDocumentTitle,
        oldDocumentUrl: item.oldDocumentUrl,
        newDocumentUrl: item.newDocumentUrl,
        oldDocumentKey: item.oldDocumentKey,
        newDocumentKey: item.newDocumentKey,
      };
    });

    for (let i = 0; i < corrigendaObj.length; i++) {
      let item = corrigendaObj[i];
      if (!item.title) {
        setErrorKey("title");
        setErrorIndex(i);
        return;
      }
      if (!item.oldDocumentTitle) {
        setErrorKey("oldDocumentTitle");
        setErrorIndex(i);
        return;
      }
      if (!item.newDocumentTitle) {
        setErrorKey("newDocumentTitle");
        setErrorIndex(i);
        return;
      }
      if (!item.oldDocumentUrl) {
        setErrorKey("oldDocumentUrl");
        setErrorIndex(i);
        return;
      }
      if (!item.newDocumentUrl) {
        setErrorKey("newDocumentUrl");
        setErrorIndex(i);
        return;
      }
    }
    setLoader(true);
    TenderAPI.updateTender({
      tenderNumber: tender?.tenderNumber,
      corrigenda: corrigendaObj,
    })
      .then((response) => {
        setLoader(false);
        enableToaster("success", "Saved Successfully");
        // window.location.reload()
      })
      .catch((err) => {
        setLoader(false);
        enableToaster("error", "Oops, Something went wrong");
      });
  };

  const onClickDocumentPreview = (item, documentKey, index, key) => {
    let corrigendaObj = [...corrigenda];
    item.isLoading = true;
    item.loadingKey = key;
    corrigendaObj[index] = item;
    setcorrigenda(corrigendaObj);

    AwsApi.getSignedUrlToView({ url: item.oldDocumentKey })
      .then((response) => {
        AwsApi.getSignedUrlToView({ url: item.newDocumentKey })
          .then((response1) => {
            setPreviewCorrigendaObject({
              ...item,
              oldDocumentPreviewUrl: response.signedUrl,
              newDocumentPreviewUrl: response1.signedUrl,
            });
            item.isLoading = true;
            item.loadingKey = "";
            corrigendaObj[index] = item;
            setcorrigenda(corrigendaObj);
          })
          .catch((error) => {
            item.isLoading = true;
            item.loadingKey = "";
            corrigendaObj[index] = item;
            setcorrigenda(corrigendaObj);
          });
      })
      .catch((error) => {
        item.isLoading = true;
        item.loadingKey = "";
        corrigendaObj[index] = item;
        setcorrigenda(corrigendaObj);
      });
  };

  const onClickActionTender = (type) => {
    buildAppConfirmModal(type);
    setShowConfirm(true);
    setActionType(type);
  };

  const buildAppConfirmModal = (type) => {
    let obj = {};
    if (type == "Cancel") {
      obj.okButtonText = "Yes, Cancel";
      obj.okButtonStyle = "reject-text";
      obj.title = "Do you want to cancel tender ?";
      obj.image = getImageSource(ImageConstants.CLOSE_RED);
    } else if (type == "Suspend") {
      obj.okButtonText = "Yes, Suspend";
      obj.okButtonStyle = "reject-text";
      obj.title = "Do you want to suspend tender ?";
      obj.image = getImageSource(ImageConstants.CLOSE_RED);
    } else if (type == "Publish") {
      obj.okButtonText = "Proceed to Secure Checkout";
      obj.okButtonStyle = "";
      obj.title =
        `A ${tender.createBY == "Custom" ? "$5.45" : "$20"} deposit is applicable for publishing your tender, which is fully refunded to you upon awarding the tender on our platform`;
      obj.image = getImageSource(ImageConstants.TICK_MARK);
    } else if (type == "Make Public") {
      obj.okButtonText = "Proceed to Secure Checkout";
      obj.okButtonStyle = "";
      obj.title = "Do you want to make public ?";
      obj.image = getImageSource(ImageConstants.TICK_MARK);
    }

    setConfirmModalObj(obj);
  };

  const closeConfirmModal = () => {
    setShowConfirm(false);
  };

  const onConfirmAction = () => {
    setShowConfirm(false);

    if (selectedAction == "Publish") {
      handlePublish();
      return;
    }

    let payload = {
      tid: tender?.tenderNumber,
      status:
        selectedAction === "Cancel"
          ? "CANCELLED"
          : selectedAction == "Suspend"
            ? "SUSPENDED"
            : "PUBLISHED",
    };

    setLoader(true);

    TenderAPI.updateTenderStatus(payload)
      .then((response) => {
        setLoader(false);
        setShowConfirm(false);
        getBuyerTenderDetails();
        enableToaster("success", "Updated Successfully");
      })
      .catch((err) => {
        setLoader(false);
        enableToaster("error", "Oops, Something went wrong");
      });
  };

  const handlePublish = () => {
    setLoader(true);
    TenderAPI.publishTender({ tenderNumber: tender.tenderNumber })
      .then((response) => {
        setLoader(false);
        setShowModal(false);
        getBuyerTenderDetails();
        // enableToaster("success", "Published Successfully");
        console.log(response.data.paymentIntent, "code0")
        window.location.href = response.data.paymentIntent;
      })
      .catch((err) => {
        setShowModal(false);
        setLoader(false);
        enableToaster("error", "Oops, Something went wrong");
      });
  };

  const enableToaster = (type, message) => {
    setToasterObj({ type, message });
    setToaster(true);
    setTimeout(() => hideToaster(), 3000);
  };

  const hideToaster = () => {
    setToaster(false);
  };

  const closeChatHandler = () => {
    setShowChat(false);
  };


  const Fileupload =(event)=>{
    let file =event.target.files[0] 
    const formData = new FormData();
    formData.append("file", file);
    setbtndisable(true)

   
     fetch(`${Urls.BASE_URL}${Urls.UPLOADFILE}`, {
       method: 'POST',
       headers:{
         authorization: token,
       },
       body: formData,  
     })
    // TenderAPI.UpladAfile(formData)
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        setbtndisable(false)
           let payloadObj = { ...payload };
           payloadObj.pdfDocumentKey=data.pdfDocumentKey
           payloadObj.file=data.pdfDocumentUrl
           console.log(payloadObj);
           setPayloadObject(payloadObj);
      })
      .catch(error => {
        // alert(error.message);
        console.error('Error:', error);
        return
        // Handle error
      });
}

  const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <button className="obx-date-picker-btn" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  const renderPreviewDocument = (previewUrl) => {
    console.log(previewUrl, "previewUrl12345")
    return (
      <div className="tender-detail text shadow rounded">
        <div className="row m-2 pt-4">
          <div className="col">
            <button onClick={closePreview} className="btn btn-light">
              <i class="bi bi-arrow-left-short"></i>Back
            </button>
          </div>
        </div>
        <hr />
        <iframe className="preview-iframe" src={previewUrl} />
      </div>
    );
  };

  const renderCorrigendaPreviewDocument = () => {
    return (
      <div className="tender-detail text shadow rounded">
        <div className="row m-2 pt-4">
          <div className="col">
            <button onClick={closePreview} className="btn btn-light">
              <i class="bi bi-arrow-left-short"></i>Back
            </button>
          </div>
        </div>
        <hr />

        <div className="p-4">
          <div className="h5 text-center">{corrigendaPreviewObject?.title}</div>
          <div>
            <div className="pt-4">
              <lable>Old Document Title : </lable>{" "}
              <b>{corrigendaPreviewObject?.oldDocumentTitle}</b>
            </div>
            <div className="pt-4">
              <iframe
                className="preview-iframe-1"
                src={corrigendaPreviewObject?.oldDocumentPreviewUrl}
              />
            </div>
          </div>
          <div className="pt-4">
            <div className="pt-4">
              <lable>New Document Title : </lable>{" "}
              <b>{corrigendaPreviewObject?.newDocumentTitle}</b>
            </div>
            <div className="pt-4">
              <iframe
                className="preview-iframe-1"
                src={corrigendaPreviewObject?.newDocumentPreviewUrl}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (showChat) return <Chat tender={tender} back={closeChatHandler} />;

  return (
    <>
      {!isLoading && previewUrl ? (
        renderPreviewDocument()
      ) : !isLoading && corrigendaPreviewObject ? (
        renderCorrigendaPreviewDocument()
      ) : (
        <div className="tender-detail pb-2 text shadow rounded">
          {isLoading ? <Loader /> : ""}
          <div
            className={`status-badge-lg mb-4 ${tender.status.toLowerCase()}`}
          ></div>
          <div className="row m-2">
            <div className="col-3">
              <button onClick={props.back} className="btn btn-light">
                <i class="bi bi-arrow-left-short"></i>Back
              </button>
            </div>
            <div className="col">
              {tender?.status === "DRAFT" ? (
                <button
                  onClick={() => onClickActionTender("Publish")}
                  type="button"
                  className="btn btn-sm btn-primary obx-btn-color float-end mr-2"
                >
                  {" "}
                  <FontAwesomeIcon icon={faGlobe} /> &nbsp;Publish
                </button>
              ) : (
                ""
              )}
              <button
                onClick={() => {
                  setEdit(!isEdit);
                }}
                type="button"
                className="btn btn-sm btn-primary btn-sm-in-w obx-btn-color float-end mr-2"
              >
                {" "}
                {!isEdit ? (
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faEdit} /> &nbsp;Edit
                  </span>
                ) : (
                  "Done"
                )}{" "}
              </button>
              {tender?.documentKey ? (
                <button
                  onClick={() => props.handleDocumentPreview(tender)}
                  type="button"
                  className="btn btn-sm btn-primary obx-btn-color float-end mr-2"
                >
                  {" "}
                  <FontAwesomeIcon icon={faEye} /> View Tender Documents
                </button>
              ) : (
                ""
              )}

              {tender?.pdfDocumentUrlanothetr ? (
                <button
                  onClick={() => props.handleAnotherDocumentPreview(tender?.pdfDocumentUrlanothetr)}
                  type="button"
                  className="btn btn-sm btn-primary obx-btn-color float-end mr-2"
                >
                  {" "}
                  <FontAwesomeIcon icon={faEye} /> View additional Doc
                </button>
              ) : (
                ""
              )}


              {SessionCache.getUserType() === "BUYER" &&
                tender?.status === "PUBLISHED" ? (
                <button
                  onClick={() => setShowChat(true)}
                  type="button"
                  className="btn btn-sm btn-primary obx-btn-color float-end mr-2"
                >
                  {" "}
                  <FontAwesomeIcon icon={faMessage} />
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <hr />
          <div className="position-relative m-4">
            <div>
              <div>
                <span className="h6 obx-ls-t">
                  Facility Details - {tender?.tenderNumber}
                </span>
                <hr />
              </div>
              <div className="">
                <div className="row  obx-ls-st">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-2">
                      <label htmlFor="formControlInput1" className="form-label">
                        <b>Facility Name :</b> {props?.tender?.facilityName}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-2">
                      <label htmlFor="formControlInput1" className="form-label">
                        <b>Facility Type : </b>{" "}
                        {CommonUtils.camelCase(props?.tender?.facilityType)}
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-2">
                      <label htmlFor="formControlInput1" className="form-label">
                        <b> Facility Postal Code : </b>{" "}
                        {props?.tender?.facilityPostalCode}
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-2">
                      <label htmlFor="formControlInput1" className="form-label">
                        <b>Street Address :</b> {props?.tender?.streetAddress}
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-2">
                      <label htmlFor="formControlInput1" className="form-label">
                        <b>Status :</b> {props?.tender?.status}
                      </label>
                    </div>
                  </div>

                  <div className="mb-4"></div>

                  <div className="">
                    <span className="h6 obx-ls-t">Key Dates</span>
                    {isEdit && (
                      <button
                        disabled={
                          !payload.publicationDate ||
                          !payload.submissionDeadlineDate
                        }
                        onClick={saveDates}
                        type="button"
                        className="btn btn-sm btn-primary obx-btn-color position-absolute right"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faSave} /> &nbsp;Save
                      </button>
                    )}
                  </div>
                  <div>
                    <hr />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-1">
                      <label htmlFor="formControlInput1" className="form-label">
                        <b>Publication Date :</b>{" "}
                        {!isEdit ? (
                          <span>
                            {CommonUtils.datePipe(
                              props?.tender?.terms.publicationDate,
                              "dd/MM/yyyy"
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      {isEdit ? (
                        <div>
                          {/* <div className={`date-wrapper ${errorKey == "publicationDate" ? "invalid-input" : ""}`}> */}
                          <DatePicker
                            selected={payload.publicationDate}
                            showTimeSelect
                            minDate={new Date()}
                            ccloseOnScroll={true}
                            timeFormat="HH:mm"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            onChange={(date) =>
                              handlePayloadChange(date, "publicationDate")
                            }
                            customInput={<CustomDatePicker />}
                          />
                          {/* </div> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="">
                      <label htmlFor="formControlInput1" className="form-label">
                        <b>Submission Deadline :</b>{" "}
                        {!isEdit ? (
                          <span>
                            {CommonUtils.datePipe(
                              props?.tender?.terms?.submissionDeadlineDate,
                              "dd/MM/yyyy"
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                      {isEdit ? (
                        <div>
                          {/* <div className={`date-wrapper ${errorKey == "submissionDeadlineDate" ? "invalid-input" : ""}`}> */}
                          <DatePicker
                            selected={payload.submissionDeadlineDate}
                            showTimeSelect
                            minDate={payload.publicationDate}
                            ccloseOnScroll={true}
                            timeFormat="HH:mm"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            onChange={(date) =>
                              handlePayloadChange(
                                date,
                                "submissionDeadlineDate"
                              )
                            }
                            customInput={<CustomDatePicker />}
                          />
                          {/* </div> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4 text">
              <div>
                <span className="h6 obx-ls-t">Site Visit Slots</span>
                {isEdit && (
                      <button
                        disabled={
                          !payload.publicationDate ||
                          !payload.submissionDeadlineDate
                        }
                        onClick={saveVisitDetails}
                        type="button"
                        className="btn btn-sm btn-primary obx-btn-color position-absolute right"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faSave} /> &nbsp;Save
                      </button>
                    )}
                <hr />
              </div>
              <div>
                {
                  !isEdit
                  ? <>
                    {siteDetails?.map((item) => (
                      <div className="row pt-2">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="mb-3">
                            <label
                              htmlFor="formControlInput1"
                              className="form-label"
                            >
                              Location: <b>{item?.location || "Not Specified"}</b>{" "}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                          <div className="mb-3">
                            <label
                              htmlFor="formControlInput1"
                              className="form-label"
                            >
                              Max. Visitor: <b>{item?.maxVisit || "0"}</b>{" "}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12">
                          <div className="mb-3">
                            <label
                              htmlFor="formControlInput1"
                              className="form-label"
                            >
                              Visit Time :{" "}
                              <b>
                                {item?.startDate
                                  ? `${CommonUtils.datePipe(
                                    item?.startDate,
                                    "dd/MM/yyyy hh:mm"
                                  )} - ${CommonUtils.datePipe(
                                    item?.endDate,
                                    "dd/MM/yyyy hh:mm"
                                  )}`
                                  : "Not Specified"}
                              </b>{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                  : <>
                    {siteDetails?.map((item, index) => (
                      <div className="position-relative">
                        {siteDetails?.length >= 1 && item?.startDate ? (
                          <div
                            className='cross-icon'
                            onClick={() => onClickRemove(index)}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="row pt-2">
                          <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="mb-3">
                              <label htmlFor='formControlInput1' className='form-label'>
                                Location
                              </label>
                              <textarea
                                type='text'
                                className={`form-control ${
                                  errorKey == "location" && errorIndex === index
                                    ? "invalid-input"
                                    : ""
                                }`}
                                id='facilityControl'
                                value={item?.location || ""}
                                onChange={(e) =>
                                  handleSiteDetailsChange(
                                    e?.target?.value,
                                    "location",
                                    index
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="mb-3">
                              <label htmlFor='formControlInput1' className='form-label'>
                                Maximum Visitor
                              </label>

                              <input
                                type='number'
                                className={`form-control ${
                                  errorKey == "maxVisit" && errorIndex === index
                                    ? "invalid-input"
                                    : ""
                                }`}
                                id='facilityControl'
                                value={item?.maxVisit || 0}
                                onChange={(e) =>
                                  handleSiteDetailsChange(
                                    e?.target?.value,
                                    "maxVisit",
                                    index
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="mb-3">
                              <label
                                htmlFor="formControlInput1"
                                className="form-label"
                              >
                                Visit Time :{" "}
                              </label>
                              <DateRangePicker
                                onHide={(event, picker) =>
                                  handleDateEvent(event, picker, index)
                                }
                                onCancel={() => {}}
                                autoApply
                                initialSettings={{
                                  singleDatePicker: false,
                                  timePicker: true,
                                  timePicker24Hour: true,
                                  autoUpdateInput: false,
                                }}
                                locale={{
                                  format: "DD/MM/YYYY",
                                }}
                              >
                                <div className='mb-3'>
                                  <input
                                    type='text'
                                    className={`form-control ${
                                      (errorKey == "startDate" ||
                                        errorKey == "endDate") &&
                                      errorIndex === index
                                        ? "invalid-input"
                                        : ""
                                    }`}
                                    placeholder='Visit Time'
                                    value={
                                      item.startDate
                                        ? `${CommonUtils.datePipe(
                                            item?.startDate,
                                            "dd/MM/yyyy, hh:mm"
                                          )} - ${CommonUtils.datePipe(
                                            item?.endDate,
                                            "dd/MM/yyyy, hh:mm"
                                          )}`
                                        : ""
                                    }
                                  />
                                </div>
                              </DateRangePicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className='text-center pt-4'>
                      <button
                        onClick={onClickAddVisitDetails}
                        type='button'
                        className='btn btn-sm btn-success'
                      >
                        + Add
                      </button>
                    </div>
                  </>
                }
              </div>
            </div>
            {tender?.status === "PUBLISHED" ? (
              <div className="pt-4">
                <div className="">
                  <span className="h6 obx-ls-t">Corrigenda</span>
                  {isEdit && (
                    <button
                      onClick={savecorrigenda}
                      type="button"
                      className="btn btn-sm btn-primary obx-btn-color position-absolute right"
                    >
                      {" "}
                      <FontAwesomeIcon icon={faSave} /> &nbsp;Save
                    </button>
                  )}
                </div>
                <div>
                  <hr />
                </div>

                <div className="">
                  {corrigenda?.length ? (
                    corrigenda?.map((item, index) => (
                      <div
                        className="p-4 mt-4 border-wrapper position-relative"
                        key={item.key}
                      >
                        {item.isNew ? (
                          <div
                            className="close-icon"
                            onClick={() => removeCorrigenda(index)}
                          >
                            <FontAwesomeIcon icon={faClose} />
                          </div>
                        ) : (
                          ""
                        )}

                        <div>
                          <label className="pb-1">Title </label>
                          <input
                            disabled={!item.isNew}
                            type="text"
                            className={`form-control  ${errorKey === "title" && errorIndex === index
                                ? "invalid-input"
                                : ""
                              }`}
                            placeholder="Title"
                            id="streetAddressControl"
                            value={item?.title}
                            onChange={(event) =>
                              handleCorigenda(
                                event?.target.value,
                                index,
                                "title"
                              )
                            }
                          />
                        </div>
                        <div className="row pt-4">
                          <div className="col-6">
                            <div>
                              <label className="pb-1">Old Title </label>
                              <input
                                disabled={!item.isNew}
                                type="text"
                                className={`form-control  ${errorKey === "oldDocumentTitle" &&
                                    errorIndex === index
                                    ? "invalid-input"
                                    : ""
                                  }`}
                                placeholder="Old Document Title"
                                id="streetAddressControl"
                                value={item?.oldDocumentTitle}
                                onChange={(event) =>
                                  handleCorigenda(
                                    event?.target.value,
                                    index,
                                    "oldDocumentTitle"
                                  )
                                }
                              />
                            </div>
                            <div className="pt-4">
                              <label className="pb-1">Old Document </label>
                              {item.isNew ? (
                                <input
                                  type="file"
                                  accept=".pdf"
                                  className={`form-control ${errorKey === "oldDocumentUrl" &&
                                      errorIndex === index
                                      ? "invalid-input"
                                      : ""
                                    }`}
                                  onChange={(event) =>
                                    onSelectFile(event, "oldDocument", index)
                                  }
                                />
                              ) : (
                                <div>
                                  <div
                                    className="doument-preview-wrapper text-center mr-2"
                                    onClick={() =>
                                      onClickDocumentPreview(
                                        item,
                                        item.oldDocumentKey,
                                        index,
                                        "oldDocument"
                                      )
                                    }
                                  >
                                    <div className="doument-preview">
                                      <div className="text-center">
                                        {item.isLoading &&
                                          item?.loadingKey === "oldDocument" ? (
                                          <div className="">
                                            <FontAwesomeIcon
                                              className="icon "
                                              spin
                                              icon={faSpinner}
                                            />
                                          </div>
                                        ) : (
                                          <div className="">
                                            <FontAwesomeIcon
                                              className="icon"
                                              icon={faFileAlt}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-6">
                            <div>
                              <label className="pb-1">
                                New Document Title{" "}
                              </label>

                              <input
                                disabled={!item.isNew}
                                type="text"
                                className={`form-control ${errorKey === "newDocumentTitle" &&
                                    errorIndex === index
                                    ? "invalid-input"
                                    : ""
                                  }`}
                                placeholder="New Document Title"
                                id="streetAddressControl"
                                value={item?.newDocumentTitle}
                                onChange={(event) =>
                                  handleCorigenda(
                                    event?.target.value,
                                    index,
                                    "newDocumentTitle"
                                  )
                                }
                              />
                            </div>

                            <div className="pt-4">
                              <label className="pb-1">New Document </label>
                              {item.isNew ? (
                                <input
                                  type="file"
                                  accept=".pdf"
                                  className={`form-control ${errorKey === "newDocumentUrl" &&
                                      errorIndex === index
                                      ? "invalid-input"
                                      : ""
                                    }`}
                                  onChange={(event) =>
                                    onSelectFile(event, "newDocument", index)
                                  }
                                />
                              ) : (
                                <div>
                                  <div
                                    className="doument-preview-wrapper text-center mr-2"
                                    onClick={() =>
                                      onClickDocumentPreview(
                                        item,
                                        item.oldDocumentKey,
                                        index,
                                        "newDocument"
                                      )
                                    }
                                  >
                                    <div className="doument-preview">
                                      <div className="text-center">
                                        {item.isLoading &&
                                          item?.loadingKey === "newDocument" ? (
                                          <div className="">
                                            <FontAwesomeIcon
                                              className="icon "
                                              spin
                                              icon={faSpinner}
                                            />
                                          </div>
                                        ) : (
                                          <div className="">
                                            <FontAwesomeIcon
                                              className="icon"
                                              icon={faFileAlt}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      <NoRecords type="small"></NoRecords>
                    </div>
                  )}
                </div>
                {isEdit && (
                  <div className="text-center pt-4">
                    <button
                      onClick={addcorrigenda}
                      type="button"
                      className="btn btn-sm btn-primary obx-btn-color"
                    >
                      + Add
                    </button>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            {isEdit && (
              <div className="pt-4">
                <div>
                  <span className="h6 obx-ls-t">Upload Additional file</span>
                  <hr />
                      <button
                        // disabled={
                        //   !payload.publicationDate ||
                        //   !payload.submissionDeadlineDate
                        // }
                        disabled={btndisable}
                        onClick={saveDates}
                        type="button"
                        style={{color:"white"}}
                        className="btn btn-sm btn-primaryve obx-btn-color position-absolute right"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faSave} /> &nbsp;Save
                      </button>

                </div>
                <div className="">
                  <label>Upload additional file</label>
                  <input type="file" name="file" accept=".pdf, .doc, .docx"   onChange={
                            Fileupload
                        } />
                </div>
              </div>
            )}

            <div className="pt-4">
              <div>
                <span className="h6 obx-ls-t">Previous Version(s) </span>
                <hr />
              </div>
              <div className="">
                {tender?.version?.length
                  ? tender?.version?.map((item, i) => (
                    <div
                      className="obx-versions "
                      onClick={() => onClickVersion(item)}
                    >
                      <div className="version-card ">
                        <div className="row">
                          <div className="col">
                            {" "}
                            <b>Version No ( {item.versionNo} )</b>{" "}
                          </div>
                          <div className="col right">
                            <span>
                              <b>Created Date : </b>{" "}
                              {new Date(item.createdDate).toLocaleString()}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    // <div className="version-card-wrapper text-center mr-2" onClick={() => onClickVersion(item)}>
                    //     <div className="version-card">
                    //         <div className="text-center">{
                    //             isLoading && selectedVersion?.versionNo === item.versionNo ?
                    //                 <div className=" pt-4">
                    //                     <FontAwesomeIcon className="icon " spin icon={faSpinner} />
                    //                 </div>
                    //                 :
                    //                 <div className=" pt-4">
                    //                     <FontAwesomeIcon className="icon" icon={faFileAlt} />
                    //                 </div>
                    //         }
                    //         </div>
                    //         <div>
                    //             Version ({item.versionNo})
                    //         </div>
                    //     </div>
                    // </div>
                  ))
                  : ""}
              </div>
            </div>
            {tender?.status === "PUBLISHED" ? (
              <div className="pt-4">
                <div>
                  <span className="h6 obx-ls-t">Actions </span>
                  <hr />
                </div>

                <div className="text-right">
                  <button
                    onClick={() => onClickActionTender("Cancel")}
                    type="button"
                    className="btn btn-md btn-danger mr-2"
                  >
                    Cancel Tender
                  </button>
                  <button
                    onClick={() => onClickActionTender("Suspend")}
                    type="button"
                    className="btn btn-md btn-outline-danger"
                  >
                    Suspend Tender
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {tender?.status === "SUSPENDED" ? (
              <div className="pt-4">
                <div>
                  <span className="h6 obx-ls-t">Actions </span>
                  <hr />
                </div>
                <div className="text-right">
                  <button
                    onClick={() => onClickActionTender("Make Public")}
                    type="button"
                    className="btn btn-md btn-outline-success mr-2"
                  >
                    Make Public
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {showConfirm ? (
        <AppConfirm
          img={confirmModalObj?.image}
          okButtonStyle={confirmModalObj?.okButtonStyle}
          title={confirmModalObj?.title}
          close={closeConfirmModal}
          onOk={onConfirmAction}
          cancelButtonText="Cancel"
          okButtonText={confirmModalObj?.okButtonText}
        />
      ) : (
        ""
      )}
      {showToaster ? (
        <ToasterComponent type={toaster?.type} message={toaster?.message} />
      ) : (
        ""
      )}
    </>
  );
};

export default TenderDetail;

import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faArrowLeft,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getImageSource } from "../../../utility/image-source";
import ImageConstants from "../../../constants/image-constants";
import AppConfirm from "../../common/app-confirm/app-confirm";
import TenderAPI from "../../../apis/tender";

const PreviewTender = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const handleEdit = () => {
    props.onEditDocument();
    setEdit(true);
  };

  const handlePublish = () => {
    props.onPublish();
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className='position-relative'>
        <span className='h6'>
          <span>
            {props.showBack ? (
              <span className='cursor-pointer'>
                <FontAwesomeIcon onClick={props.back} icon={faArrowLeft} />{" "}
                &nbsp;{" "}
              </span>
            ) : (
              ""
            )}
            {isEdit ? " Edit Document" : " Preview Document"}{" "}
          </span>
        </span>

        {!isEdit && !props.isTenderPublished ? (
          <button
            onClick={handleEdit}
            type='button'
            className='btn btn-sm btn-primary obx-btn-color float-end mr-2'
          >
            {" "}
            <FontAwesomeIcon icon={faEdit} /> &nbsp;Edit
          </button>
        ) : (
          ""
        )}
        {!props.isTenderPublished ? (
          <button
            onClick={handleShowModal}
            type='button'
            className='btn btn-sm btn-primary obx-btn-color float-end mr-2'
          >
            {" "}
            <FontAwesomeIcon icon={faGlobe} /> &nbsp;Publish
          </button>
        ) : (
          <span className='published-badge  mr-2'>Published</span>
        )}
      </div>
      <hr></hr>
      <div>
        <iframe className='preview-iframe' src={props.previewURL} />
      </div>

      {showModal ? (
        <AppConfirm
          img={getImageSource(ImageConstants.TICK_MARK)}
          okButtonStyle=''
          title='A $20 deposit is applicable for publishing your tender, which is fully refunded to you upon awarding the tender on our platform.'
          close={handleClose}
          onOk={handlePublish}
          cancelButtonText='Cancel'
          okButtonText={"Proceed to Secure Checkout"}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PreviewTender;

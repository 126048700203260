const ROOT = "";
const VENDOR = "/vendor";
const BUYER = "/buyer";
const ADMIN = "/admin"

const Path = {
  BASE: "/",
  VERIFICATION: "/email/verify",
  HOME: "/home",
  DASHBOARD: `${ROOT}${VENDOR}/dashboard`,
  BUYER_DASHBOARD: `${ROOT}${BUYER}/dashboard`,
  BUYER_LIST: ROOT + "/buyer-list",
  LOGIN: "/login",
  REGISTER: ROOT + "/register",
  FORGOT_PASSWORD: ROOT + "/forgot-password",
  OPPORTUNITIES: ROOT + "/opportunities",
  VENDOR_DIRECTORY: ROOT + "/vendor-directory",
  CREATE_TENDER: ROOT + "/create-tender",
  FAQ: ROOT + "/faqs",
  PROFILE: ROOT + "/profile",
  CONTACT: ROOT + "/contact",
  ANNOUNCEMENTS: ROOT + "/announcements",
  BUYERSUCCESS: ROOT + `/checkout/sucess`,
  VENDORSUCCESS: `${ROOT}${VENDOR}/sucess`,
  BUYERTRANSACTION: ROOT + `/buyer/transactions`,
  VENDORTRANSACTION: `${ROOT}${VENDOR}/transactions`,
  UploadTENDER:ROOT + "/upload-tender",
  TERMS: ROOT + "/Terms",
  PRIVACY: ROOT + "/Privacy",

  ADMIN_LOGIN: `${ROOT}${ADMIN}/login`,
  ADM_DASHBOARD: `${ROOT}${ADMIN}/dashboard`,
  ADM_BUYER_SETTING: `${ROOT}${ADMIN}${BUYER}`,
  ADM_VENDOR_SETTING: `${ROOT}${ADMIN}${VENDOR}`
};

export default Path;

const SecurityServices = [
  require("../assets/SecurityTypes/Concierge.png"),
  require("../assets/SecurityTypes/MannedGuarding.png"),
  require("../assets/SecurityTypes/MobilePatrols.png"),
  require("../assets/SecurityTypes/Surveillance.png"),
  require("../assets/SecurityTypes/AccessControlSystem.png"),
  require("../assets/SecurityTypes/Access Control - Vehicle.png"),
  require("../assets/SecurityTypes/Facility Management.png"),
];

export default SecurityServices;

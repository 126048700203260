import React, { useEffect, useState } from "react";
import "./kpi.scss";
import Table from 'react-bootstrap/Table';
import KPIApi from "../../../../apis/kpi";
import ModifyKPI from "./modify-kpi";
import Loader from '../../../common/loading/loading'
import AppConfirm from '../../../common/app-confirm/app-confirm'
import { getImageSource } from "../../../../utility/image-source";
import ImageConstants from "../../../../constants/image-constants";

function KpiList(props) {

    let [kpis, setKpis] = useState([])
    let [isCreateKpi, setCreateKpi] = useState(false)
    let [selectedKpi, setSelectedKpi] = useState(null)
    let [mode, setMode] = useState("")
    const [isLoading, setLoader] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    useEffect(() => {
        getKpis()
    }, [])

    const getKpis = () => {
        setLoader(true)
        KPIApi.getUserKpis({}).then((response) => {
            setKpis(response || [])
            setLoader(false)

        }).catch((err) => {
            setLoader(false)
        })
    }

    const onClickKpi = (item) => {
        setCreateKpi(true)
        setMode(item ? "EDIT" : "")
        setSelectedKpi(item)
    }

    const handleBack = (forceFetch) => {
        setCreateKpi(false)
        setSelectedKpi(null)
        if (forceFetch) getKpis()
    }

    const onClickDelete = (item) => {
        setShowConfirmModal(true)
        setSelectedKpi(item)
    }

    const closeConfirm = () => {
        setShowConfirmModal(false)
    }

    const confirmDelete = () => {
        setLoader(true)

        KPIApi.deleteApi({ id: selectedKpi?._id }).then((response) => {
            setLoader(false)
            getKpis()
            setSelectedKpi(null)
            closeConfirm()
        }).catch((err) => {
            setLoader(false)
            setSelectedKpi(null)
            closeConfirm()

        })
    }

    if (isCreateKpi) return <ModifyKPI mode={mode} kpi={selectedKpi} back={handleBack} />

    return (
        <div className="container py-4 kpi">
            <div className="container-fluid">
                {isLoading ? <Loader /> : ""}
                <div className="card sshadow-sm obx-blue p-4 mb-4">
                    <div className="row">
                        <div className="col obx-white">
                            <h4>KPI</h4>
                        </div>
                        <div className='col'>
                            <button className='btn btn-light float-end' onClick={() => onClickKpi()}>Create Kpi</button>
                        </div>
                    </div>
                </div>

                <div className='row px-0 m-0'>
                    <div className='col px-0 m-0'>
                        <button onClick={props.back} className='btn btn-light'><i class="bi bi-arrow-left-short"></i>Back</button>
                    </div>
                </div>
                <div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th className={`text-bg-light`}>Title</th>
                                <th className={`text-bg-light`}>Fail Threshold</th>
                                <th className={`text-bg-light`}>Outcome</th>
                                <th className={`text-bg-light`}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                kpis?.length ?
                                    kpis.map(item => (
                                        <tr style={{ height: "60px" }}>
                                            <td className="align-middle">{item?.description}</td>
                                            <td className="align-middle">{item?.failThreshold}</td>
                                            <td className="align-middle">{item?.unit}</td>
                                            <td className="align-middle">{item?.outcomeDescription}</td>
                                            <td className="align-middle text-center">
                                                <span className="text-primary p-2 cursor-pointer" onClick={() => onClickKpi(item)}>View </span>
                                                <span className="text-danger p-2 cursor-pointer" onClick={() => onClickDelete(item)}>Delete </span>
                                            </td>

                                        </tr>
                                    )) :
                                    <tr> <td className="text-center" colSpan="4">No Record's Found </td></tr>
                            }

                        </tbody>
                    </Table>
                </div >
            </div>

            {
                showConfirmModal ?
                    <AppConfirm
                        img={getImageSource(ImageConstants.CLOSE_RED)}
                        okButtonStyle="reject-text"
                        title="Do you want to delete this kpi ?"
                        close={closeConfirm}
                        onOk={confirmDelete}
                        cancelButtonText="No"
                        okButtonText={"Yes, Delete"}
                    /> : ""
            }

        </div >);
}

export default KpiList;

import React, { Component } from 'react';
import './no-records.scss';

class NoRecords extends Component {
    state = {}
    render() {
        return (
            <div className='no-records'>
                {this.props.type == "small" ? <><div className='title-sm'>{this.props.title || "No Records"}</div>
                    <div className='sub-title-sm'>Currenly no records to show!!!</div></> : <><div className='title'>{this.props.title || "No Records"}</div>
                    <div className='sub-title'>Currenly no records to show!!!</div></>}

            </div>);
    }
}

export default NoRecords;
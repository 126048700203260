import './App.scss';
import "bootstrap-icons/font/bootstrap-icons.css";
import { Router, Switch } from "react-router-dom";

import Layout from './components/layout/layout';
import history from './components/routes/history';

function App() {

  return (
    <Router history={history}>
      <Layout />
    </Router>


  );
}
//
export default App;

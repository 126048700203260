import { useEffect, useState } from "react";
import CommonUtils from "../../../utility/common-utilities";
import { getImageSource } from "../../../utility/image-source";
import ImageConstants from "../../../constants/image-constants";
import "../../../styles/fonts.scss";
import "../create-tender-layout.scss";

const KPISelection = (props) => {
  const [payload, setPayload] = useState({});

  useEffect(() => {
    setPayload(props.payload || {});
    console.log(props.kpis);
  }, []);

  const handleServiceClick = (item) => {
    let payloadObj = { ...payload };
    console.log(payloadObj);
    let selectedKpis = payloadObj.selectedKpis || [];
    if (selectedKpis?.find((kpi) => kpi._id === item._id)) {
      selectedKpis = selectedKpis.filter((s) => s._id !== item._id);
    } else {
      selectedKpis.push(item);
    }
    payloadObj.selectedKpis = selectedKpis;
    setPayloadObject(payloadObj);
  };

  const setPayloadObject = (payloadObj) => {
    setPayload(payloadObj);
    props.emitChanges && props.emitChanges(payloadObj, "3");
  };

  const handleNext = () => {
    let validation = CommonUtils.validateCreateTenderPayload(payload, 3);
    if (validation?.error) {
      return;
    }
    props.next();
  };

  return (
    <div>
      <span className="h6 header_title_caption fs-5">KPI Selection</span>
      <hr></hr>
      <div className="card mt-3 tooltip-background-color">
        <div className="card-body p-4">
          <div className="row">
            <div className="col">
              <h6 className="card-title mb-4 header_title_caption fw-bold">
                <i className="me-2 bi bi-info-circle"></i>Performance Metrics
                are configured in 3 stages
              </h6>
            </div>
          </div>

          <div className="row">
            <div className="col-1"></div>
            <div className="col-11 header_title_caption">
              <div>
                <small>
                  <strong>1. [Current] Select a KPI for each outcome</strong>
                </small>
              </div>
              <div>
                <small>2. Set the performance requirements for each KPI</small>
              </div>
              <div>
                <small>
                  3. Set Set Contract Sum Grades based on the total score across
                  all KPIs
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-4 table_style">
        <div className="card-body ">
          <table className="table ">
            <thead>
              <tr className="header_title_caption tables_header_rowborderbottom">
                <th scope="col" className="text-center" colSpan={2}>
                  Outcome
                </th>
                <th scope="col" className="text-center" colSpan={2}>
                  KPI Description
                </th>
                <th scope="col" className="text-center" colSpan={2}>
                  Definition
                </th>
                <th scope="col" className="text-center" colSpan={2}>
                  Calculation
                </th>
                {/* <th scope="col" className="text-center" colSpan={2}>
                  NOTES
                </th> */}
              </tr>
            </thead>

            <tbody className="hover selected-hover">
              {props?.kpis?.length
                ? props?.kpis.map((item, index) => (
                    <tr
                      className={`small kpi_table_datasize ${
                        payload?.selectedKpis?.find(
                          (kpi) => kpi._id === item._id
                        )
                          ? "options-selected-color header_title_caption"
                          : "header_title_caption"
                      }`}
                      // style={{
                      //   background: `${
                      //     index % 2 === 0 ? `#f5ffff` : `#E9FBFA`
                      //   }`,
                      // }}
                      onClick={() => handleServiceClick(item)}
                    >
                      <td className="" colSpan={2}>
                        <div className="d-flex justify-content-start">
                          <input
                            type="checkbox"
                            className=" me-2"
                            checked={
                              payload?.selectedKpis?.find(
                                (kpi) => kpi._id === item._id
                              )
                                ? true
                                : false
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              handleServiceClick(item);
                            }}
                          />
                          <span className="ms-2">
                            {item.outcomeDescription}
                          </span>
                        </div>
                      </td>
                      <td className="align-top fw-bold" colSpan={2}>
                        <div className="d-flex  align-items-center mt-2">
                          <div
                            className={`${
                              item.Recommended ? `` : `all_recommneded_hide`
                            }`}
                          >
                            <img
                              src={getImageSource(
                                ImageConstants.RECOMMENDEDKPI
                              )}
                              style={{ width: "30px" }}
                              alt="recommended"
                              title="Recommended"
                              className="me-2"
                            />
                          </div>

                          <span>{item.description}</span>
                        </div>
                      </td>
                      <td
                        className="align-top"
                        // style={{ borderLeft: "1px solid black" }}
                        colSpan={2}
                      >
                        <span>{item.definition[0].title}</span>
                      </td>
                      <td className="align-top" colSpan={2}>
                        {item.calculation[0].title}
                      </td>
                      {/* <td colSpan={2}>{item.notes[0].title}</td> */}
                      {/* <td className="overflow-hidden">
                        {item.benchmarking?.length
                          ? item.benchmarking?.map((element) => (
                              <div>{element?.title}</div>
                            ))
                          : ""}
                      </td> */}
                      {/* <td className="overflow-hidden">
                        {item.dataCollection?.length
                          ? item.dataCollection?.map((element) => (
                              <div>{element?.title}</div>
                            ))
                          : ""}
                      </td> */}
                      {/* <td className="text-center">{item.failThreshold}</td> */}
                    </tr>
                  ))
                : ""}
            </tbody>
          </table>
        </div>
        <div className="pb-4 px-4">
          <button
            onClick={props.showManageKpi}
            type="button"
            className="btn btn-md btn-primary obx-btn-color"
          >
            Create KPI
          </button>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <button
            onClick={props.back}
            type="button"
            className="btn btn-md btn-light float-left"
          >
            Back
          </button>
        </div>
        <div className="col">
          <button
            onClick={handleNext}
            type="button"
            className="btn btn-md btn-primary obx-btn-color float-end"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default KPISelection;

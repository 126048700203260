import React, { useState } from "react";
import AuthenticateAPI from "../../apis/authenticate";
import CommonUtils from "../../utility/common-utilities";
import Loader from "../common/loading/loading";
import ToasterComponent from "../common/toaster/toaster";
import "../../styles/fonts.scss";

const ContactUs = (props) => {
  const [isLoading, setLoader] = useState(false);
  const [showToaster, setToaster] = useState(false);
  const [toaster, setToasterObj] = useState({});

  const [errorKey, setErrorKey] = useState("");
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    message: "",
  });

  const handlePayloadChange = (value, key) => {
    let payloadObj = { ...payload };
    payloadObj[key] = value;
    setPayload(payloadObj);
    setErrorKey("");
  };

  const onClickSubmit = () => {
    let validation = validatePayload(payload);

    if (!validation) return;
    setLoader(true);

    AuthenticateAPI.postContactMessage(payload)
      .then((response) => {
        setLoader(false);
        setPayload({
          name: "",
          email: "",
          companyName: "",
          phoneNumber: "",
          message: "",
        });

        enableToaster("success", "Request Submitted");
      })
      .catch((err) => {
        enableToaster("error", "Oops, Something went wrong");
        setLoader(false);
      });
  };

  const validatePayload = (payload) => {
    if (!payload.name || !payload.name.trim()) {
      setErrorKey("name");
      return false;
    }

    if (
      !payload.email ||
      !payload.email.trim() ||
      !CommonUtils.validateEmail(payload.email)
    ) {
      setErrorKey("email");
      return false;
    }

    if (!payload.companyName || !payload.companyName.trim()) {
      setErrorKey("companyName");
      return false;
    }

    if (
      !payload.phoneNumber ||
      !payload.phoneNumber.trim() ||
      !CommonUtils.validateMobileNumber(payload.phoneNumber)
    ) {
      setErrorKey("phoneNumber");
      return false;
    }

    if (!payload.message || !payload.message.trim()) {
      setErrorKey("message");
      return false;
    }
    setErrorKey("");
    return true;
  };

  const enableToaster = (type, message) => {
    setToasterObj({ type, message });
    setToaster(true);
    setTimeout(() => hideToaster(), 3000);
  };

  const hideToaster = () => {
    setToaster(false);
  };

  return (
    <div className='container py-4'>
      <div className='container-fluid'>
        {isLoading ? <Loader /> : ""}
        <div className='card sshadow-sm obx-blue p-4 mb-4'>
          <div className='row'>
            <div className='col obx-white header_title_head'>
              <h4>Contact Us</h4>
            </div>
            <div className='col'></div>
          </div>
        </div>
       <div className="card p-4 obx-full-container header_title_paragraph">
          <div className="row">
            <div className="col">
              <small>Have any queries, feedback or issues to report?</small>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <small>
                Send us an email at{" "}
                <a href="mailto:admin@obx.sg">admin@obx.sg</a>.
              </small>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <small>Or you can reach us using the contact form below.</small>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <small>We will get back to you within 3 working days.</small>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 col-lg-6 col-sm-12'>
              <div className='form-group mb-3'>
                <label
                  className='obx-small mb-2 header_title_paragraph'
                  htmlFor='name'
                >
                  Name
                </label>
                <input
                  type='text'
                  className={`obx-small form-control ${
                    errorKey === "name" ? "invalid-input" : ""
                  }`}
                  id='name'
                  placeholder='Enter Name'
                  name='name'
                  value={payload.name}
                  onChange={(event) =>
                    handlePayloadChange(event.target.value, "name")
                  }
                  required
                />
              </div>
            </div>
            <div className='col-md-6 col-lg-6 col-sm-12'>
              <div className='form-group mb-3'>
                <label
                  className='obx-small mb-2 header_title_paragraph'
                  htmlFor='email'
                >
                  Email
                </label>
                <input
                  type='email'
                  className={`obx-small form-control ${
                    errorKey === "email" ? "invalid-input" : ""
                  }`}
                  id='email'
                  placeholder='Enter email'
                  name='email'
                  value={payload.email}
                  onChange={(event) =>
                    handlePayloadChange(event.target.value, "email")
                  }
                  required
                />
                {errorKey === "email" ? (
                  <span style={{ color: "red" }}>Email is Required</span>
                ) : null}
              </div>
            </div>

            <div className='col-md-6 col-lg-6 col-sm-12'>
              <div className='form-group mb-3'>
                <label
                  className='obx-small mb-2 header_title_paragraph'
                  htmlFor='email'
                >
                  Company Name
                </label>
                <input
                  type='text'
                  className={`obx-small form-control ${
                    errorKey === "companyName" ? "invalid-input" : ""
                  }`}
                  id='companyName'
                  placeholder='Enter Company Name'
                  name='companyName'
                  value={payload.companyName}
                  onChange={(event) =>
                    handlePayloadChange(event.target.value, "companyName")
                  }
                  required
                />
              </div>
            </div>
            <div className='col-md-6 col-lg-6 col-sm-12'>
              <div className='form-group mb-3'>
                <label
                  className='obx-small mb-2 header_title_paragraph'
                  htmlFor='email'
                >
                  Phone Number
                </label>
                <input
                  type='text'
                  className={`obx-small form-control ${
                    errorKey === "phoneNumber" ? "invalid-input" : ""
                  }`}
                  id='phoneNumber'
                  placeholder='Enter Phone Number'
                  name='phoneNumber'
                  value={payload.phoneNumber}
                  onChange={(event) =>
                    handlePayloadChange(event.target.value, "phoneNumber")
                  }
                  required
                />
                {errorKey === "phoneNumber" ? (
                  <span style={{ color: "red" }}>PhoneNumber is Required</span>
                ) : null}
              </div>
            </div>
            <div className='col-12'>
              <div className='form-group mb-3'>
                <label
                  className='obx-small mb-2 header_title_paragraph'
                  htmlFor='email'
                >
                  Message
                </label>
                <textarea
                  rows='5'
                  type='textarea'
                  className={`obx-small form-control ${
                    errorKey === "message" ? "invalid-input" : ""
                  }`}
                  id='message'
                  placeholder='Message'
                  name='message'
                  value={payload.message}
                  onChange={(event) =>
                    handlePayloadChange(event.target.value, "message")
                  }
                  required
                />
              </div>
            </div>
          </div>

          <div className='text-center pt-4'>
            <button
              onClick={onClickSubmit}
              type='button'
              className='btn btn-md btn-primary mr-2'
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {showToaster ? (
        <ToasterComponent type={toaster?.type} message={toaster?.message} />
      ) : (
        ""
      )}
    </div>
  );
};

export default ContactUs;

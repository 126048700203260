import HttpClient from './http-client';
import URL from './url';

export default class KPIApi {


    static getUserKpis(payload) {
        let params = HttpClient.getParams(payload)
        let paramsList = params.length ? URL.USER_KPI + "?" + params.join('&') : URL.USER_KPI;
        return HttpClient.get(URL.BASE_URL + paramsList, payload);
    }

    static createKpi(payload) {
        return HttpClient.post(URL.BASE_URL + URL.USER_KPI, null, payload);
    }

    static updateKpi(payload) {
        return HttpClient.put(URL.BASE_URL + URL.USER_KPI, null, payload);
    }

    static deleteApi(payload) {
        let params = HttpClient.getParams(payload)
        let paramsList = params.length ? URL.USER_KPI + "?" + params.join('&') : URL.USER_KPI;
        return HttpClient.delete(URL.BASE_URL + paramsList, null, payload);
    }
}

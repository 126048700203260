import "./tender-card.scss";
import ImageConstants from "../../../constants/image-constants";
import { getImageSource } from "../../../utility/image-source";

const TenderItem = (props) => {
  let tender = props.tender;
  let services = props.tender?.selectedServices || [];
 

  const handleItemClick = (event) => {
    props.delegateOnClick(props.tender);
    console.log("handle event click - row clicked!!!");
  };

   console.log(tender,"123456789")

  function camelCase(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index != 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  return (
    <div
      className="card titem mb-3 hover shadow"
      onClick={() => handleItemClick()}
    >
      <div className={`tender-status-badge ${tender.status.toLowerCase()} `}>
        <span className={tender.status.toLowerCase()}>
          {camelCase(tender.status.toLowerCase())}
        </span>
      </div>
      {/* <div className={`tender-status-badge ${tender.status.toLowerCase()}`}><span className={tender.status.toLowerCase()}>{camelCase(tender.status.toLowerCase())}{tender.status.toLowerCase() == "published"? <img className="published-icon" src={getImageSource(ImageConstants.CLOCK)}></img>:""} </span></div> */}
      <div className="card-body ">
        <div className="row">
          <div className="col-8 mb-1 text ">
            {/* <div className="pr-2 d-inline-block text"><span className="obx-text-dgray">Status: </span><b>{tender.status}</b> </div> */}
            <div className="pr-2 tex card_title_head">
              <span></span>
              <b>
                {tender.facilityName} ({tender.tenderNumber})
              </b>
            </div>
            <div className="pr-2 tex card_title_subhead">
              <span className="text"></span>
              {camelCase(tender.facilityType)}
            </div>
          </div>
          <div className="col-4 text-end text card_title_content">
            <span className="me-2 badge text-bg-light obx-text-dgray ">
              Published Date:{" "}
              {new Date(tender.terms?.publicationDate).toLocaleDateString()}
            </span>
            <span className="me-2 badge text-bg-light obx-text-dgray ">
              Bid Before:{" "}
              {new Date(
                tender?.terms?.submissionDeadlineDate
              ).toLocaleDateString()}
            </span>
          </div>
        </div>
        <div className="row pt-2">
          <hr />
          <div className="col-lg-2 col-xl-2">
            <span className="obx-text-dgray text card_title_subhead">
              <b>Services</b>
            </span>
          </div>
          <div className="col-lg-6 col-xl-6 text header_title_caption">
            {services.map((service) => (
              <span className="me-2 badge text-bg-warning obx-text-dgray text">
                {service?.title}
              </span>
            ))}
          </div>
          {tender?.submissionCount ? (
            <div className="d-flex justify-content-center">
              <div className="submission-count px-4 ">
                Total Submission: {tender?.submissionCount}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <p style={{textAlign:"right"}}>{tender.createBY==="Custom"?"User Customed Tender":null}</p>
      </div>
    </div>
  );
};

export default TenderItem;

import React, { useState } from "react";
import "./site.scss";
import Accordion from "react-bootstrap/Accordion";
import { getImageSource } from "../../utility/image-source";
import ImageConstants from "../../constants/image-constants";

function SitePage(props) {
  return (
    <div className="site">
      <div className="container-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 my-auto">
              <div className="d-flex justify-content-center align-items-center">
                <div className="text-white">
                  <div className="h1">
                    <b>Welcome to OBX </b>
                  </div>
                  <div>
                    {" "}
                    A platform that assist the creation of an Outcome Based
                    Contract (Tender) and also a marketplace to list your
                    Tenders for interested agencies to pitch and bid for your
                    tender
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="my-3">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="banner-img"
                    alt=""
                    src={getImageSource(ImageConstants.BANNER)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="my-3">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="banner-img"
                    alt=""
                    src={getImageSource(ImageConstants.OBX_CONTRACT)}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 my-auto">
              <div className="d-flex justify-content-center align-items-center">
                <div className="">
                  <div className="h1">
                    <b>What is Outcome-based contracting</b>
                  </div>
                  <div>
                    An outcome-based contract is a type of agreement in which
                    the terms of the contract are structured around achieving a
                    specific result or outcome, rather than specifying the exact
                    steps that must be taken to complete the work. In other
                    words, the focus of an outcome-based contract is on what
                    needs to be accomplished...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 my-auto">
              <div className="d-flex justify-content-center align-items-center">
                <div className="">
                  <div className="h1">
                    <b>What is OBX?</b>
                  </div>
                  <div>
                    OBX is a platform that promotes the use of outcome-based
                    contracting in the security industry. Our OBT generator will
                    create a customized outcome-based tender for security
                    buyers, which will allow vendors to propose more competitive
                    and bespoke solutions that provide significant benefits over
                    traditional headcount-based security contracts.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="my-3">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="banner-img"
                    alt=""
                    src={getImageSource(ImageConstants.OBX_INTRO)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container py-4">
          <div className="h1">
            <b>Platform Benefits</b>
          </div>
          <div className="">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 pt-4">
                <Accordion>
                  <Accordion.Item eventKey={"1"}>
                    <Accordion.Header>
                      <div className="d-flex">
                        <div className="p-2">
                          {" "}
                          <img
                            className="icon-20"
                            src={getImageSource(ImageConstants.TIME)}
                          />
                        </div>
                        <div className="p-2">Time and Cost Saving</div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="p-2">
                        Our OBT generator removes the need for manual contract
                        drafting and negotiation, allowing buyers to put out
                        complex and customized tenders that match their exact
                        requirements.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pt-4">
                <Accordion>
                  <Accordion.Item eventKey={"2"}>
                    <Accordion.Header>
                      <div className="d-flex">
                        <div className="p-2">
                          {" "}
                          <img
                            className="icon-20"
                            src={getImageSource(ImageConstants.INCENTIVE)}
                          />
                        </div>
                        <div className="p-2">Incentives</div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="p-2">
                        Outcome-based security contracts can create stronger
                        incentives for sellers to deliver high-quality security
                        solutions that achieve the desired outcomes. By linking
                        payment to the achievement of outcomes, the platform can
                        create a strong incentive for sellers to focus on
                        results and outcomes rather than just the delivery of
                        services.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 pt-4">
                <Accordion>
                  <Accordion.Item eventKey={"2"}>
                    <Accordion.Header>
                      <div className="d-flex">
                        <div className="p-2">
                          {" "}
                          <img
                            className="icon-20"
                            src={getImageSource(ImageConstants.TRANSPARANCY)}
                          />
                        </div>
                        <div className="p-2">Transparancy</div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="p-2">
                        An outcome-based security contract generated through a
                        platform can offer increased transparency, as it clearly
                        defines the desired outcomes and the metrics by which
                        success will be measured. This can help to prevent
                        misunderstandings and ensure that both parties
                        understand their obligations.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 pt-4">
                <Accordion>
                  <Accordion.Item eventKey={"2"}>
                    <Accordion.Header>
                      <div className="d-flex">
                        <div className="p-2">
                          {" "}
                          <img
                            className="icon-20"
                            src={getImageSource(ImageConstants.SECURITY)}
                          />
                        </div>
                        <div className="p-2">Improved Security</div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="p-2">
                        By defining specific outcomes and performance metrics,
                        outcome-based security contracts can help to ensure that
                        the security solutions provided are effective and
                        focused on the specific needs of the buyer. This can
                        help to improve the overall security operations at the
                        organization or property.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
            <div className="">
                <div className="container">
                    <div className="py-4">
                        <div className="h1 text-center"><b>Our Key Milestones</b></div>

                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 pt-4 my-auto">
                                <div className="text-center">
                                    <div> <img className="icon-40" alt="" src={getImageSource(ImageConstants.NOTEPAD)} /> </div>
                                    <div className="pt-2 sub-header-text">
                                        <div>9.8M</div>
                                        <div>Contact value</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 pt-4 my-auto">
                                <div className="text-center">
                                    <div> <img className="icon-40" alt="" src={getImageSource(ImageConstants.NOTEPAD)} /> </div>
                                    <div className="pt-2 sub-header-text">
                                        <div>600+</div>
                                        <div>Estates</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 pt-4 my-auto">
                                <div className="text-center">
                                    <div> <img className="icon-40" alt="" src={getImageSource(ImageConstants.NOTEPAD)} /> </div>
                                    <div className="pt-2 sub-header-text">
                                        <div>2000+</div>
                                        <div>Tenders</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-6 pt-4 my-auto">
                                <div className="text-center">
                                    <div> <img className="icon-40" alt="" src={getImageSource(ImageConstants.NOTEPAD)} /> </div>
                                    <div className="pt-2 sub-header-text">
                                        <div>4000+</div>
                                        <div>Vendors</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
}

export default SitePage;

import { useEffect, useState } from "react";
import TenderCard from "../../cards/tender-card/tender-card";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import CommonUtils from "../../../utility/common-utilities";
import TenderDetails from "../tender-detail/tender-detail";
import "./opportunities.scss";
import Path from "../../routes/routes-path";
import TenderAPI from "../../../apis/tender";
import Pagination from "../../common/pagination/pagination";
import AuthenticateAPI from "../../../apis/authenticate";
import SessionCache from "../../routes/session-cache";
import NoRecords from "../../no-records/no-records";



const Opportunities = (props) => {
  const [dueDate, setDueDate] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [tenderId, setTenderId] = useState("");
  const [budget, setBudget] = useState("");
  const [isViewTender, setViewTender] = useState(false);
  const [selectedTender, setSelectedTender] = useState(null);
  const [tenders, setTenders] = useState([]);
  const [pageObject, setPageObject] = useState({});
  const [selectedServiceTags, setSelectedServiceTags] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isAuthenticated, setAutheticated] = useState(false);

  useEffect(() => {
    let authenticate = SessionCache.isAuthenticated();
    if (authenticate) {
      setAutheticated(authenticate);
      getOppertunities();
    } else isLoggedin();
  }, [isAuthenticated]);

  useEffect(() => {
    services();
  }, []);

  const isLoggedin = () => {
    AuthenticateAPI.isLogin({})
      .then((response) => {
        let authenticate = false;
        if (response) {
          SessionCache.setUser(response);
          authenticate = SessionCache.isAuthenticated();
          setAutheticated(authenticate);
        }
        getOppertunities();
      })
      .catch((err) => {
        getOppertunities();
      });
  };
  const onSearch = (filter) => {
    console.log(filter);
  };

  const handleDateEvent = (event, picker) => {
    setDueDate(CommonUtils.datePipe(picker.startDate.toDate(), "dd/MM/yyyy"));
  };

  const handlePublishDateEvent = (event, picker) => {
    setPublishedDate(
      CommonUtils.datePipe(picker.startDate.toDate(), "dd/MM/yyyy")
    );
  };

  const handleTenderIdChange = (event) => {
    setTenderId(event.target.value);
  };

  const handleBudgetChange = (event) => {
    setBudget(event.target.value);
  };

  const handleResetFilter = () => {
    setDueDate("");
    setPublishedDate("");
    setTenderId("");
    setBudget("");
    getOppertunities();
    services();
    window.location.reload();
  };

  const onApplyFilter = () => {
    getOppertunities({ page: 1 });
  };

  const onViewTender = (tender) => {
    setViewTender(true);
    setSelectedTender(tender);
  };

  const onClickLogin = () => {
    props.history.push(Path.LOGIN);
  };

  const filteringdata = (event) => {
    if (event.target.checked) {
      const updatedServiceTags = selectedServiceTags.map((tag) =>
        tag.title === event.target.value
          ? { ...tag, checked: event.target.checked }
          : tag
      );
      setSelectedServiceTags(updatedServiceTags);
      let filterdata;
      filterdata = updatedServiceTags.filter((tag) => tag.checked);
      filterdata = filterdata.map((tag) => tag.title);
      let payloadObj = {
        searchdata: filterdata,
      };
      TenderAPI.getFilterIttTenderCategory(payloadObj)
        .then((response) => {
          setTenders(response.data.buyerTenders.data);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      const updatedServiceTags = selectedServiceTags.map((tag) =>
        tag.title === event.target.value
          ? { ...tag, checked: event.target.checked }
          : tag
      );
      setSelectedServiceTags(updatedServiceTags);
      let filterdata;
      filterdata = updatedServiceTags.filter((tag) => tag.checked);
      filterdata = filterdata.map((tag) => tag.title);
      let payloadObj = {
        searchdata: filterdata,
      };
      TenderAPI.getFilterIttTenderCategory(payloadObj)
        .then((response) => {
          setTenders(response.data.buyerTenders.data);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const services = () => {
    TenderAPI.getServices()
      .then((response) => {
        const tagsdata = response.data.map((tags) => ({
          title: tags.title,
          checked: false,
        }));

        setSelectedServiceTags(tagsdata);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOppertunities = (payload = {}) => {
    if (dueDate) payload.dd = dueDate;
    if (publishedDate) payload.pd = publishedDate;
    if (tenderId) payload.tid = tenderId;
    if (budget) payload.budget = budget;

    if (!payload.page) payload.page = pageObject?.currentPage || 1;
    setLoading(true);

    if (SessionCache.isAuthenticated()) {
      payload.status = "PUBLISHED";
      TenderAPI.getIttTenderFilterByStatus(payload)
        .then((response) => {
          let data = response.data;
          setPageObject(response?.pagination || {});
          setTenders(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      TenderAPI.getTenders(payload)
        .then((response) => {
          let data = response.data;
          setPageObject(response?.pagination || {});
          setTenders(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const onPageSelected = (selectedPage, startIndex) => {
    pageObject.currentPage = selectedPage;
    pageObject.startIndex = startIndex;
    setPageObject(pageObject);

    getOppertunities({ page: selectedPage });
  };

  const handleBack = () => {
    setViewTender(false);
  };

  const onClickBookmark = (tender, index) => {
    tender.isBookmark = !tender.isBookmark;
    let tenderArr = [...tenders];
    tenderArr[index] = tender;
    setTenders(tenderArr);

    if (!tender.isBookmark)
      TenderAPI.removeBookmark({ tid: tender.tenderNumber });
    else TenderAPI.addBookmark({ tenderNumber: tender.tenderNumber });
  };

  return (
    <>
      <div className='container opportunities'>
        <div className='container-fluid py-4'>
          <div className='card shadow-sm obx-blue p-4 mb-4'>
            <div className='row'>
              <div className='col obx-white header_title_head'>
                <h4>Opportunities</h4>
              </div>
            </div>
          </div>
          {isViewTender ? (
            <TenderDetails
              tender={selectedTender}
              onClickLogin={onClickLogin}
              back={handleBack}
            />
          ) : (
            <div className='row'>
              <div className='col-lg-3'>
                <div className='shadow rounded p-3 '>
                  <div className='row'>
                    <div className='col'>
                      <span className='h6'>Search Filter</span>
                    </div>
                    <div className='col'>
                      <button
                        onClick={handleResetFilter}
                        type='button'
                        className='btn btn-sm btn-light float-end'
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  <hr></hr>
                  {selectedServiceTags.length > 0
                    ? selectedServiceTags.map((tenderdata) => {
                        return (
                          <>
                            <div
                              className='checkbox_wrap'
                              style={{
                                display: "flex",
                                columnGap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type='checkbox'
                                value={tenderdata.title}
                                onChange={filteringdata}
                                // checked={tenders.includes(
                                //   tenderdata.selectedServices[0].title
                                // )}
                                id={`checkbox-${tenderdata.title}`}
                                className='myproject--checkbox'
                                checked={tenderdata.checked}
                              />
                              <label
                                htmlFor={`checkbox-${tenderdata.title}`}
                                style={{
                                  margin: "0",
                                  textTransform: "capitalize",
                                  fontSize: "1rem",
                                  color: "#414141",
                                  fontWeight: "400",
                                  lineHeight: "28px",
                                }}
                              >
                                {tenderdata.title}
                              </label>
                            </div>
                          </>
                        );
                      })
                    : null}
                  <hr></hr>
                  <div className='form-floating mb-3'>
                    <input
                      type='text'
                      className='form-control'
                      id='floatingInput'
                      placeholder='Tender Id'
                      onChange={handleTenderIdChange}
                      value={tenderId}
                    ></input>
                    <label htmlFor='floatingInput'>Tender ID</label>
                  </div>
                  {/* <div className="form-floating mb-3">
                      <input type="number" className="form-control" id="floatingInput1" placeholder="budget" onChange={handleBudgetChange} value={budget}></input>
                      <label htmlFor="floatingInput1">Budget</label>
                    </div> */}
                  <DateRangePicker
                    onHide={handleDateEvent}
                    onCancel={() => {}}
                    autoApply
                    maxDate={new Date().toLocaleDateString()}
                    initialSettings={{
                      singleDatePicker: true,
                      autoUpdateInput: false,
                    }}
                    locale={{
                      format: "DD/MM/YYYY",
                    }}
                  >
                    <div className='form-floating mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        id='floatingInput3'
                        placeholder='Submission Deadline'
                        value={dueDate}
                      />
                      <label htmlFor='floatingInput3'>
                        Submission Deadline
                      </label>
                    </div>
                  </DateRangePicker>
                  <div>
                    <DateRangePicker
                      onHide={handlePublishDateEvent}
                      onCancel={() => {}}
                      autoApply
                      maxDate={new Date().toLocaleDateString()}
                      initialSettings={{
                        singleDatePicker: true,
                        autoUpdateInput: false,
                      }}
                      locale={{
                        format: "DD/MM/YYYY",
                      }}
                    >
                      <div className='form-floating mb-3'>
                        <input
                          type='text'
                          className='form-control'
                          id='floatingInput4'
                          placeholder='Publish Date'
                          value={publishedDate}
                        />
                        <label htmlFor='floatingInput4'>Publish Date</label>
                      </div>
                    </DateRangePicker>
                  </div>

                  <div>
                    <button
                      onClick={onApplyFilter}
                      className={`w-100 btn btn-block btn-dark align-center vendor-button`}
                    >
                      {`Apply Filter`}
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-lg-9'>
                <div className=''>
                  {tenders?.length ? (
                    tenders.map((tender, index) => (
                      <div className='mb-3'>
                        <TenderCard
                          tender={tender}
                          onView={onViewTender}
                          showBookmark={isAuthenticated}
                          onClickBookmark={(tender) =>
                            onClickBookmark(tender, index)
                          }
                        />
                      </div>
                    ))
                  ) : !isLoading ? (
                    <NoRecords />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {pageObject?.totalPages && !isViewTender ? (
          <Pagination
            startIndex={pageObject?.startIndex || 1}
            selectedPage={pageObject?.currentPage || 1}
            totalPages={pageObject?.totalPages || 0}
            onPageSelected={onPageSelected.bind(this)}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Opportunities;

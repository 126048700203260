import { Container } from "react-bootstrap"
import Header from "./header"
import Footer from "./footer"
import Main from "./main"

const Layout = (props) => {

    return (
        <>
            <Header {...props} />
            <div className="content-container">
                <Main />
            </div>
            <Footer />
        </>
    )
}

export default Layout
import { auto } from "@popperjs/core";
import { useEffect, useState } from "react";
import AdminSettings from "../../../../apis/admin-settings";
import Loading from "../../../common/loading/loading";
import Switch from "react-switch";
import ToasterComponent from "../../../common/toaster/toaster";

const SubscriptionSetting = (props) => {
  const [payload, setPayload] = useState({});
  const [errorKey, setErrorKey] = useState("");
  const [isLoading, setLoader] = useState(false);
  const [enableToaster, setEnableToaster] = useState(false)
  const [toaster, setToaster] = useState({
    type: 'success',
    message: ''
  })

  useEffect(() => {
    getBuyerSetting()
  }, []);

  const handlePayloadChange = (value, key) => {
    let payloadObj = { ...payload };
    payloadObj[key] = value;
    setPayload(payloadObj);
  };

  const getBuyerSetting = () => {
    setLoader(true)
    AdminSettings
      .getBuyerSubscriptionSetting({})
      .then((res) => {
        console.log('buyersetting', res);
        setPayload(res);
        setErrorKey("");
        setLoader(false);
      }).catch((err) => {
        console.log(err)
        // setErrorKey(JSON.parse(err).message);
        setLoader(false);
      })
  }

  const onUpdateSetting = () => {
    setLoader(true);
    AdminSettings
      .updateBuyerSubscriptionSetting(payload)
      .then(data => {
        setLoader(false);
        setEnableToaster(true)
        setToaster({type: 'success', message: 'success update subscription setting'})
      })
      .catch(err => {
        setLoader(false);
        setEnableToaster(true)
        setToaster({type: 'success', message: 'success update subscription setting'})

      })

  }


  return <div>
      {isLoading && <Loading />}
      <div className="shadow rounded p-3 justify-content-center">
        <span className="h6 header_title_caption fs-5">Buyer Setting</span>
        <hr></hr>
        <div className="mb-3">
          <div className="row">
            <label
              htmlFor="formControlInput1"
              className="form-label header_title_caption col-3"
            >
              Is Subscription Active
            </label>
            <div className="col-4">
              <Switch 
              onChange={(checked) =>{
                handlePayloadChange(
                  checked,
                  "is_subscription_active"
                )
              }} 
              checked={payload.is_subscription_active} 
              />
            </div>
          </div>
        </div>
        <div className="mb-3">
          <div className="row">
            <label
              htmlFor="formControlInput1"
              className="form-label header_title_caption col-3"
            >
              Subscription Amount (SGD)
            </label>
            <div className="col-4">
              <input
                placeholder="$"
                type="number"
                className={`form-control custom-input ${
                  errorKey === "subscription_amount"
                    ? "invalid-input"
                    : ""
                }`}
                value={payload.subscription_amount}
                onChange={(event) =>
                  handlePayloadChange(
                    event?.target?.value,
                    "subscription_amount"
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="mb-3">
          <div className="row">
            <label
              htmlFor="formControlInput1"
              className="form-label header_title_caption col-3"
            >
              Subscription Duration (in month)
            </label>
            <div className="col-4">
              <input
                type="number"
                step={1}
                className={`form-control custom-input ${
                  errorKey == "subscription_duration_month" ? "invalid-input" : ""
                }`}
                id="facilityControl"
                value={payload.subscription_duration_month}
                onChange={(event) =>
                  handlePayloadChange(
                    event?.target?.value,
                    "subscription_duration_month"
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col text-center pt-4">
            <button
              onClick={onUpdateSetting}
              type="button"
              className="btn btn-sm btn-primary obx-btn-color float-end"
              style={{paddingLeft:15, paddingRight:15}}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {enableToaster ? <ToasterComponent type={toaster?.type} message={toaster?.message} /> : ""}
    </div>
}

export default SubscriptionSetting;
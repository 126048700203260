import React, { useState, useEffect } from "react";
import TenderDetail from "./tender-detail";
import TenderItem from "../cards/tender-card/tender-item";
import "./tender-list.scss";
import TenderAPI from "../../apis/tender";
import PaginationComponent from "../common/pagination/pagination";
import NoRecords from "../no-records/no-records";
import Loader from "../common/loading/loading";
import ActiveBids from "../vendor/active-bids/active-bids";

const TenderList = (props) => {
  const [showTenderDetail, setShowTenderDetail] = useState(
    props.showTenderDetail
  );
  const [selectedTender, setSelectedTender] = useState(
    props.selectedTender || null
  );
  const [items, setItems] = useState([]);
  const [pageObject, setPageObject] = useState({});
  const [isLoading, setLoader] = useState(false);

  useEffect(() => {
    getBuyerTenders({ page: 1 });
  }, []);

  const getBuyerTenders = (payload = {}) => {
    if (props?.status == "AWARDED") {
      setLoader(true);
      TenderAPI.getIttTenderSubmissionForBuyer(payload)
        .then((response) => {
          setLoader(false);
          setPageObject(response?.pagination || {});
          setItems(response.data);
        })
        .catch((err) => {
          console.log("i am here");
          setLoader(false);
        });
    } else {
      payload.status = props?.status;

      setLoader(true);
      TenderAPI.getBuyerTenders(payload)
        .then((response) => {
          setLoader(false);
          setPageObject(response?.pagination || {});
          setItems(response.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const onPageSelected = (selectedPage, startIndex) => {
    pageObject.currentPage = selectedPage;
    pageObject.startIndex = startIndex;
    setPageObject(pageObject);

    getBuyerTenders({ page: selectedPage });
  };

  const onTenderItemClick = (item) => {
    setShowTenderDetail(true);
    setSelectedTender(item);
  };

  const onBack = () => {
    setSelectedTender(null);
    setShowTenderDetail(false);
    getBuyerTenders({ page: pageObject?.currentPage || 1 });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {!showTenderDetail ? (
            <div>
              <div className="hover">
                {items?.length ? (
                  items.map((item) => {
                    return (
                      <TenderItem
                        key={item.id}
                        delegateOnClick={onTenderItemClick}
                        tender={item}
                      />
                    );
                  })
                ) : (
                  <div className="text-center twenty-per">
                    <NoRecords type="large"></NoRecords>
                  </div>
                )}
              </div>

              <PaginationComponent
                startIndex={pageObject?.startIndex || 1}
                selectedPage={pageObject?.currentPage || 1}
                totalPages={pageObject?.totalPages || 0}
                onPageSelected={onPageSelected.bind(this)}
              />
            </div>
          ) : props.status != "AWARDED" ? (
            <TenderDetail
              tender={selectedTender}
              back={onBack}
              handleDocumentPreview={props.handleDocumentPreview}
              handleAnotherDocumentPreview={props.handleAnotherDocumentPreview}
            ></TenderDetail>
          ) : (
            <ActiveBids
              showBack
              back={onBack}
              tender={selectedTender}
              isAward={true}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TenderList;

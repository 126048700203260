import React, { useState } from "react";
import ToasterComponent from '../../common/toaster/toaster'
import "./forgot-password.scss";

import TitleHeader from "../../common/title-header/title-header";
import AuthenticateAPI from "../../../apis/authenticate";
import Path from "../../routes/routes-path";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faKey } from '@fortawesome/free-solid-svg-icons';
import { getImageSource } from "../../../utility/image-source";
import ImageConstants from "../../../constants/image-constants";


function ForgotPassword(props) {

    const [email, setEmail] = useState("")
    const [submit, setSubmit] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOTP] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [time, setTimer] = useState(0);

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showToaster, setToaster] = useState(false);
    const [toaster, setToasterObj] = useState({});

    let interval = ""


    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleOtpChange = (event) => {
        setOTP(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleForgotPassword = (event) => {
        event.preventDefault();

        if (submit) return


        if (!otpSent) {
            sendOtp()
        } else {
            verifyOtp()
        }

    };


    const sendOtp = () => {
        let payload = {
            email,
        };
        setSubmit(true);

        AuthenticateAPI.forgortPassword(payload).then((response) => {
            setOtpSent(true)
            setSubmit(false)
            startTimer()
            enableToaster("success", "OTP sent")

        }).catch((err) => {
            setSubmit(false)
            enableToaster("error", "User not register with us")

        })
    }

    const verifyOtp = () => {
        if (password !== confirmPassword) {
            setPasswordError(true)
            return ""
        } else {
            setPasswordError(false)
        }
        let payload = {
            email,
            otp,
            password
        };
        setSubmit(true);
        AuthenticateAPI.validateForgortPassword(payload).then((response) => {
            setSubmit(false)
            enableToaster("success", "Success")
            setTimeout(() => {
                props.history.push(Path.LOGIN)
            }, 3000)

        }).catch((err) => {
            setSubmit(false)
            enableToaster("error", "Something went wrong")
        })
    }

    const onClickEdit = () => {
        setOtpSent(false)
        setOTP("")
        setPassword("")
        setConfirmPassword("")
    }

    const startTimer = () => {
        if (interval) {
            stopTimer()
        }

        let seconds = 60
        interval = setInterval(() => {

            seconds -= 1
            setTimer(seconds)

            if (seconds === 0) stopTimer()
        }, 1000)
    }

    const stopTimer = () => {
        clearInterval(interval)
    }

    const handleRegerateOtp = () => {
        if (time <= 0) {
            sendOtp()
        }
    }

    const enableToaster = (type, message) => {

        setToasterObj({ type, message, })
        setToaster(true)
        setTimeout(() => hideToaster(), 3000)
    }

    const hideToaster = () => {
        setToaster(false)
    }

    return (
        <>
            {/* <TitleHeader title="FORGOT PASSWORD" /> */}
            <div className="row forgot-password LoginForm justify-content-center  py-5 ">

                <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6 hide-mobile-view">
                    <div className="d-flex justify-content-center">
                        <img className="login-image" src={getImageSource(ImageConstants.FORGOT_PASSWORD)} alt="" />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                    <div className="d-flex justify-content-center">
                        <div className="position-relative">
                            <div className="rounded p-5 shadow-lg login-card ">
                                <div className="icon-wrapper rounded shadow-lg"><FontAwesomeIcon icon={faKey} /></div>

                                <h2 className="text-center pt-4">Forgot Password</h2>

                                <form className={`mt-4 `} onSubmit={handleForgotPassword} >
                                    <div className="form-floating">
                                        <input disabled={otpSent} type="email" className="obx-small form-control " id="email" placeholder="Enter email" name="email" value={email} onChange={handleEmailChange} required />
                                        <label className="obx-small" htmlFor="email">Email *</label>
                                        {
                                            otpSent ?
                                                <span className="email-edit cursor-pointer" onClick={onClickEdit}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </span> : ""
                                        }
                                    </div>

                                    {
                                        otpSent ?
                                            <>
                                                <div className="form-floating mt-4">
                                                    <input type="otp" className="obx-small form-control " id="otp" placeholder="Enter otp" name="otp" value={otp} onChange={handleOtpChange} required />
                                                    <label className="obx-small" htmlFor="email">OTP *</label>
                                                </div>
                                                <div className="text-right pt-2">
                                                    <span className='regenerate' onClick={handleRegerateOtp}> Regenerate OTP {time > 0 ? `(${time})` : ""} </span>
                                                </div>

                                                <div className="form-floating mt-4">
                                                    <input type="password" minLength={8} className="obx-small form-control" id="password" placeholder="Enter password" name="password" value={password} onChange={handlePasswordChange} required />
                                                    <label className="obx-small" htmlFor="password">Password *</label>
                                                </div>
                                                <div className="form-floating mt-4">
                                                    <input aria-invalid={passwordError} minLength={8} type="password" className={`obx-small form-control ${passwordError ? "is-invalid" : ""}`} id="confirmPassword" placeholder="Enter password" name="confirmPassword" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                                                    <label className="obx-small" htmlFor="confirmPassword">Confirm Password *</label>
                                                    <div class="invalid-feedback">
                                                        Password mismatch
                                                    </div>
                                                </div>
                                            </>

                                            : ""

                                    }

                                    <div className="text-center mt-5">
                                        <button type="submit" className={`btn btn-md w-100 btn-block btn-dark align-center vendor-button`} >
                                            {`Submit`}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showToaster ? <ToasterComponent type={toaster?.type} message={toaster?.message} /> : ""}

        </>
    );
}

export default ForgotPassword;

import React, { useState, useEffect, useMemo } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";

import TitleHeader from "../../common/title-header/title-header";
import AuthenticateAPI from "../../../apis/authenticate";
import Path from "../../routes/routes-path";
import SessionCache from "../../routes/session-cache";
import { getImageSource } from "../../../utility/image-source";
import ImageConstants from "../../../constants/image-constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCheck } from "@fortawesome/free-solid-svg-icons";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useLocation } from "react-router-dom";
import CommonUtils from "../../../utility/common-utilities";

function Register(props) {
  const { search } = useLocation();
  const data = useMemo(() => new URLSearchParams(search), [search]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [type, setType] = useState("BUYER");

  const [errorMessage, setErrorMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [uanNo, setUanNo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [submit, setSubmit] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isFormValidated, setFormValidate] = useState(false);
  const [isFormValidated2, setFormValidate2] = useState(false);
  const [isFormValidated3, setFormValidate3] = useState(false);
  const [isFormValidated4, setFormValidate4] = useState(false);
  const [isRegisted, setRegistered] = useState(false);
  const [currentPage, setPage] = useState(1);
  const [manpower, setManpower] = useState("<200")
  const [OBCReady, setOBCReady] = useState(false)
  const [awards, setAwards] = useState({
    ssia2023: false,
    ssia2022: false,
    goldenCircle2023: false
  })
  const [financialGrade, setFinancialGrade] = useState("S2")
  const [FGAmount, setFGAmount] = useState("$100,000")
  const [plrd, setPlrd] = useState([])

  useEffect(() => {
    // setCompanyName("company Name")
    setTimeout(() => {
      setType("VENDOR");
    }, 200);

    setTimeout(() => {
      setType("BUYER");
    }, 700);

    if(data.get("subscription_status") === 'success') {
      setRegistered(true)
    }
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (event.target.value === password) {
      setPasswordError(false);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleUanNoChange = (event) => {
    setUanNo(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handlePostalCodeChange = (event) => {
    setPostalCode(event.target.value);
  };

  const handleNext = (event, page) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setPasswordError(true);
      return "";
    } else {
      setPasswordError(false);
    }
    setPage(page || 1);
  };

  const handleBack = (event) => {
    setErrorMessage("");
    setPage(currentPage-1);
  };

  const goToLogin = () => {
    window.location.href = Path.LOGIN;
  };

  const handleRegister = (event) => {
    event.preventDefault();
    setErrorMessage("");
    if (submit) return;
    let payload = {};

    if (type === "BUYER") {
      payload = {
        type,
        name,
        company: companyName,
        phoneNumber,
        email,
        password,
        uanNumber: uanNo,
        address,
        city: "",
        pincode: postalCode,
      }
    } else {
      let parseAmount = financialGrade === "Ungrade" ? `$${CommonUtils.ThousandSeparator(Number(FGAmount))}` : FGAmount
      payload = {
        type,
        name,
        company: companyName,
        phoneNumber,
        email,
        password,
        uanNumber: uanNo,
        address,
        city: "",
        pincode: postalCode,
        obc_ready: OBCReady,
        financialGrade,
        financialGradeAmount: parseAmount,
        plrd: JSON.stringify(plrd),
        awards: JSON.stringify(awards),
        manpower
      }
    }
    setSubmit(true);

    AuthenticateAPI.signup(payload)
      .then((response) => {

        if(response?.data?.paymentIntent) {
          window.location.href = response.data.paymentIntent
        } else {
          setRegistered(true)
        }
        setSubmit(false);
      })
      .catch((err) => {
        setSubmit(false);

        if (err?.response?.data?.code === 11000) {
          setErrorMessage("User already registered with this email");
        }
      });
  };

  const renderStep1 = () => {
    return (
      <>
        <div className="text-center mt-4">
          <div className="buyer-vendor-wrapper">
            <div className="row px-0 mx-0">
              <div className="col px-0" onClick={() => setType("VENDOR")}>
                <div
                  className={`switch-button ${type === "VENDOR" ? "vendor-button" : ""
                    }`}
                >
                  Vendor
                </div>
              </div>
              <div className="col px-0" onClick={() => setType("BUYER")}>
                <div
                  className={`switch-button ${type === "BUYER" ? "buyer-button" : ""
                    }`}
                >
                  Buyer
                </div>
              </div>
            </div>
          </div>

          {/* <BootstrapSwitchButton
                        checked={type === "VENDOR"}
                        onlabel='Vendor'
                        onstyle='warning'
                        offlabel='Buyer'
                        offstyle='success'
                        style="w-100"
                        onChange={() => setType(type === "VENDOR" ? "BUYER" : "VENDOR")}
                    /> */}
        </div>
        <form
          className={`mt-4 ${isFormValidated ? "was-validated" : ""}`}
          onSubmit={(e) => handleNext(e, 2)}
          novalidate
          onInvalid={() => setFormValidate(true)}
        >
          <div className="form-floating mb-3">
            <input
              type="name"
              className="obx-small form-control "
              id="name"
              placeholder="Enter name"
              name="name"
              value={name}
              onChange={handleNameChange}
              required
            />
            <label className="obx-small" htmlFor="name">
              Full Name *
            </label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="email"
              className="obx-small form-control "
              id="email"
              placeholder="Enter email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <label className="obx-small" htmlFor="email">
              Email *
            </label>
          </div>
          <div className="form-floating mt-4">
            <input
              type="password"
              minLength={8}
              className="obx-small form-control"
              id="password"
              placeholder="Enter password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <label className="obx-small" htmlFor="password">
              Password *
            </label>
          </div>
          <div className="form-floating mt-4">
            <input
              aria-invalid={passwordError}
              minLength={8}
              type="password"
              className={`obx-small form-control ${passwordError ? "is-invalid" : ""
                }`}
              id="confirmPassword"
              placeholder="Enter password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            <label className="obx-small" htmlFor="confirmPassword">
              Confirm Password *
            </label>
            <div class="invalid-feedback">Password mismatch</div>
          </div>
          <div className="text-center pt-4">
            <button
              type="submit"
              className={`w-100 btn btn-block btn-dark align-center ${type === "VENDOR" ? "vendor-button" : "buyer-button"
                }`}
            >
              {`Next >`}
            </button>
          </div>
        </form>
      </>
    );
  };
  const renderStep3 = () => {
    let manpowers = [
      {value: "<200", label: "Below 200"},
      {value: "200-500", label: "200 - 500"},
      {value: ">500", label: "Above 500"},
    ]
    return (
      <div>

        <form
          className={`mt-4 ${isFormValidated3 ? "was-validated" : ""}`}
          onSubmit={(e) => {
            handleNext(e, 4)
          }}
          onInvalid={() => setFormValidate3(true)}
        >
          <div class="form-floating mt-4">
            <Form.Select
              id="mp_select" 
              aria-label="mp selection"
              value={manpower}
              onChange={(e) => {
                setManpower(e.target.value ?? "")
              }}
            >
              {manpowers.map((mp, id) => 
                  <option value={mp.value} key={id}>{mp.label}</option>
                )
              }
            </Form.Select>
            <label for="mp_select">Manpower Strength</label>
          </div> 

          <div 
            className="obc-ready mt-4" 
            style={{
              border: "1px solid #dee2e6", 
              borderRadius: "0.375rem", 
              padding: ".375rem 2.25rem .375rem .75rem"
            }}
          >
            <p 
              className="mb-1"
              style={{color:"rgba(var(--bs-body-color-rgb),.65)", fontSize: "1rem"}}
            >OBC Ready</p>
            <p className="mb-0" style={{fontSize:"0.9rem"}}>Please tick if your security agency is OBC ready. Requirement for being ready:</p>
            <ol className="mb-1" style={{fontSize:"0.9rem"}}>
              <li>Security Agency has an in-house Accredited Security Consultant (“ASC”) or a tie up with an ASC to provide consultancy.</li>
              <li>Security Agency has in-house capabilities for systems installation/integration or a formal tie up with a systems company. This includes having a signed Agreement for Services, a Memorandum of Understanding or Partnership for Services.</li>
            </ol>
            <div>
              <label className="fw-bold mr-2">OBC Ready</label>
              <div class="form-check form-check-inline" onChange={() => setOBCReady(true)}>
                <input class="form-check-input" type="radio" name="obc_ready_options" id="obcYes" value="yes" required checked={OBCReady}/>
                <label class="form-check-label" for="obcYes">Yes</label>
              </div>
              <div class="form-check form-check-inline"  onChange={() => setOBCReady(false)}>
                <input class="form-check-input" type="radio" name="obc_ready_options" id="obcNo" value="no" required checked={!OBCReady} />
                <label class="form-check-label" for="obcNo">No</label>
              </div>
            </div>
          </div>

          <div 
            className="awards mt-4"
            style={{
              border: "1px solid #dee2e6", 
              borderRadius: "0.375rem", 
              padding: ".375rem 2.25rem .375rem .75rem"
            }}
          >
            <p 
              className="mb-1"
              style={{color:"rgba(var(--bs-body-color-rgb),.65)", fontSize: "1rem"}}
            >Awards</p>
            <p className="mb-0" style={{fontSize:"0.9rem"}}>Please tick if your agency is a SSIA award winner. </p>
            <div>
              <label className="fw-bold mr-2">SSIA award winner for 2023</label>
              <div>
                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, ssia2023: true})} >
                  <input class="form-check-input" type="radio" name="SSIA_2023_opt" id="ssiaWinner23Yes" value="yes" required checked={awards.ssia2023} />
                  <label class="form-check-label" for="ssiaWinner23Yes">Yes</label>
                </div>
                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, ssia2023: false})} >
                  <input class="form-check-input" type="radio" name="SSIA_2023_opt" id="ssiaWinner23No" value="no" required checked={!awards.ssia2023} />
                  <label class="form-check-label" for="ssiaWinner23No">No</label>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="fw-bold mr-2">SSIA award winner for 2022</label>
              <div>
                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, ssia2022: true})} >
                  <input class="form-check-input" type="radio" name="SSIA_2022_opt" id="ssiaWinner22Yes" value="yes" required checked={awards.ssia2022} />
                  <label class="form-check-label" for="ssiaWinner22Yes">Yes</label>
                </div>
                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, ssia2022: false})} >
                  <input class="form-check-input" type="radio" name="SSIA_2022_opt" id="ssiaWinner22No" value="no" required checked={!awards.ssia2022} />
                  <label class="form-check-label" for="ssiaWinner22No">No</label>
                </div>
              </div>
            </div>

            <p className="mb-0" style={{fontSize:"0.9rem"}}>Please tick if your agency is a Golden Circle award winner</p>
            <div>
              <label className="fw-bold mr-2"> Golden Circle Award winners 2023</label>
              <div>
                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, goldenCircle2023: true})} >
                  <input class="form-check-input" type="radio" name="gcaw_options" id="goldenYes" value="yes" required checked={awards.goldenCircle2023} />
                  <label class="form-check-label" for="goldenYes">Yes</label>
                </div>
                <div class="form-check form-check-inline" onChange={() => setAwards({...awards, goldenCircle2023: false})} >
                  <input class="form-check-input" type="radio" name="gcaw_options" id="goldenNo" value="no" required checked={!awards.goldenCircle2023} />
                  <label class="form-check-label" for="goldenNo">No</label>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center pt-4">
            <button
              onClick={() => handleBack()}
              className={`btn space-between-btn btn-block btn-dark align-center ${type === "VENDOR" ? "vendor-button" : "buyer-button"
                }`}
            >
              {`Back`}
            </button>
            <button
              type="submit"
              className={`btn btn-block btn-dark align-center ${type === "VENDOR" ? "vendor-button" : "buyer-button"
                }`}
            >
              {`Next`}
            </button>
          </div>
        </form>
      </div>
    );
  };
  const renderStep2 = () => {
    return (
      <form
        className={`mt-4 ${isFormValidated2 ? "was-validated" : ""}`}
        onSubmit={(e) => type === "BUYER" ? handleRegister(e) : handleNext(e, 3)}
        onInvalid={() => setFormValidate2(true)}
      >
        <div className="form-floating mb-3">
          <input
            type="text"
            className="obx-small form-control "
            id="companyName"
            placeholder="Enter Company Name"
            name="companyName"
            value={companyName}
            onChange={handleCompanyNameChange}
            required
          />
          <label className="obx-small" htmlFor="companyName">
            Company Name *
          </label>
        </div>
        <div className="form-floating mt-4">
          <input
            type="text"
            className="obx-small form-control"
            id="uanNo"
            placeholder="Enter uanNo"
            name="uanNo"
            value={uanNo}
            onChange={handleUanNoChange}
            required
          />
          <label className="obx-small" htmlFor="uanNo">
            UEN Number *
          </label>
        </div>

        <div className="form-floating mt-4">
          <input
            type="number"
            className="obx-small form-control"
            id="phoneNumber"
            placeholder="Enter phone number"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            required
          />
          <label className="obx-small" htmlFor="phoneNumber">
            Phone No (+65) *
          </label>
        </div>

        <div className="form-floating mt-4">
          <textarea
            type="text"
            className="obx-small form-control h-120"
            id="address"
            placeholder="Enter address"
            name="address"
            value={address}
            onChange={handleAddressChange}
            required
          />
          <label className="obx-small" htmlFor="address">
            Address *
          </label>
        </div>

        <div className="form-floating mt-4">
          <input
            type="text"
            className="obx-small form-control"
            id="postalCode"
            placeholder="Enter postal code"
            name="postalCode"
            value={postalCode}
            onChange={handlePostalCodeChange}
            required
          />
          <label className="obx-small" htmlFor="postalCode">
            Postal Code *
          </label>
        </div>

        <div className="mt-4">
          <div class="form-check mb-3">
            <input
              type="checkbox"
              class="form-check-input"
              id="validationFormCheck1"
              required
            />
            <label class="form-check-label" for="validationFormCheck1">
              <span>
                I accept the OBX Platform{" "}
                <a href="https://obx.sg/terms">Terms & Conditions </a>{" "}
              </span>
            </label>
          </div>
          <div class="form-check mb-3">
            <input
              type="checkbox"
              class="form-check-input"
              id="validationFormCheck2"
              required
            />
            <label class="form-check-label" for="validationFormCheck2">
              <span>
                I accept OBX platform's{" "}
                <a href="https://obx.sg/privacy">Privacy Policy</a>{" "}
              </span>
            </label>
          </div>
        </div>

        <div>
          {errorMessage ? (
            <div className="text-center error-message pt-4">{errorMessage}</div>
          ) : (
            ""
          )}
        </div>

        <div className="text-center pt-4">
          <button
            onClick={handleBack}
            className={`btn space-between-btn btn-block btn-dark align-center ${type === "VENDOR" ? "vendor-button" : "buyer-button"
              }`}
          >
            {`Back`}
          </button>
          <button
            type="submit"
            className={`btn btn-block btn-dark align-center ${type === "VENDOR" ? "vendor-button" : "buyer-button"
              }`}
          >
            {type === "VENDOR" ? `Next` : `Register`}
          </button>
        </div>
      </form>
    );
  };
  const renderStep4 = () => {
    let electives = [
      {id: 1, elective_desc: "Continuous Professional Development" },
      {id: 2, elective_desc: "Simulation Training" },
      {id: 3, elective_desc: "Job Redesign" },
      {id: 4, elective_desc: "Business Continuity Plan" },
      {id: 5, elective_desc: "Security Checks" },
      {id: 6, elective_desc: "Standard Operating Procedures (SOPs)" },
      {id: 7, elective_desc: "Service Buyer Management Process" },
      {id: 8, elective_desc: "Supervision" },
      {id: 9, elective_desc: "Body Worn Cameras / Wireless Communications" },
      {id: 10, elective_desc: "Video Analytics" },
      {id: 11, elective_desc: "Remote Guarding" },
      {id: 12, elective_desc: "Technology Enabled Patrol" },
    ]
    return (
      <form
        className={`mt-4 ${isFormValidated2 ? "was-validated" : ""}`}
        onSubmit={(e) => handleNext(e, 5)}
        onInvalid={() => setFormValidate2(true)}
      >
        <p className="fw-bold mb-0">PLRD Electives</p>
        <span className="mb-2">*Please tick the electives that your company is PLRD elective competence. </span>
        {electives.map((el,i) => 
        <div key={i} class="form-check mb-1" onChange={() => {
          if (plrd.map((v) => v.id).includes(el.id)) {
            setPlrd(plrd.filter((item) => item.id !== el.id));
          } else {
            setPlrd([...plrd, el]);
          }
        }}>
          <input 
            class="form-check-input" 
            type="checkbox"
            value={el.id}
            id={"elective_check"+i+el.id}
            checked={plrd.map((v) => v.id).includes(el.id)}
          />
          <label class="form-check-label" for={"elective_check"+i+el.id}>
            {el.elective_desc}
          </label>
        </div>
        )}
        <div className="text-center pt-4">
          <button
            onClick={() => handleBack()}
            className={`btn space-between-btn btn-block btn-dark align-center ${type === "VENDOR" ? "vendor-button" : "buyer-button"
              }`}
          >
            {`Back`}
          </button>
          <button
            type="submit"
            className={`btn btn-block btn-dark align-center ${type === "VENDOR" ? "vendor-button" : "buyer-button"
              }`}
          >
            {`Next`}
          </button>
        </div>
      </form>
    );
  };
  const renderStep5 = () => {
    let financialGrades = [
      { grade: "S2", amount: "$100,000"},
      { grade: "S3", amount: "$250,000"},
      { grade: "S4", amount: "$500,000"},
      { grade: "S5", amount: "$1,000,000"},
      { grade: "S6", amount: "$3,000,000"},
      { grade: "S7", amount: "$5,000,000"},
      { grade: "S8", amount: "$10,000,000"},
      { grade: "S9", amount: ">$10,000,000"},
      { grade: "S10", amount: ">$15,000,000"},
      { grade: "Ungrade", amount: null}
    ]
    return (
    <form
      className={`mt-4 ${isFormValidated2 ? "was-validated" : ""}`}
      onSubmit={handleRegister}
      onInvalid={() => setFormValidate2(true)}
    >
      <div>
        <div class="form-floating mt-4">
          <Form.Select
            id="financial_select" 
            aria-label="financial selection"
            value={financialGrade}
            onChange={(e) => {
              setFinancialGrade(e?.target?.value || "")
              let findFGA = financialGrades.find(v => v.grade === e?.target?.value || "")
              setFGAmount(findFGA ? findFGA.amount : null)
            }}
          >
            {financialGrades.map((fg, id) => 
                <option value={fg.grade} key={id}>{fg.grade} {fg.amount && `(${fg.amount})`}</option>
              )
            }
          </Form.Select>
          <label for="financial_select">Financial Grades</label>
        </div> 
        {
          financialGrade === "Ungrade" &&
          <div className="form-floating mt-3 mb-3">
            <input
              type="number"
              className="obx-small form-control "
              id="fgAmount"
              name="fgAmount"
              value={FGAmount}
              onChange={(e) => setFGAmount(e.target.value)}
              required
            />
            <label className="obx-small" htmlFor="fgAmount">
              Enter Amount ($) *
            </label>
          </div>
        }
        <div style={{paddingLeft:5, paddingRight: 5}}>Please select the financial grade for your agency (Includes turnover/sales/revenue and also ungraded but must have revenue) </div>
      </div>
      <div className="text-center pt-4">
          <button
            onClick={handleBack}
            className={`btn space-between-btn btn-block btn-dark align-center ${type === "VENDOR" ? "vendor-button" : "buyer-button"
              }`}
          >
            {`Back`}
          </button>
          <button
            type="submit"
            className={`btn btn-block btn-dark align-center ${type === "VENDOR" ? "vendor-button" : "buyer-button"
              }`}
          >
            {`Register`}
          </button>
        </div>
    </form>)
  }


  return (
    <>
      {/* <TitleHeader title="REGISTER" /> */}
      <div className="row LoginForm justify-content-center  py-5">
        <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6 hide-mobile-view">
          <div className="d-flex justify-content-center mh-container">
            <img
              className="login-image"
              src={getImageSource(ImageConstants.OBX_LOGIN)}
              alt=""
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12 col-lg-6 py-4">
          <div className="d-flex justify-content-center">
            <div className="position-relative">
              <div className="rounded p-5 shadow-lg login-card">
                <div className="icon-wrapper rounded shadow-lg">
                  {!isRegisted ? (
                    <FontAwesomeIcon icon={faUser} />
                  ) : (
                    <FontAwesomeIcon color="green" icon={faCheck} />
                  )}
                </div>
                {!isRegisted ? (
                  <div>
                    <h2 className="text-center pt-4">Register</h2>
                    <h6 className="text-center">
                      {" "}
                      Please fill in your particulars to register an account.
                    </h6>
                  </div>
                ) : (
                  ""
                )}
                {/* {type === "BUYER" ? <h6 className="text-center"> Please register as buyer account to access the OBT Generator.</h6> : ""} */}
                {!isRegisted ? (
                  <div>{currentPage === 1 
                    ? renderStep1() 
                    : currentPage === 2
                    ? renderStep2()
                    : currentPage === 3
                    ? renderStep3()
                    : currentPage === 4
                    ? renderStep4()
                    : renderStep5()
                  }</div>
                ) : (
                  <div className="text-center pt-5">
                    <div className="text-success h4">
                      Successfully Registered{" "}
                    </div>
                    <div className="pt-4">
                      We have sent you an email to your registered email
                      address, please verify the account before login.
                    </div>
                    <div className="pt-5">
                      <button
                        onClick={goToLogin}
                        className={`w-100 btn btn-block btn-dark align-center`}
                      >
                        {`Back to login`}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;

import { useEffect, useState } from "react";
import Constants from "../../../constants/constants";
import CommonUtils from "../../../utility/common-utilities";
import Slider from "../../slider/slider";

const KPIBenchmarking = (props) => {
  const [kpis, setKpis] = useState([]);
  const [errorIndex, setErrorIndex] = useState("");
  const [errorKey, setErrorKey] = useState("");
  const [errorSubIndex, setErrorSubIndex] = useState("");

  const ApplayThresholdChangeformula = (kpis) => {
    kpis.forEach((element) => {
      element.scale = setScales(element.failThreshold, element.inverseScale)
    });
    return kpis;
  };

  useEffect(() => {
    let selectedKpis = props.selectedKpis || [];

    if (selectedKpis[0]?.scale) {
      setKpis(selectedKpis);
      return;
    }

    selectedKpis.forEach((element) => {
      element.scale = setScales(element.failThreshold, element.inverseScale)
    });
    setKpis(selectedKpis);
    props.emitChanges && props.emitChanges(selectedKpis, "4");
  }, []);

  const setScales =  (total, inverseScale) => {
    let scale = []
    let standardsFormula = [
      {type: "Excellent", percentage: 0},
      {type: "Good", percentage: 25},
      {type: "Passable", percentage: 50},
      {type: "Poor", percentage: 75},
      {type: "Fail", percentage: 100},
    ]
    let inversFormula = [
      {type: "Fail", percentage: 0},
      {type: "Poor", percentage: 25},
      {type: "Passable", percentage: 50},
      {type: "Good", percentage: 75},
      {type: "Excellent", percentage: 100},
    ]

    let formula = standardsFormula
    formula.forEach((el, idx) => {
      let high = 0
      let low = 0

      let nextform = formula[idx+1]
      if ((el.type === "Poor" || el.type === "Passable") && total < 2) {
        low = ""
        high = ""
      } else if(el.type === "Good" && total < 4) {
        low = ""
        high = ""
      } else {
        if(el.percentage > 0) {
          low = Math.round((el.percentage / 100) * total);
          high = nextform ? (Math.round((nextform.percentage / 100) * total) - 1) || "More" : "More"
        } else {
          high = 'Less'
          low = nextform ? (Math.round((nextform.percentage / 100) * total) - 1) || 0 : 0
        }
      }
      scale.push({ title: el.type, low, high });
    })
    return scale.sort((a,b) => b.low - a.low)

  }

  const onChangeThreshold = (event, index, allowFractions) => {
    let kpisArr = [...kpis];
    if(!allowFractions) {
        if(/^[0-9]*$/.test(event.target.value)) {
          if(event.target.value <= 999) {
            kpisArr[index].failThreshold = +event?.target.value;
          }
        }
        if(event.target.value === "") {
          kpisArr[index].failThreshold = event?.target.value;
        }
    } else {
      let pattern = /^[0-9]*(\.[0-9]{0,2})?$/
      if(pattern.test(event.target.value)) {
        if(event.target.value <= 999) {
          kpisArr[index].failThreshold = +event?.target.value;
        }
        if(event.target.value === "") {
          kpisArr[index].failThreshold = event?.target.value;
        }
      } 
    }
    const FormulatedKpis = ApplayThresholdChangeformula(kpisArr);
    setPayloadObject(FormulatedKpis);
  };

  const onChangeScale = (event, index, subIndex, key) => {
    let kpisArr = [...kpis];
    kpisArr[index].scale[subIndex][key] = event?.target.value;
    setPayloadObject(kpisArr);
  };

  const setPayloadObject = (payloadObj) => {
    setKpis(payloadObj);
    setErrorKey("");
    setErrorIndex("");
    setErrorSubIndex("");
    props.emitChanges && props.emitChanges(payloadObj, "4");
  };

  const validateBenchMark = () => {
    console.log(kpis);
    let result = CommonUtils.validateBenchMark(kpis);
    console.log(result);
    setErrorKey(result.errorKey);
    setErrorIndex(result.errorIndex);
    setErrorSubIndex(result.errorSubIndex);
    return result.success;
  };

  const onClickNext = () => {
    let validation = validateBenchMark();
    // validation logic to be chnage
    // if (!validation) return;

    props.emitChanges && props.emitChanges(kpis, "4");
    props.next();
  };
  const OnSliderValueChangeHandler = (UpdatedScale, Index) => {
    const UpdatedKpis = [...kpis];
    UpdatedKpis[Index].scale = UpdatedScale;

    setKpis(UpdatedKpis);
  };

  return (
    <div>
      <span className="h6 header_title_caption fs-5">KPI Benchmarking</span>
      <hr></hr>
      <div className="card mt-3 tooltip-background-color">
        <div className="card-body p-4">
          <div className="row">
            <div className="col header_title_caption">
              <h6 className="card-title mb-4">
                <i className="me-2 bi bi-info-circle"></i>Performance Metrics
                are configured in 3 stages
              </h6>
            </div>
          </div>

          <div className="row">
            <div className="col-1"></div>
            <div className="col-11 ">
              <div className="header_title_caption">
                <small>1. Select a KPI for each outcome</small>
              </div>
              <div className="header_title_caption">
                <small>
                  <strong>
                    2. [Current] Set the performance requirements for each KPI
                  </strong>
                </small>
              </div>
              <div className="header_title_caption">
                <small>
                  3. Set Contract Sum Grades based on the total score across all
                  KPIs
                </small>
              </div>
            </div>
          </div>

          <div className="m-4 header_title_caption">
            <small>
              Please find below a grading rubric across 5 bands ranging from
              Service Failure to Exceeds Expectations. This rubric is to be
              completed each month-end and used to evaluate the quality of your
              service provider's outcomes.
            </small>
          </div>
          {/* <div className="m-4 header_title_caption">
            <small>
              Please note that while you are encouraged to assign high standards
              to your service providers, highly punitive or exacting standards
              may incur higher costs from service providers.
            </small>
          </div> */}
        </div>
      </div>
      <div className="card mt-4 table_style">
        <div className="card-body">
          <table className="table">
            <thead>
              <tr className="text-center header_title_caption tables_header_rowborderbottom">
                <th scope="col" colSpan={2}>
                  KPI
                </th>
                <th scope="col">Value for Service Failure</th>
                <th scope="col">unit</th>
                <th scope="col">
                  0 points <br />
                  <span className="kpi_colum_subheading_font-size">
                    (Service Failure)
                  </span>
                </th>
                <th scope="col">
                  1 point <br />
                  <span className="kpi_colum_subheading_font-size">
                    (Far Below Expectations)
                  </span>
                </th>
                <th scope="col">
                  2 points
                  <br />
                  <span className="kpi_colum_subheading_font-size">
                    (Slightly Below Expectations)
                  </span>
                </th>
                <th scope="col">
                  3 points
                  <br />
                  <span className="kpi_colum_subheading_font-size">
                    (Meets Expectations)
                  </span>
                </th>
                <th scope="col">
                  4 points
                  <br />
                  <span className="kpi_colum_subheading_font-size">
                    (Exceeds Expectations)
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {kpis.map((item, index) => {
                return (
                  <tr
                    className={`small header_title_caption`}
                    key={"kpi_"+index}
                    // style={{
                    //   background: `${index % 2 === 0 ? `#f5ffff` : `#E9FBFA`}`,
                    // }}
                  >
                    <td colSpan={2}>
                      <small className="fw-bold kpi_table_remuneration_kpi_description">
                        {item.description}
                      </small>
                    </td>
                    <td className="text-center">
                      <input
                        type="number"
                        className="form-control"
                        value={item.failThreshold}
                        onChange={(e) => onChangeThreshold(e, index, item.allowFractions)}
                      />
                    </td>
                    <td className="text-center">
                      <small>
                        {item.unit}
                      </small>
                    </td>
                    <td colSpan={5}>
                      {
                        <Slider
                          key={`${item.outcomeId} ${index}`}
                          Index={index}
                          failThreshold={item.failThreshold}
                          scale={item.scale || []}
                          onSliderChange={OnSliderValueChangeHandler}
                          inverseScale={item.inverseScale}
                          allowFractions={item.allowFractions}
                        />
                      }
                    </td>

                    {/* {item.scale.map((i, subIndex) => {
                      return (
                        <td className="text-center">
                          <div className="py-5">
                            <small>
                              <input
                                className={`form-control ${
                                  errorIndex === index &&
                                  errorSubIndex === subIndex &&
                                  errorKey === "low"
                                    ? "invalid-input"
                                    : ""
                                }`}
                                value={i.low}
                                onChange={(e) =>
                                  onChangeScale(e, index, subIndex, "low")
                                }
                              />
                            </small>
                            <div className="py-1">
                              {subIndex === 0 ||
                              subIndex === item.scale?.length - 1
                                ? "or"
                                : "to"}
                            </div>
                            <small>
                              <input
                                className={`form-control ${
                                  errorIndex === index &&
                                  errorSubIndex === subIndex &&
                                  errorKey === "high"
                                    ? "invalid-input"
                                    : ""
                                }`}
                                value={i.high}
                                onChange={(e) =>
                                  onChangeScale(e, index, subIndex, "high")
                                }
                              />
                            </small>
                          </div>
                        </td>
                      );
                    })} */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* {kpis.map((kpis, index) => (
        <Slider
          key={`${kpis.outcomeId} ${index}`}
          Index={index}
          failThreshold={kpis.failThreshold}
          scale={kpis.scale}
          onSliderChange={OnSliderValueChangeHandler}
        />
      ))} */}
      <div className="row mt-4">
        <div className="col">
          <button
            onClick={props.back}
            type="button"
            className="btn btn-md btn-light float-left"
          >
            Back
          </button>
        </div>
        <div className="col">
          <button
            onClick={onClickNext}
            type="button"
            className="btn btn-md btn-primary obx-btn-color float-end"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default KPIBenchmarking;

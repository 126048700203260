
export default class SessionCache {

    static user = null;

    static isAuthenticated() {
          
        if (this.user == null) {
            return null;
        }
        else if (this.user['isAuthorized']) {
            return true;
        }

        return false;
    }

    static isVerified() {

        return false;
    }

    static getName() {
        if (this.user) {
            return this.user.name;
        }

        return "";
    }


    static getUserType() {
        return this.user?.type
    }

    static getUser(obj) {

        return this.user
    }

    static setUser(obj) {

        this.user = obj;

    }
};
import { useEffect, useState } from "react";
import Constants from "../../../constants/constants";
import "../../../styles/fonts.scss";
import "../create-tender-layout.scss";

const Renumeration = (props) => {
  const [renumerations, setRenumerations] = useState(props.payload || []);
  const [isCheacked, SetIsCheacked] = useState(false);

  useEffect(() => {
    applyFormula(props.kpis?.length * Constants.KPI_VALUE);
  }, [isCheacked]);

  const applyFormula = (total) => {
    let data1 = [
      {
        type: "PERCENTAGE",
        value: 103,
        range: {
          type: "NUMBER",
          low: PercentageValue(total, 90),
          high: PercentageValue(total, 100),
        },
      },
      {
        type: "PERCENTAGE",
        value: 100,
        range: {
          type: "NUMBER",
          low: PercentageValue(total, 60),
          high: PercentageValue(total, 90) - 1,
        },
      },
      {
        type: "PERCENTAGE",
        value: 95,
        range: {
          type: "NUMBER",
          low: PercentageValue(total, 45),
          high: PercentageValue(total, 60) - 1,
        },
      },
      {
        type: "PERCENTAGE",
        value: 90,
        range: {
          type: "NUMBER",
          low: PercentageValue(total, 30),
          high: PercentageValue(total, 45) - 1,
        },
      },
      {
        type: "PERCENTAGE",
        value: 87,
        range: {
          type: "NUMBER",
          low: 0,
          high: PercentageValue(total, 30) - 1,
        },
      },
    ];
    let data2 = [
      {
        type: "PERCENTAGE",
        value: 100,
        range: {
          type: "NUMBER",
          low: PercentageValue(total, 85),
          high: PercentageValue(total, 100),
        },
      },
      {
        type: "PERCENTAGE",
        value: 97,
        range: {
          type: "NUMBER",
          low: PercentageValue(total, 70),
          high: PercentageValue(total, 85) - 1,
        },
      },
      {
        type: "PERCENTAGE",
        value: 95,
        range: {
          type: "NUMBER",
          low: PercentageValue(total, 55),
          high: PercentageValue(total, 70) - 1,
        },
      },
      {
        type: "PERCENTAGE",
        value: 90,
        range: {
          type: "NUMBER",
          low: PercentageValue(total, 30),
          high: PercentageValue(total, 55) - 1,
        },
      },
      {
        type: "PERCENTAGE",
        value: 87,
        range: {
          type: "NUMBER",
          low: 0,
          high: PercentageValue(total, 30) - 1,
        },
      },
    ];
    const Result = isCheacked ? data1 : data2;
    setRenumerations(Result);
    props.emitChanges && props.emitChanges(Result, "5");
  };

  const PercentageValue = (total, percentage) => {
    return Math.floor((total * percentage) / 100);
  };

  const handleChange = (value, index, range) => {
    let renumerationsObj = [...renumerations];

    let obj = { ...renumerationsObj[index] };
    obj.range[range] = +value;

    renumerationsObj[index] = obj;
    setRenumarionObj(renumerationsObj);
  };

  const setRenumarionObj = (payload) => {
    setRenumerations(payload);
    props.emitChanges && props.emitChanges(payload, "5");
  };

  const handleNext = () => {
    props.emitChanges && props.emitChanges(renumerations, "5");
    props.next();
  };

  return (
    <div>
      <span className="h6 header_title_caption fs-5">Contract Sum Grade</span>
      <hr></hr>
      <div className="card mt-3  header_title_caption tooltip-background-color">
        <div className="card-body p-4">
          <div className="row">
            <div className="col">
              <h6 className="card-title mb-4">
                <i className="me-2 bi bi-info-circle"></i>Performance Metrics
                are configured in 3 stages
              </h6>
            </div>
          </div>

          <div className="row">
            <div className="col-1"></div>
            <div className="col-11">
              <div>
                <small>1. Select a KPI for each outcome</small>
              </div>
              <div>
                <small>2. Set the performance requirements for each KPI</small>
              </div>
              <div>
                <small>
                  <strong>
                    3. [Current] Set Contract Sum levels based on the total
                    score across all KPIs
                  </strong>
                </small>
              </div>
            </div>
          </div>

          <div className="m-4">
            <small>
              The following rubric will be used to determine the Contract Sum
              paid out to the contractor based on their performance each month.
            </small>
          </div>
          {/* <div className="m-4">
            <small>
              Please note that while you are encouraged to assign high standards
              to your service providers, highly punitive or exacting standards
              may incur higher costs from service providers.
            </small>
          </div> */}
        </div>
      </div>
      <div className="card mt-4 table_style">
        <div className="card-body">
          <div>
            <input
              className="form-check-input"
              type="checkbox"
              checked={isCheacked}
              onClick={(e) => {
                SetIsCheacked(!isCheacked);
              }}
            />
            <label className="ms-2 fw-bold">Incentive for Highest Outcome Tier</label>
          </div>
          <table className="table header_title_caption">
            <thead>
              <tr className="tables_header_rowborderbottom">
                <th scope="col">Contract Sum Grade (%)</th>
                <th scope="col">Minimum Score</th>
                <th scope="col">Maximum Score</th>
              </tr>
            </thead>
            <tbody>
              {renumerations.map((item, index) => {
                return (
                  <tr className="small">
                    <td>
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          value={item.value}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          value={item.range.low}
                          onChange={(e) =>
                            handleChange(e?.target?.value, index, "low")
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          value={item.range.high}
                          onChange={(e) =>
                            handleChange(e?.target?.value, index, "high")
                          }
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <button
            onClick={props.back}
            type="button"
            className="btn btn-md btn-light float-left"
          >
            Back
          </button>
        </div>
        <div className="col">
          <button
            onClick={handleNext}
            type="button"
            className="btn btn-md btn-primary obx-btn-color float-end"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Renumeration;

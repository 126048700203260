import "../../styles/fonts.scss";
import "../Terms/Terms.scss";

function Terms(prop) {
  return (
    <div className="container py-4 header_title_caption">
      <div className="container-fluid">
        <div className="card sshadow-sm obx-blue p-4 mb-4">
          <div className="row">
            <div className="col obx-white header_title_head">
              <h4>TERMS AND CONDITIONS</h4>
            </div>
          </div>
        </div>
        <div className="Terms">
          <div className="py-3">
            <ol>
              <li>
                These terms of use (the "<strong>Terms</strong>" or "
                <strong>Terms of Use</strong>"), together with all other
                agreements, disclaimers, and disclosures (the “
                <strong>Other Terms</strong>”) displayed on this site
                ([https://www.obx.sg]) (the “<strong>Site</strong>”) or
                otherwise entered into between (i) you and (ii) [name of
                operator of the Site/OBX], its subsidiaries, and/or associated
                companies (collectively, “<strong>we</strong>,” “
                <strong>us</strong>” or “<strong>our</strong>”), sets out the
                terms and conditions under which you may access or use the
                [Outcome Based Tender Generating Platform] (the “
                <strong>OBX</strong>”), the Site and/or any services provided on
                or under the OBX and/or the Site (the “<strong>Services</strong>
                ”).{" "}
              </li>
              <text>By:</text>
              <ol className="a">
                <li className="py-3">
                  accessing or using the OBX, the Site and/or any Services in
                  any way, including using the agreements, contracts, data,
                  information, reports, templates, text, and/or other materials
                  made available, enabled or generated via the OBX, the Site
                  and/or the Services (collectively, the “
                  <strong>OBX Materials</strong>“);{" "}
                </li>
                <li className="py-3">
                  clicking on any “I/We accept” or “I/We agree” button (or any
                  variance of the same); and/or{" "}
                </li>
                <li className="py-3">
                  browsing and/or reviewing the OBX and/or the Site,{" "}
                </li>
              </ol>
              <text className="py-3">
                you represent that you have the capacity and authority to agree
                and agree that the Terms together with the Other Terms
                constitute a legally binding agreement made between (i) you,
                whether personally or on behalf of an entity; and (ii) us, and
                to unconditionally follow and be bound by these Terms and the
                Other Terms.{" "}
              </text>
              <text className="py-3">
                In the event of any inconsistencies between the (i) Terms; and
                (ii) the Other Terms, the provisions of the Other Terms shall
                prevail.
              </text>
              <div className="py-3">
                <text>
                  <strong>
                    PLEASE BE ADVISED THAT THE TERMS OF USE ARE SUBJECT TO
                    CHANGE AT ANY TIME BY US IN OUR SOLE AND ABSOLUTE DISCRETION
                  </strong>
                  . In the event any changes to the Terms are made, we will make
                  a new copy of the Terms available on the Site. You agree and
                  undertake that you shall check and review the Terms, available
                  through a link on every page of the Site, each time you use
                  the OBX, the Site and/or the Services to determine if any
                  changes have been made. In the event you shall use the OBX,
                  the Site and/or the Services after the amended Terms have been
                  posted, you shall be deemed to have agreed unconditionally to
                  the amended Terms.
                </text>
              </div>
              <li className="py-3">
                <u>Registration of Account, Fees and Payments</u>
              </li>
              <ol className="a">
                <li className="py-3">
                  By registering an account for accessing or using the OBX, the
                  Site and/or any Services (the “<strong>Account</strong>”), you
                  agree and undertake that all information provided or submitted
                  to us in connection with the registration of the Account shall
                  be true, accurate and not misleading any way.{" "}
                </li>
                <li className="py-3">
                  You further agree and undertake to safeguard your username and
                  password to the Account and keep your Account information
                  current (including email address, payment method and credit or
                  debit card expiration date, so that we can complete your
                  transactions and contact you as needed).
                </li>
                <li className="py-3">
                  You agree and confirm that we shall have the absolute
                  discretion (but no obligation) to suspend or terminate your
                  Account and/or to disable any password (whether chosen by you
                  or allocated by us) at any time without notice if and when we
                  detect that an Account has been used by multiple users and/or
                  if you are in breach of these Terms, and we shall not be
                  liable to you or any third party for such suspension or
                  termination.
                </li>
                <li className="py-3">
                  If we terminate or suspend your Account for any reason, you
                  are prohibited from registering and creating a new Account
                  under your name, a fake or borrowed name, or the name of any
                  third party, even if you may be acting on behalf of the third
                  party. In addition to terminating or suspending your Account,
                  we reserve the right to take appropriate legal action,
                  including without limitation pursuing civil, criminal, and
                  injunctive redress.
                </li>
                <li className="py-3">
                  Fees for the use of the OBX, the Site and/or the Services may
                  be charged on a per purchase basis or on a yearly or other
                  recurring basis (the “<strong>Fees</strong>”). If your
                  purchase is subject to recurring charges, then you consent to
                  us charging your payment method in advance on a recurring
                  basis, without requiring your prior approval for each
                  recurring charge. Recurring charges will continue until you
                  notify us of your cancellation. Charges for renewal periods
                  shall be calculated at the prevailing rates then offered by
                  us. In the event you fail to make a timely payment, you will
                  be responsible for all reasonable expenses (including
                  attorneys’ fees) incurred by us in collecting such amounts. We
                  reserve the right to suspend access to the OBX, the Site
                  and/or the Services in the event you fail to make a timely
                  payment hereunder or if any payment is subsequently cancelled
                  or refunded.
                </li>
                <li>
                  We reserve the right to refuse any order placed through the
                  OBX, the Site and/or the Services. We also reserve the right
                  to correct any errors or mistakes in pricing, even if we have
                  already requested or received payment.
                </li>
              </ol>
              <li className="py-3">
                <u>Licence to use</u>
              </li>
              <ol className="a">
                <li className="py-3">
                  You are granted a limited, non-exclusive, non-assignable,
                  non-transferrable, non-sublicensable and revocable license to
                  access and use the OBX, the Site and the Services and copy
                  (electronically or otherwise) and print to hard copy all or
                  portions of the OBX Materials for your information and for
                  non-commercial use only; save and except that you are
                  expressly permitted to distribute, download, modify, revise,
                  use and/or view any tender documents (or part thereof)
                  generated through your use of the OBX and/or the Services (the
                  “<strong>Tender Documents</strong>”, and for the purpose of
                  these Terms “Tender Documents” shall include any Tender
                  Documents, or part thereof, amended, modified or revised by
                  you){" "}
                  <u>
                    solely for the purpose of inviting security service
                    providers to participate in the tender for providing
                    security services (the “<strong>Project</strong>”) for which
                    the Tender Documents are generated and the appointment of
                    such service providers for the said Project
                  </u>
                  .
                </li>
                <li className="py-3">
                  Unless expressly stated herein, nothing in these Terms shall
                  be construed as conferring any license to intellectual
                  property rights, whether by estoppel, implication or
                  otherwise.{" "}
                </li>
                <li className="py-3">
                  The license granted hereunder is revocable at any time at our
                  sole and absolute discretion.
                </li>
              </ol>
              <li className="py-3">
                <u>Intellectual property rights</u>
              </li>
              <ol className="a">
                <li className="py-3">
                  The OBX, the Site, the Services and all OBX Materials
                  (including without limitation, the Tender Documents) are our
                  proprietary property and all source code, databases,
                  functionality, software, website designs, audio, video, text,
                  photographs, and graphics on the OBX and/or the Site and the
                  copyright, patents, trademarks, service marks, and logos
                  contained therein are owned or controlled by us or licensed to
                  us for use in connection with the OBX, the Site and/or the
                  Services. All rights under applicable laws are hereby
                  expressly reserved. You confirm, acknowledge and undertake
                  that the OBX, the Site, the Services and all OBX Materials are
                  protected by copyrights, trademarks, trade secrets, patents
                  and/or other proprietary rights, and that these worldwide
                  rights belong solely to us and are valid and protected in all
                  forms, media and technologies now existing or hereafter
                  developed.
                </li>
                <li className="py-3">
                  Without prejudice to Clause 4.1 and save as otherwise
                  permitted under Clause 3.1, you agree and undertake that:{" "}
                </li>
                <ol className="b">
                  <li className="py-3">
                    You shall not amend, distribute, modify, post, process,
                    publish, reproduce, revise, transmit or transfer any content
                    or component of the OBX, the Site, the Services and/or any
                    OBX Materials in any way, or create any derivative works or
                    competitive products with respect to any such content or
                    component, or use of the OBX, the Site, the Services and/or
                    any OBX Materials other than for their intended purpose;{" "}
                  </li>
                  <li className="py-3">
                    You shall not allow any third party to access, use or
                    benefit from your Account or any OBX Materials in any way;
                  </li>
                  <li className="py-3">
                    Reverse engineer, decompile, disassemble, make or otherwise
                    attempt to derive the source code of the OBX, the Site
                    and/or the Services, or the underlying ideas or algorithms
                    of the OBX, the Site and/or the Services;
                  </li>
                  <li className="py-3">
                    Attempt to gain unauthorised access to any servers, database
                    or any other service, account, computer system or network
                    associated with or used for the operation of the OBX, the
                    Site and/or the Services;{" "}
                  </li>
                  <li className="py-3">
                    Block, disable, or otherwise affect any advertising, banner
                    window, tab, link to websites and services, or other
                    features that are part of the OBX, the Site and/or the
                    Services;
                  </li>
                  <li className="py-3">
                    Incorporate, integrate or otherwise include the OBX, the
                    Site and/or the Services or any part thereof into any
                    software, program or product; and/or
                  </li>
                  <li className="py-3">
                    You shall comply with all intellectual property laws and
                    regulations and you shall not create any encumbrance in, or
                    assert any rights to, the OBX, the Site, the Services and/or
                    the OBX Materials.{" "}
                  </li>
                </ol>
                <li className="py-3">
                  You shall retain your rights to any information, data or
                  content that you upload, post, e-mail, transmit or otherwise
                  make available to us or on the OBX and/or the Site
                  (collectively, the “<strong>User Content</strong>”); however,
                  by uploading, posting, e-mailing, transmitting or otherwise
                  making available such User Content to us or on the OBX and/or
                  the Site, you grant us an irrevocable, worldwide,
                  non-exclusive, perpetual, royalty-free licence (with the right
                  to sub-license) to copy, distribute, modify, post, process,
                  publish, reproduce, revise, transmit or update or use such
                  content in any form, media or technology through any
                  distribution channels. You acknowledge and confirm that we
                  have no obligation to review or pre-screen any User Content,
                  although we reserve the right in our sole and absolute
                  discretion to pre-screen, refuse or remove any User Content.
                  By entering into the Terms, you hereby provide your
                  irrevocable consent to such review and pre-screening of User
                  Content. In the event that we pre-screen, refuse or remove any
                  User Content, you acknowledge that we shall do so solely for
                  our benefit, and not yours. Without limiting the foregoing, we
                  shall have the right to remove any User Content that violates
                  the Terms, violates any applicable laws, regulations or codes,
                  or is otherwise objectionable.
                </li>
                <li className="py-3">
                  In uploading, posting, emailing, transmitting or otherwise
                  making available to us or on the OBX and/or the Site any User
                  Content, you agree not to:
                </li>
                <ol className="b">
                  <li className="py-3">
                    upload, post, link to, email or otherwise transmit:
                  </li>
                  <ol>
                    <li className="py-3">
                      any information that is unlawful in any way in Singapore
                      or in the country in which you reside;
                    </li>
                    <li className="py-3">
                      any information that you do not have a right to transmit;
                    </li>
                    <li className="py-3">
                      any unsolicited or unauthorised advertising, promotional
                      materials, "junk mail", "chain letters", "phishing
                      emails", "pyramid schemes", or any other form of
                      solicitation;
                    </li>
                    <li className="py-3">
                      any material that contains software viruses or any other
                      computer, files or programs designed to interrupt, destroy
                      or limit the functionality of any computer software or
                      hardware or telecommunications equipment;
                    </li>
                  </ol>
                  <li className="py-3">
                    impersonate any entity or person (whether living or dead).
                  </li>
                </ol>
                <li className="py-3">
                  Solely for the purpose of your convenience, we may utilise
                  hyperlinks to third party websites or otherwise make third
                  party information available on the OBX, the Site and/or the
                  Services. The use of such hyperlinks or inclusion of any third
                  party information shall not imply any associations between us,
                  the operator of such third party websites and any third party
                  or be deemed an endorsement or approval of such third party
                  website or information by us. The use and access of any third
                  party sites or information shall be done at your own risk.
                </li>
              </ol>
              <li className="py-3">
                <u>No legal or other assurance</u>
              </li>
              <text className="py-3">
                You hereby agree, confirm and acknowledge that:{" "}
              </text>
              <ol className="a">
                <li className="py-3">
                  the OBX, the Site and the Services is a portal that provides
                  users with general guidance and information for the
                  preparation of outcome-based contracts and tender documents
                  for the purchase of security services, and the provision of
                  the OBX, the Site and/or the Services by us shall not
                  constitute any legal, security or other advice, nor does it
                  constitute a practice of law or other professional
                  consultation;{" "}
                </li>
                <li className="py-3">
                  no general information or tools, services or platform (such as
                  the OBX, the Site and/or the Services we provide) can fit or
                  cater to every circumstance/requirement, and as the OBX, the
                  Site and the Services are general in nature, they may not
                  address your specific circumstances/requirements, may not
                  achieve any particular result or may not meet your
                  expectations;
                </li>
                <li className="py-3">
                  you should not act and you should refrain from acting based on
                  any OBX Materials (including without limitation any Tender
                  Documents) without first obtaining advice from professional
                  advisors qualified in the applicable subject matter and
                  jurisdiction and nothing contained on or generated by or
                  through the OBX, the Site and/or the Services (including
                  without limitation any Tender Documents and other OBX
                  Materials) shall be regarded as a substitute for advice from
                  qualified legal, security or other professionals. Without
                  limitation to the foregoing, you shall be solely responsible
                  for preparation, content, accuracy and review of any Tender
                  Documents to be used in connection with any Project;
                </li>
                <li className="py-3">
                  any information or document contained on or generated by the
                  OBX, the Site and/or the Services (including without
                  limitation the Tender Documents and other OBX Materials) may
                  not reflect the most recent developments in the law and/or
                  security industry, may not be complete or up to date, and may
                  not be accurate in or applicable to your jurisdiction, your
                  specifications and/or your project requirements;
                </li>
                <li className="py-3">
                  without limiting the foregoing, we do not represent or warrant
                  that:{" "}
                </li>
                <ol className="b">
                  <li className="py-3">
                    any information or document contained on or generated by the
                    OBX, the Site and/or the Services (including without
                    limitation any Tender Documents) are adequate or suitable
                    for the project for which you are seeking security or other
                    services or are reliable or free from inaccuracies, defects,
                    errors or omissions;
                  </li>
                  <li className="py-3">
                    the OBX, the Site, the Services and/or any OBX Materials
                    (including without limitation the Tender Documents) are free
                    from viruses, bugs or other harmful components;
                  </li>
                  <li className="py-3">
                    any information submitted or uploaded to us (whether via the
                    OBX and/or the Site, or otherwise), or correspondence
                    between us, will be free from interception, corruption,
                    error, delay, or loss; and/or
                  </li>
                  <li className="py-3">
                    access to or use of the OBX, the Site and/or the Services
                    will always be available or uninterrupted.
                  </li>
                </ol>
              </ol>
              <li className="py-3">
                <u>Disclaimer of Warranties</u>
              </li>
              <text>
                Without limiting Clause 5, you expressly agree and acknowledge
                that to the extent permitted under applicable laws, your use of
                the OBX, the Site and/or the Services (including without
                limitation any OBX Materials) shall be at your sole risk, and
                the OBX, the Site and/or the Services (including without
                limitation any OBX Materials) are provided to you on an “as is”
                and “as available” basis. On behalf of our partners, officers,
                employees, agents and representatives and ourselves
                (collectively, the “<strong>OBX Parties</strong>”), we expressly
                disclaim all warranties, representations and conditions of any
                kinds, whether express or implied, relating to the OBX, the Site
                and/or the Services (including without limitation any OBX
                Materials), including but not limited to warranties or
                conditions of merchantability, satisfactory quality,
                non-infringement, implied terms and fitness for any particular
                purpose.
              </text>
              <li className="py-3">
                <u>Limitation of Liability</u>
              </li>
              <text>
                The OBX Parties shall not, in any event, be liable for any
                damages resulting from the loss of use, data, revenue or
                profits, whether direct or indirect, or for any indirect,
                incidental, special, exemplary or consequential damages arising
                out of or in connection with the use of the OBX, the Site and/or
                the Services (including without limitation any OBX Materials),
                whether or not we have been advised of the possibility of such
                damages. The aggregate cumulative liability of us and/or our
                partners, officers, employees or agents in connection with any
                claim arising out of or relating to the OBX, the Site and/or the
                Services (including without limitation any OBX Materials) shall
                not exceed S$100/- or the Fees (whichever is higher), and such
                amount shall be in lieu of all other remedies which you may have
                against the OBX Parties.
              </text>
              <li className="py-3">
                <u>Indemnity</u>
              </li>
              <text>
                You agree to indemnify and hold us and other OBX Parties
                harmless from and against all liabilities, damages, claims,
                costs (including legal fees and costs), and expenses of any kind
                in connection with or arising from (i) your breach of these
                Terms, (ii) your use of the OBX, the Site and/or the Services;
                and/or (iii) any misrepresentation made by you. You also agree
                to fully co-operate with us in the defence or settlement of any
                claim in relation to or arising out of your use of the OBX, the
                Site and/or the Services or these Terms.
              </text>
              <li className="py-3">
                <u>Term and termination</u>
              </li>
              <text>
                These Terms shall remain in full force and effect while you use
                the OBX, the Site and/or the Services. Without limiting any
                other provision of these Terms, we reserve the right to, in our
                sole discretion and without notice or liability, deny access to
                and use of the OBX, the Site and/or the Services (including
                blocking certain IP addresses), to any person for any reason or
                for no reason, including without limitation for breach of any
                representation, warranty, or covenant contained in these Terms
                or for compliance with any applicable laws or regulations. We
                may terminate your use of the OBX, the Site and/or the Services
                or delete your Account and any User Content at any time, without
                warning notice or liability to you or any third party, in our
                sole discretion.
              </text>
              <li className="py-3">
                <u>Modifications and interruptions</u>
              </li>
              <ol className="a">
                <li className="py-3">
                  We reserve the right to change, modify, or remove the contents
                  of the OBX, the Site and/or the Services at any time or for
                  any reason at our sole discretion without notice. However, we
                  have no obligation to update any information on the OBX, the
                  Site and/or the Services. We also reserve the right to modify
                  or discontinue all or part of the OBX, the Site and/or the
                  Services without notice at any time. We will not be liable to
                  you or any third party for any modification, price change,
                  suspension, or discontinuance of the OBX, the Site and/or the
                  Services.
                </li>
                <li className="py-3">
                  We cannot guarantee and we do not warrant that the OBX, the
                  Site and/or the Services will be available at all times. We
                  may experience hardware, software, or other problems or need
                  to perform maintenance related to the OBX, the Site and/or the
                  Services, resulting in interruptions, delays, or errors. You
                  agree that we have no liability whatsoever for any loss,
                  damage, or inconvenience caused by your inability to access or
                  use the OBX, the Site and/or the Services during any downtime
                  or discontinuance of the OBX, the Site and/or the Services.
                  Nothing in these Terms will be construed to obligate us to
                  maintain and support the OBX, the Site and/or the Services or
                  to supply any corrections, updates, or releases in connection
                  therewith.
                </li>
              </ol>
              <li className="py-3">
                <u>Miscellaneous</u>
              </li>
              <ol className="a">
                <li className="py-3">
                  If any term, covenant, condition or provision of these Terms
                  is held by a court of competent jurisdiction to be invalid,
                  void or unenforceable, such provision is to be excluded to the
                  extent of such invalidity or unenforceability and all other
                  provisions will remain in full force and effect; and, to the
                  extent permitted and possible, the invalid or unenforceable
                  provision will be deemed replaced by a provision that is valid
                  and enforceable and that comes closest to expressing the
                  intention of such invalid or unenforceable provision.
                </li>
                <li className="py-3">
                  No failure or delay, on our part, in exercising any right or
                  power under these Terms will operate as a waiver of such right
                  or power.
                </li>
                <li className="py-3">
                  We reserve the right to assign, transfer, and subcontract our
                  rights and/or obligations under these Terms without any prior
                  notification or consent required. You shall not be permitted
                  to assign, transfer, or subcontract any of your rights and/or
                  obligations under these Terms.
                </li>
                <li className="py-3">
                  Save for the OBX Parties, a person who is not a party to the
                  Terms is not entitled to enforce any of the provisions of the
                  Terms pursuant to the Contracts (Rights of Third Parties) Act
                  2001 of Singapore.
                </li>
                <li className="py-3">
                  There is no joint venture, partnership, employment, or agency
                  relationship created between you and us as a result of these
                  Terms or use of the OBX, the Site and/or the Services.{" "}
                </li>
                <li className="py-3">
                  You agree that these Terms will not be construed against us by
                  virtue of having drafted them.{" "}
                </li>
                <li className="py-3">
                  You hereby waive any and all defences you may have based on
                  the electronic form of these Terms and the lack of signing by
                  the parties hereto to execute these Terms.
                </li>
                <li className="py-3">
                  The Terms shall be governed by and are to be construed in
                  accordance with the laws of Singapore. You hereby irrevocably
                  agree that the courts of Singapore shall have exclusive
                  jurisdiction to settle any dispute which may arise out of or
                  in connection with the Terms and that accordingly any
                  proceedings arising out of or in connection with the Terms
                  shall be brought in such courts. You agree to irrevocably
                  submit to the jurisdiction of such courts and waive any
                  objection to proceedings in any such court on the ground of
                  venue or on the ground that proceedings have been brought in
                  an inconvenient forum.
                </li>
              </ol>
              <li className="py-3">
                <u>Contact</u>
              </li>
              <text className="py-3">
                Any questions, comments and requests regarding these Terms
                should be addressed to{" "}
                <a href="mailto:admin@obx.sg">admin@obx.sg</a>.
              </text>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;

import { format, differenceInDays } from "date-fns";

import Constants from "../constants/constants";
// import party from "party-js";

const createTenderRules = {
  1: {
    maximumBidders: { required: true },
    facilityName: { required: true },
    facilityPostalCode: { required: true },
    streetAddress: { required: true },
    selectedServices: { required: true },
    selectedFacilityType: { required: true },
  },
  2: {
    selectedOutcomes: { required: true },
  },
  3: {
    selectedKpis: { required: true },
  },
  6: {
    phoneNumber: { required: false, type: "mobile" },
    email: { required: false, type: "email" },
  },
  7: {
    selectedTender: { required: true },
    publicationDate: { required: true },
    submissionDate: { required: true },
    // contractTerm: { required: true },
    // contractExtension: { required: true },
  },
};

class CommonUtils {
  // static partyFun = (e) => {
  //     party.confetti(e.target);
  // };

  static validateEmail = (value) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/;
    if (reg.test(value) === false) {
      return false;
    } else {
      return true;
    }
  };

  static isNumber = (number) => {
    if (!this.isNumeric(number)) {
      return false;
    }

    var numbers = /^[0-9]+$/;

    if (!numbers.test(number)) {
      return false;
    }

    return true;
  };

  static validateMobileNumber = (mobileNumber, validationDetails = {}) => {
    if (!this.isNumeric(mobileNumber)) {
      return false;
    }

    var numbers = /^[0-9]+$/;

    if (!mobileNumber.match(numbers)) {
      return false;
    }

    let maxLength = validationDetails.length;
    let minLength = validationDetails.minLength;

    if (!maxLength || mobileNumber.length == 0) {
      return true;
    } else if (
      minLength &&
      mobileNumber.length >= minLength &&
      mobileNumber.length <= maxLength
    ) {
      return true;
    } else if (mobileNumber.length == maxLength) {
      return true;
    } else {
      return false;
    }
  };

  static isNumeric = (str) => {
    return !isNaN(str);
  };

  static camelCase(str) {
    if (!str) return "";

    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index != 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  static datePipe(date, dateFormat = "dd/MM/yyyy") {
    if (!date) return "";
    return format(new Date(date), dateFormat);
  }

  static checkClosedStatus(status) {
    return (
      status === Constants.ORDER_STATUS.COMPLETED ||
      status === Constants.ORDER_STATUS.REJECTED ||
      status === Constants.ORDER_STATUS.PARTIAL_CLOSED ||
      status === Constants.ORDER_STATUS.CLOSED_BY_CUSTOMER ||
      status === Constants.ORDER_STATUS.CLOSED_BY_VENDOR
    );
  }

  static ellipsis(text, limit) {
    if (!text) return "";

    if (text.length < limit) return text;

    return text.substr(0, limit) + "...";
  }

  static formatAmount(value, n = 2) {
    if (!value) return 0;
    return +(+value).toFixed(n);
  }

  static formatAmountStringStrict(value, n = 2) {
    if (!value) return "0";
    return (+value).toFixed(n);
  }

  static getTimeZone() {
    return format(new Date(), "xxx");
  }

  static getTimeZoneString() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  static validatePassword(password) {
    // let spacialCharformat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    // let numberFormat = /\d/
    // let letterFormat = /[a-zA-Z]/g
    if (password.length >= Constants.PASSWORD_MIN_LENGTH) {
      // if (spacialCharformat.test(password) && numberFormat.test(password) && letterFormat.test(password)) {
      //     return true
      // }
      return true;
    }
    return false;
  }

  static calculatePercentage(discount, total) {
    if (discount == 0 || total == 0) return 0;

    return +((discount / 100) * total).toFixed(2);
  }

  static queryStringToJson(search) {
    if (!search) return {};
    search = search.substring(1);
    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );
  }

  static simpleTemplateEngine(tpl, data) {
    let re = /<%([^%>]+)?%>/g,
      match;
    while ((match = re.exec(tpl))) {
      // eslint-disable-next-line no-unused-expressions
      (re = /<%([^%>]+)?%>/g), match;
      tpl = tpl.replace(match[0], data[match[1]]);
    }
    return tpl;
  }

  static getStartAndEndDateOfMonth(date) {
    date = new Date(date);
    let startDate = new Date(date.getFullYear(), date.getMonth());

    let endDate = new Date(
      new Date(date.getFullYear(), date.getMonth() + 1).getTime() - 1
    );

    return { startDate, endDate };
  }

  static getStartDateOfMonth(todayDate) {
    let firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth());

    return firstDay;
  }

  static getEndDateOfMonth(todayDate) {
    let lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1);

    return lastDay;
  }

  static getDaysInMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  static getPreviousStartDateOfMonth(date) {
    date.setDate(0);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  }
  static getPreviousEndDateOfMonth(date) {
    date.setDate(0);
    date.setHours(23, 59, 59, 999);
    return date;
  }

  static getDateDiff(startDate, endDate) {
    return differenceInDays(new Date(endDate), new Date(startDate));
  }

  static cloneDeep(obj) {
    if (obj === null) return null;
    let clone = Object.assign({}, obj);
    Object.keys(clone).forEach((key) => {
      clone[key] =
        typeof obj[key] === "object"
          ? CommonUtils.cloneDeep(obj[key])
          : obj[key];
    });
    if (Array.isArray(obj)) {
      clone.length = obj.length;
      return Array.from(clone);
    }
    return clone;
  }

  static validateCreateTenderPayload = (payload, type) => {
    let rules = createTenderRules[type];

    if (rules) {
      for (let key in rules) {
        let rule = rules[key];
        if (rule?.required) {
          if (!payload || !payload[key] || !payload[key].toString().length || payload[key].toString().length === 0) {
            return {
              error: true,
              key,
            };
          }
        } else if (payload[key]) {
          if (rule?.type === "email") {
            if (!this.validateEmail(payload[key])) {
              return {
                error: true,
                key,
              };
            }
          } else if (rule?.type === "mobile") {
            if (!this.validateMobileNumber(payload[key], {})) {
              return {
                error: true,
                key,
              };
            }
          }
        }
      }
    }

    return {
      error: null,
    };
  };

  static validateBenchMark = (selectedKpis) => {
    for (let index = 0; index < selectedKpis.length; index++) {
      const element = selectedKpis[index];
      let previousItem = element.scale[0];
      for (let i = 1; i < element.scale.length; i++) {
        const currentItem = element.scale[i];
        if (+previousItem.low != +currentItem.high + 1) {
          return {
            success: false,
            errorKey: "high",
            errorIndex: index,
            errorSubIndex: i,
          };
        }
        if (+currentItem.high >= +currentItem.low) {
          return {
            success: false,
            errorKey: "low",
            errorIndex: index,
            errorSubIndex: i,
          };
        }
        previousItem = currentItem;
      }
    }

    return { success: true };
  };

  static ThousandSeparator(value, maxDigit = 8) {
    return value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: maxDigit });
  }
}

export default CommonUtils;

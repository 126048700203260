
const Constants = {
    INPUT_MAX_LENGTH: 500,
    INPUT_MAX_LENGTH_1: 250,
    INPUT_MAX_LENGTH_2: 12,
    INPUT_MAX_LENGTH_3: 4,
    INPUT_MAX_LENGTH_4: 7,
    PASSWORD_MIN_LENGTH: 8,

    KPI_VALUE: 4
};

export default Constants

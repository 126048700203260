import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import CommonUtils from "../../../../utility/common-utilities";
import "./kpi.scss";
import Form from "react-bootstrap/Form";
import TenderAPI from "../../../../apis/tender";
import KPIApi from "../../../../apis/kpi";
import Loader from "../../../common/loading/loading";

const ModifyKPI = (props) => {
  const [payload, setPayload] = useState({
    benchmarking: [{ title: "" }],
    dataCollection: [{ title: "" }],
    definition: [{ title: "" }],
    calculation: [{ title: "" }],
    notes: [{ title: "" }],
  });
  const [errorKey, setErrorKey] = useState("");
  const [errorIndex, setErrorIndex] = useState("");
  const [outcomes, setOutcomes] = useState([]);
  const [services, setServices] = useState([]);

  const [isLoading, setLoader] = useState(false);
  // When user creates custum kpi the value assined in outcome description and outcomeid  are not stored in outcome collection in mongodb
  const DummyData = {
    outcomeDescription: "User-defined",
    outcomeId: "User-defined",
  };

  useEffect(() => {
    console.log(props);
    if (props.mode === "EDIT") {
      let obj = {
        title: props.kpi?.description,
        failThreshold: props.kpi?.failThreshold,
        selectedOutcome: props.kpi?.outcomeId,
        benchmarking: props.kpi?.benchmarking?.map((item) => ({
          title: item?.title,
          key: Math.random,
        })),
        dataCollection: props.kpi?.dataCollection?.map((item) => ({
          title: item?.title,
          key: Math.random,
        })),
        definition: props.kpi?.definition?.map((item) => ({
          title: item?.title,
          key: Math.random,
        })),
        calculation: props.kpi?.calculation?.map((item) => ({
          title: item?.title,
          key: Math.random,
        })),
        notes: props.kpi?.notes?.map((item) => ({
          title: item?.title,
          key: Math.random,
        })),
      };
      setPayload(obj);
    }
    getServices();
  }, []);

  const getServices = (payload = {}) => {
    TenderAPI.getServices(payload)
      .then((response) => {
        setServices(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOutComes = (payload) => {
    TenderAPI.getOutComes(payload)
      .then((response) => {
        setOutcomes(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePayloadChange = (value, key) => {
    let payloadObj = { ...payload };
    payloadObj[key] = value;
    setPayloadObject(payloadObj);
  };

  const onSelectOutcome = (e) => {
    let value = e?.target?.value;
    let payloadObj = { ...payload };
    payloadObj.selectedOutcome = value;
    setPayloadObject(payloadObj);
  };
  const onSelectService = (e) => {
    let value = e?.target?.value;
    let payloadObj = { ...payload };
    payloadObj.selectedService = value;
    payloadObj.selectedOutcome = "";
    setPayloadObject(payloadObj);
    getOutComes({ type: value });
  };

  const setPayloadObject = (payloadObj) => {
    setPayload(payloadObj);
    setErrorKey("");
    props.emitChanges && props.emitChanges(payloadObj, "1");
  };

  const handleSubmit = () => {
    let obj = buildPayload();
    console.log(obj, ">>>>>>>>>>>>>>>>>>");
    let validation = validatePayload(obj);
    if (!validation) return;

    setLoader(true);

    if (props.mode === "EDIT") {
      obj.id = props.kpi._id;
      KPIApi.updateKpi(obj)
        .then((response) => {
          props.back(true, "Update");
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      KPIApi.createKpi(obj)
        .then((response) => {
          props.back(true, "Created");
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const removeBenchmark = (index) => {
    let payloadObj = { ...payload };
    if (payloadObj.benchmarking.length == 1)
      payloadObj.benchmarking[0].title = "";
    else payloadObj.benchmarking.splice(index, 1);
    setPayload(payloadObj);
  };

  const removeDataCollection = (index) => {
    let payloadObj = { ...payload };
    if (payloadObj.dataCollection.length == 1)
      payloadObj.dataCollection[0].title = "";
    else payloadObj.dataCollection.splice(index, 1);
    setPayload(payloadObj);
  };

  const handleBenchMarking = (value, index, key) => {
    let payloadObj = { ...payload };
    payloadObj.benchmarking[index] = { title: value };
    setPayload(payloadObj);
    setErrorKey("");
  };

  const handleDataCollection = (value, index, key) => {
    let payloadObj = { ...payload };
    payloadObj.dataCollection[index] = { title: value };
    setPayload(payloadObj);
    setErrorKey("");
  };
  const handleDefination = (value, index, key) => {
    let payloadObj = { ...payload };
    payloadObj.definition[index] = { title: value };
    setPayload(payloadObj);
    setErrorKey("");
  };

  const handleCalculation = (value, index, key) => {
    let payloadObj = { ...payload };
    payloadObj.calculation[index] = { title: value };
    setPayload(payloadObj);
    setErrorKey("");
  };

  const handleNotes = (value, index, key) => {
    let payloadObj = { ...payload };
    payloadObj.notes[index] = { title: value };
    setPayload(payloadObj);
    setErrorKey("");
  };

  const addBenchmark = () => {
    let payloadObj = { ...payload };
    payloadObj.benchmarking.push({ key: Math.random(), title: "" });
    setPayload(payloadObj);
  };

  const addDataCollection = () => {
    let payloadObj = { ...payload };
    payloadObj.dataCollection.push({ key: Math.random(), title: "" });
    setPayload(payloadObj);
  };

  const buildPayload = () => {
    let obj = {
      description: payload.title,
      failThreshold: payload.failThreshold,
      unit: payload.unit,
      benchmarking: payload.benchmarking?.map((i) => ({ title: i.title })),
      dataCollection: payload.dataCollection?.map((i) => ({ title: i.title })),
      calculation: payload.calculation,
      definition: payload.definition,
      notes: payload.notes,
    };
    if (props.mode === "EDIT") {
      obj.outcomeId = props?.kpi.outcomeId;
    } else {
      let selectedOutcome = outcomes.find(
        (i) => i._id == payload.selectedOutcome
      );
      if (selectedOutcome) {
        obj.outcomeId = selectedOutcome?._id;
        obj.outcomeDescription = selectedOutcome?.title;
      } else {
        obj.outcomeDescription = DummyData.outcomeDescription;
        obj.outcomeId = DummyData.outcomeId;
      }
    }

    return obj;
  };

  const validatePayload = (obj) => {
    if (!obj.description || !obj.description.trim()) {
      setErrorKey("title");
      return false;
    }

    if (!obj.failThreshold || obj.failThreshold <= 0) {
      setErrorKey("failThreshold");
      return false;
    }

    if (!obj.outcomeId) {
      setErrorKey("outcomes");
      return false;
    }

    // for (let i = 0; i < obj?.benchmarking?.length; i++) {
    //   if (!obj?.benchmarking[i].title || !obj?.benchmarking[i].title.trim()) {
    //     setErrorKey("benchmarking");
    //     setErrorIndex(i);
    //     return false;
    //   }
    // }

    // for (let i = 0; i < obj?.dataCollection?.length; i++) {
    //   if (
    //     !obj?.dataCollection[i].title ||
    //     !obj?.dataCollection[i].title?.trim()
    //   ) {
    //     setErrorKey("dataCollection");
    //     setErrorIndex(i);
    //     return false;
    //   }
    // }

    setErrorKey("");
    return true;
  };

  return (
    <div className="container py-4">
      <div className="container-fluid">
        {isLoading ? <Loader /> : ""}

        <div className="card sshadow-sm obx-blue p-4 mb-4">
          <div className="row">
            <div className="col obx-white">
              <h4>{props.mode == "EDIT" ? "Update" : "Create"} Kpi</h4>
            </div>
          </div>
        </div>

        <div className="card p-4 obx-full-container">
          <div className="row px-0 m-0">
            <div className="col px-0 m-0">
              <button onClick={props.back} className="btn btn-light">
                <i class="bi bi-arrow-left-short"></i>Back
              </button>
            </div>
          </div>
          <hr></hr>

          <div>
            <div className="pt-4">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <label htmlFor="formControlInput1" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errorKey == "title" ? "invalid-input" : ""
                      }`}
                      id="facilityControl"
                      value={payload?.title}
                      onChange={(e) =>
                        handlePayloadChange(e?.target?.value, "title")
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <label htmlFor="formControlInput2" className="form-label">
                      Value for Service Failure
                    </label>
                    <input
                      type="number"
                      className={`form-control ${
                        errorKey == "failThreshold" ? "invalid-input" : ""
                      }`}
                      id="postalCodeControl"
                      value={payload?.failThreshold}
                      onChange={(e) =>
                        handlePayloadChange(e?.target?.value, "failThreshold")
                      }
                    />
                  </div>
                </div>
                {props.mode != "EDIT" ? (
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="formControlInput3" className="form-label">
                        Services
                      </label>
                      <Form.Select
                        disabled={props.mode === "EDIT"}
                        value={payload?.selectedService}
                        onChange={onSelectService}
                        className={`form-control ${
                          errorKey == "services" ? "invalid-input" : ""
                        }`}
                        aria-label="Default select example"
                      >
                        <option>Select Service</option>
                        {services.map((item) => (
                          <option value={item.type}>{item.title}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <label htmlFor="formControlInput3" className="form-label">
                      Outcomes
                    </label>
                    {props.mode === "EDIT" ? (
                      <input
                        disabled
                        type="text"
                        className={`form-control`}
                        value={props?.kpi.outcomeDescription}
                      />
                    ) : (
                      <Form.Select
                        disabled={
                          props.mode === "EDIT" || !payload?.selectedService
                        }
                        value={payload?.selectedOutcome}
                        onChange={onSelectOutcome}
                        className={`form-control ${
                          errorKey == "outcomes" ? "invalid-input" : ""
                        }`}
                        aria-label="Default select example"
                      >
                        <option>Select Outcome</option>
                        {outcomes.map((item) => (
                          <option value={item._id}>{item.title}</option>
                        ))}
                      </Form.Select>
                    )}
                  </div>
                </div>
              </div>
              <div className="row pt-4">
                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <label htmlFor="formControlInput3" className="form-label">
                    Benchmarking
                  </label>
                  <div className="border-wrapper">
                    {payload?.benchmarking?.length
                      ? payload?.benchmarking?.map((item, index) => (
                          <div className="p-4 position-relative" key={item.key}>
                            <div
                              className="close-icon"
                              onClick={() => removeBenchmark(index)}
                            >
                              <FontAwesomeIcon icon={faClose} />
                            </div>
                            <div>
                              <input
                                type="text"
                                className={`form-control ${
                                  errorKey === "benchmarking" &&
                                  errorIndex === index
                                    ? "invalid-input"
                                    : ""
                                }`}
                                placeholder={`${index + 1}. Title`}
                                id="benchmarking"
                                value={item?.title}
                                onChange={(event) =>
                                  handleBenchMarking(
                                    event?.target.value,
                                    index,
                                    "title"
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                  <div className="text-center pt-4">
                    <button
                      onClick={addBenchmark}
                      type="button"
                      className="btn btn-sm btn-primary obx-btn-color"
                    >
                      + Add
                    </button>
                  </div>
                </div> */}
                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <label htmlFor="formControlInput3" className="form-label">
                    Data Collection
                  </label>
                  <div className="border-wrapper">
                    {payload?.dataCollection?.length
                      ? payload?.dataCollection?.map((item, index) => (
                          <div className="p-4 position-relative" key={item.key}>
                            <div
                              className="close-icon"
                              onClick={() => removeDataCollection(index)}
                            >
                              <FontAwesomeIcon icon={faClose} />
                            </div>
                            <div>
                              <input
                                type="text"
                                className={`form-control ${
                                  errorKey === "dataCollection" &&
                                  errorIndex === index
                                    ? "invalid-input"
                                    : ""
                                }`}
                                placeholder={`${index + 1}. Title`}
                                id="dataCollection"
                                value={item?.title}
                                onChange={(event) =>
                                  handleDataCollection(
                                    event?.target.value,
                                    index,
                                    "title"
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                  <div className="text-center pt-4">
                    <button
                      onClick={addDataCollection}
                      type="button"
                      className="btn btn-sm btn-primary obx-btn-color"
                    >
                      + Add
                    </button>
                  </div>
                </div> */}
                {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                  <label htmlFor="formControlInput3" className="form-label">
                    Definition
                  </label>
                  <div className="border-wrapper">
                    {payload?.definition?.length
                      ? payload?.definition?.map((item, index) => (
                          <div className="p-4 position-relative" key={item.key}>
                            <div>
                              <input
                                type="text"
                                className={`form-control`}
                                placeholder={`Title`}
                                id="defination"
                                value={item?.title}
                                onChange={(event) =>
                                  handleDefination(
                                    event?.target.value,
                                    index,
                                    "title"
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                </div> */}
                {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                  <label htmlFor="formControlInput3" className="form-label">
                    Calculation
                  </label>
                  <div className="border-wrapper">
                    {payload?.calculation?.length
                      ? payload?.calculation?.map((item, index) => (
                          <div className="p-4 position-relative" key={item.key}>
                            <div>
                              <input
                                type="text"
                                className={`form-control`}
                                placeholder={`Title`}
                                id="calculation"
                                value={item?.title}
                                onChange={(event) =>
                                  handleCalculation(
                                    event?.target.value,
                                    index,
                                    "title"
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                </div> */}
                {/* <div className="col-lg-6 col-md-6 col-sm-12 mt-3">
                  <label htmlFor="formControlInput3" className="form-label">
                    Notes
                  </label>
                  <div className="border-wrapper">
                    {payload?.notes?.length
                      ? payload?.notes?.map((item, index) => (
                          <div className="p-4 position-relative" key={item.key}>
                            <div>
                              <input
                                type="text"
                                className={`form-control`}
                                placeholder={`Title`}
                                id="notes"
                                value={item?.title}
                                onChange={(event) =>
                                  handleNotes(
                                    event?.target.value,
                                    index,
                                    "title"
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col">
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-md btn-primary obx-btn-color float-end"
              >
                {props.mode === "EDIT" ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifyKPI;

let ServerConfigs = {
    url: document.location.origin,
  // url: "http://obx.netscapelabs.com",
  //  url: "http://localhost:5000",
  //  url: "http://localhost:6000",
  timeout: 30,
};


export default ServerConfigs;

const ImageConstants = {
  OBX_LOGIN: "obx-login",
  FORGOT_PASSWORD: "forgot-password",
  NO_DATA: "no-data",
  AD: "ad",
  ALOGO: "alogo",
  USER: "user",
  LOCK: "lock",
  RIGHT_ARROW_BLUE: "right-arrow-blue",
  EYE_OPEN: "eye_open",
  EYE_CLOSE: "eye_close",

  CLOSE_RED: "close-red",
  CLOSE_GRAY: "close-gray",
  TICK_MARK: "tick-mark",
  LOGO_BLUE: "logo-blue",
  BANNER: "banner",
  OBX_INTRO: "obx_intro",
  OBX_CONTRACT: "obx_contract",
  INCENTIVE: "incentive",
  SECURITY: "security",
  TIME: "time",
  TRANSPARANCY: "transparancy",
  NOTEPAD: "notepad",
  TENDER: "tender",
  VENDOR: "vendor",
  GROUP: "group",
  AWARDED: "awarded",
  DOWNLOADWORD: "downloadword",
  RECOMMENDEDKPI: "recommendedkpi",
};

export default ImageConstants;

import React, { useEffect, useState } from 'react';
import './home.scss'


const VendorHome = (props) => {

    return (
        <div className="vendor-home">
            <div className="row px-0 m-0">
                <div className="col-md-6 col-lg-6 col-sm-12 pad-r">
                    <div className="card shadow-lg obx-vendor-card cursor-pointer" onClick={() => props.handler(1)}>
                        <div className="p-4">
                            <div className="title">Opportunities</div>
                            <div className="pt-2 ic"><i class="bi bi-graph-up-arrow"></i></div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-6 col-sm-12 pad-l">
                    <div className="card shadow-lg obx-vendor-card cursor-pointer" onClick={() => props.handler(2)}>
                        <div className="p-4">
                            <div className="title">Shortlisted Tenders</div>
                            <div className="pt-2 ic">
                                <i class={"bi bi-bookmark"}> </i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-6 col-sm-12 pad-r">
                    <div className="card shadow-lg obx-vendor-card cursor-pointer">
                        <div className="p-4">
                            <div className="title">Notification</div>
                            <div className="pt-2 ic">
                                <i class="bi bi-bell"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-6 col-sm-12 pad-l">
                    <div className="card shadow-lg obx-vendor-card cursor-pointer" onClick={() => props.handler(3)}>
                        <div className="p-4">
                            <div className="title">Submission summary</div>
                            <div className="pt-2 ic">
                                <i class="bi bi-file-earmark"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default VendorHome;

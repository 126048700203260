import React, { useEffect, useState } from "react";
import "./vendor-directory.scss";
import { getImageSource } from "../../utility/image-source";
import ImageConstants from "../../constants/image-constants";
import TenderAPI from "../../apis/tender";
import Pagination from "../common/pagination/pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocation,
  faCog,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import NoRecords from "../no-records/no-records";
import Loader from "../common/loading/loading";

const VendorDirectory = (props) => {
  const [companyName, setCompany] = useState("");
  const [vendors, setVendors] = useState([]);
  const [pageObject, setPageObj] = useState({ currentPage: 1, startIndex: 1 });
  const [isLoading, setLoader] = useState(false);
  const [reset, setReset] = useState(false)
  const [OBCReady, setOBCReady] = useState("")
  const [plrd, setPlrd] = useState([])
  let electives = [
    {id: 1, elective_desc: "Continuous Professional Development" },
    {id: 2, elective_desc: "Simulation Training" },
    {id: 3, elective_desc: "Job Redesign" },
    {id: 4, elective_desc: "Business Continuity Plan" },
    {id: 5, elective_desc: "Security Checks" },
    {id: 6, elective_desc: "Standard Operating Procedures (SOPs)" },
    {id: 7, elective_desc: "Service Buyer Management Process" },
    {id: 8, elective_desc: "Supervision" },
    {id: 9, elective_desc: "Body Worn Cameras / Wireless Communications" },
    {id: 10, elective_desc: "Video Analytics" },
    {id: 11, elective_desc: "Remote Guarding" },
    {id: 12, elective_desc: "Technology Enabled Patrol" },
  ]

  useEffect(() => {
    getVendors();
  }, []);
  
  useEffect(() => {
    if(reset) {
      getVendors();
      setReset(false)
    }
  }, [reset]);

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const handleResetFilter = () => {
    setCompany("");
    setOBCReady("");
    setPlrd([]);
    setReset(true);
  };

  const onApplyFilter = () => {
    getVendors({ page: 1 });
  };

  const getVendors = (payload = {}) => {
    if (companyName) payload.n = companyName;
    if (OBCReady !== '') payload.obc_ready = OBCReady;
    if (plrd.length > 0) payload.plrd = encodeURIComponent(JSON.stringify(plrd));

    if (!payload.page) payload.page = pageObject.currentPage || 1;

    pageObject.currentPage = payload.page;

    setLoader(true);
    TenderAPI.getVendors(payload)
      .then((response) => {
        let data = response.data;
        setVendors(data || []);
        setPageObj(response.pagination);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onPageSelected = (selectedPage, startIndex) => {
    pageObject.currentPage = selectedPage;
    pageObject.startIndex = startIndex;
    setPageObj(pageObject);
    getVendors({ page: selectedPage });
  };

  return (
    <>
      <div className="container vendor-directory py-4">
        <div className="container-fluid">
          {isLoading ? <Loader /> : ""}
          <div className="card sshadow-sm obx-blue p-4 mb-4">
            <div className="row">
              <div className="col obx-white header_title_head">
                <h4>Vendor Directory</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 pt-4">
              <div className="shadow rounded p-3 ">
                <div className="row">
                  <div className="col">
                    <span className="h6">Search Filter</span>
                  </div>
                  <div className="col">
                    <button
                      onClick={handleResetFilter}
                      type="button"
                      className="btn btn-sm btn-light float-end"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <hr></hr>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Company Name"
                    onChange={handleCompanyChange}
                    value={companyName}
                  ></input>
                  <label htmlFor="floatingInput">Company Name</label>
                </div>

                <div 
                  className="obc-ready mt-3" 
                  style={{
                    border: "1px solid #dee2e6", 
                    borderRadius: "0.375rem", 
                    padding: ".375rem 2.25rem .375rem .75rem"
                  }}
                >
                  <p className="fw-bold mb-2">OBC Ready</p>
                  <div>
                    <div class="form-check form-check-inline" onChange={() => setOBCReady(true)}>
                      <input class="form-check-input" type="radio" name="obc_ready_options" id="obcYes" value="yes" required checked={OBCReady === true}/>
                      <label class="form-check-label" for="obcYes">Yes</label>
                    </div>
                    <div class="form-check form-check-inline"  onChange={() => setOBCReady(false)}>
                      <input class="form-check-input" type="radio" name="obc_ready_options" id="obcNo" value="no" required checked={OBCReady === false} />
                      <label class="form-check-label" for="obcNo">No</label>
                    </div>
                  </div>
                </div>

                <div 
                  className="obc-ready mt-3 mb-3" 
                  style={{
                    border: "1px solid #dee2e6", 
                    borderRadius: "0.375rem", 
                    padding: ".375rem 2.25rem .375rem .75rem"
                  }}
                >
                  <p className="fw-bold mb-2">PLRD Electives</p>
                  {electives.map((el,i) => 
                  <div key={i} class="form-check mb-1" onChange={() => {
                    if (plrd.map((v) => v.id).includes(el.id)) {
                      setPlrd(plrd.filter((item) => item.id !== el.id));
                    } else {
                      setPlrd([...plrd, el]);
                    }
                  }}>
                    <input 
                      class="form-check-input" 
                      type="checkbox"
                      value={el.id}
                      id={"elective_check"+i+el.id}
                      checked={plrd.map((v) => v.id).includes(el.id)}
                    />
                    <label class="form-check-label" for={"elective_check"+i+el.id}>
                      {el.elective_desc}
                    </label>
                  </div>
                  )}
                </div>

                <div>
                  <button
                    onClick={onApplyFilter}
                    className={`w-100 btn btn-block btn-dark align-center vendor-button`}
                  >
                    {`Apply Filter`}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-9  pt-4">
              <div className="row">
                {vendors?.length ? (
                  vendors.map((vendor) => (
                    <div className="col-6 mb-3">
                      <div className="shadow rounded p-3">
                        <div className="row">
                          <div className="col-6">
                            <img
                              className="a-logo"
                              alt=""
                              src={getImageSource(ImageConstants.USER)}
                            />
                          </div>
                          {/* <div className="col-6">
                            <button type="button" className="btn btn-md btn-outline  float-end" > <div className="d-flex align-items-center"> <FontAwesomeIcon icon={faMessage} /> &nbsp;<span>Chat </span></div></button>
                          </div> */}
                        </div>

                        <div className="pt-2">
                          {" "}
                          <span className="h5">{vendor.company}</span>
                        </div>
                        <div className="pt-2 obx-text-dgray d-flex align-items-center">
                          <FontAwesomeIcon icon={faEnvelope} />
                          &nbsp;&nbsp;{" "}
                          <span className="font-10">{vendor.email}</span>
                        </div>
                        <div className="pt-2 obx-text-dgray d-flex align-items-center">
                          <FontAwesomeIcon icon={faLocation} />
                          &nbsp;&nbsp;{" "}
                          <span className="font-10">{vendor.address}</span>
                        </div>
                        {/* <div className="pt-2 obx-text-dgray d-flex align-items-center"><FontAwesomeIcon icon={faCog} />&nbsp;&nbsp; <span className="font-10">Document Preparation, Bidding, Dealing Contracts, Financial Dealings, Other services</span></div> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <NoRecords />
                )}
              </div>
            </div>
          </div>
        </div>
        <Pagination
          startIndex={pageObject?.startIndex || 1}
          selectedPage={pageObject?.currentPage || 1}
          totalPages={pageObject?.totalPages || 0}
          onPageSelected={onPageSelected.bind(this)}
        />
      </div>
    </>
  );
};

export default VendorDirectory;

import { useEffect, useRef, useState } from "react";

import { Container, Popover, OverlayTrigger } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import SessionCache from "../routes/session-cache";
import SharedStore from "../../service/shared-store";
import { Link, Redirect } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSmile,
  faSignOut,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";

import Path from "../routes/routes-path";
import AuthenticateAPI from "../../apis/authenticate";

import { getImageSource } from "../../utility/image-source";
import ImageConstants from "../../constants/image-constants";

const Header = (props) => {
  const [refresh, setRefresh] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [isAuthendicated, setIsAuthendicated] = useState(false);
  const [hideNameIcon, setHideNameIcon] = useState(false);
  const [name, setName] = useState("");
  const [imageUrl, setimageUrl] = useState();

  let popOverRef = null;

  let user = SessionCache.getUser();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickEvent, false);
    let currentPath = window.location.pathname;
    setSelectedMenu(currentPath);
    isAuthenticatedApi();
  }, []);

  const handleClickEvent = (event) => {
    if (popOverRef?.current && !popOverRef?.current?.contains(event.target)) {
      setShowPopover(false);
    }
  };

  const isAuthenticatedApi = () => {
    AuthenticateAPI.isLogin({})
      .then((response) => {
        let isAuthendicated = response?.isAuthorized;
        setName(response?.name);
        setimageUrl(response?.profile);
        setIsAuthendicated(isAuthendicated);
      })
      .catch((err) => {
        setIsAuthendicated(false);
      });
  };

  const getUserNameLetter = () => {
    let userName = user?.name || name;
    return userName ? userName[0] : "";
  };

  const refreshHeader = () => {
    setRefresh(!refresh);
  };

  SharedStore.setStore("refreshHeader", refreshHeader);

  const onClickLogout = () => {
    AuthenticateAPI.logout({})
      .then((response) => {
        window.localStorage.removeItem("user");
        window.location.href = Path.LOGIN;
      })
      .catch((err) => {});
  };

  const refreshNameIcon = () => {
    setHideNameIcon(true);
    setTimeout(() => {
      setHideNameIcon(false);
    }, 50);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className="pop-min-width">
          <div className="">
            <FontAwesomeIcon icon={faSmile} />{" "}
            <span className="px-2">{name}</span>
          </div>

          <Link
            to={Path.PROFILE}
            className="remove-text-decoration text-black"
            onClick={() => refreshNameIcon()}
          >
            <div className="pt-4 cursor-pointer">
              <FontAwesomeIcon icon={faUser} />{" "}
              <span className="px-2">Profile</span>
            </div>
          </Link>

          <Link
            to={
              SessionCache.getUserType() == "VENDOR"
                ? Path.VENDORTRANSACTION
                : Path.BUYERTRANSACTION
            }
            className="remove-text-decoration text-black"
            onClick={() => refreshNameIcon()}
          >
            <div className="pt-4 cursor-pointer">
              <FontAwesomeIcon icon={faMoneyBillTransfer} />
              <span className="px-2">
                {" "}
                <FontAwesomeIcon icon="fa-solid fa-money-bill-transfer" />
                Transactions
              </span>
            </div>
          </Link>

          <div
            className="pt-4 cursor-pointer text-danger"
            onClick={onClickLogout}
          >
            <FontAwesomeIcon icon={faSignOut} />{" "}
            <span className="px-2">Logout</span>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  const isSelected = (path) => {
    return path === selectedMenu;
  };

  return (
    <div className="header-splitter">
      <Container>
        <Navbar bg="" expand="lg">
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                alt=""
                className="logo"
                src={getImageSource(ImageConstants.LOGO_BLUE)}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "10 ̰0px" }}
                navbarScroll
              >
                <Nav.Link>
                  {" "}
                  <Link
                    to={
                      SessionCache.getUserType() === "VENDOR"
                        ? Path.DASHBOARD
                        : SessionCache.getUserType() === "BUYER"
                        ? Path.BUYER_DASHBOARD
                        : Path.BASE
                    }
                    onClick={() =>
                      setSelectedMenu(
                        SessionCache.getUserType() === "VENDOR"
                          ? Path.DASHBOARD
                          : SessionCache.getUserType() === "BUYER"
                          ? Path.BUYER_DASHBOARD
                          : Path.BASE
                      )
                    }
                    className={`nav-link ${
                      isSelected(Path.BUYER_DASHBOARD) ||
                      isSelected(Path.DASHBOARD) ||
                      isSelected(Path.BASE)
                        ? "selected-nav"
                        : ""
                    }`}
                  >
                    Home{" "}
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to={Path.OPPORTUNITIES}
                    onClick={() => setSelectedMenu(Path.OPPORTUNITIES)}
                    className={`nav-link ${
                      isSelected(Path.OPPORTUNITIES) ? "selected-nav" : ""
                    }`}
                  >
                    Opportunities
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to={Path.VENDOR_DIRECTORY}
                    onClick={() => setSelectedMenu(Path.VENDOR_DIRECTORY)}
                    className={`nav-link ${
                      isSelected(Path.VENDOR_DIRECTORY) ? "selected-nav" : ""
                    }`}
                  >
                    Vendor Directory
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to={Path.ANNOUNCEMENTS}
                    onClick={() => setSelectedMenu(Path.ANNOUNCEMENTS)}
                    className={`nav-link ${
                      isSelected(Path.ANNOUNCEMENTS) ? "selected-nav" : ""
                    }`}
                  >
                    Announcements
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to={Path.FAQ}
                    onClick={() => setSelectedMenu(Path.FAQ)}
                    className={`nav-link ${
                      isSelected(Path.FAQ) ? "selected-nav" : ""
                    }`}
                  >
                    FAQ
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    to={Path.CONTACT}
                    onClick={() => setSelectedMenu(Path.CONTACT)}
                    className={`nav-link ${
                      isSelected(Path.CONTACT) ? "selected-nav" : ""
                    }`}
                  >
                    Contact Us
                  </Link>
                </Nav.Link>
              </Nav>
              {!isAuthendicated ? (
                <Form className="d-flex">
                  <Link to="/login">
                    <Button variant="dark m-1">Login</Button>
                  </Link>
                  <Link to="/register">
                    <Button variant="dark m-1">Register</Button>
                  </Link>
                </Form>
              ) : !hideNameIcon ? (
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  placement="auto"
                  overlay={popover}
                >
                  <div className="user-circle ml-4 cursor-pointer">
                    {!imageUrl ? (
                      <span className="user-name-new">
                        {getUserNameLetter()}
                      </span>
                    ) : (
                      <>
                        <span>
                          <img
                            style={{
                              borderRadius: "50%",
                              height: "50px",
                              width: "50px",
                            }}
                            src={imageUrl}
                          />
                        </span>
                      </>
                    )}
                    {/* <span className='user-name-new'>{getUserNameLetter()}</span> */}
                  </div>
                </OverlayTrigger>
              ) : (
                ""
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
    </div>
  );
};

export default Header;

import { auto } from "@popperjs/core";
import { useEffect, useState } from "react";
import CommonUtils from "../../utility/common-utilities";
// import "../../../styles/fonts.scss";
import "./upload.scss";
import Accordion from "react-bootstrap/Accordion";
import SecurityServices from "../../constants/securityservicelogo";
import BuildingParticular from "./building-particular";
import PreviewTender from "../create-tender/preview-tender/preview-tender";
import Loader from "../common/loading/loading";
import TenderAPI from "../../apis/tender";
import KpiComponent from "../layout/profile/kpi/kpi";
import SiteDetails from "../create-tender/site-details/site-details";


const LeftPanel = (props) => {
  let handler = props.handleClickEvent;
  let position = props.position;
  const [stage, setStage] = useState(position);

  const handleSelection = (position) => {
    let stagePayload = props.stage[stage] || {};
    debugger;

    if (stage < position) {
      if (stage === 4) {
        let result = CommonUtils.validateBenchMark(stagePayload);
        if (!result.success) return;
      } else {
        let validation = CommonUtils.validateCreateTenderPayload(
          stagePayload,
          stage
        );
        if (validation?.error) return;
      }
    }

    setStage(position);
    handler(position);
  };

  return (
    <div className="shadow rounded p-3">
      <div className="row">
        <div className="col create-tender col create-tender header_title_paragraph">
          <div
            onClick={() => {
              handleSelection(1);
            }}
            className={
              "obx-btn obx-bb p-3 " +
              (stage == 1 ? "obx-header-btn-selected" : "")
            }
          >
            {" "}
            1. Building Particulars
          </div>
        </div>
      </div>
    </div>
  );
};

const RightPanel = (props) => {
  let handler = props.handleNavigation;
  let position = props.position;

  const handleNext = (event) => {
    console.log("right panel = ", position + 1);
    handler(position + 1);
  };

  const handleBack = (event) => {
    handler(position - 1);
  };

  return (
    <div className="shadow rounded p-3">
      {position == 1 && (
        <BuildingParticular
          services={props.services}
          payload={props.stage["1"]}
          emitChanges={props.emitChanges}
          onPublishTender={props.onPublish}
          next={handleNext}
        />
      )}
    </div>
  );
};

function UploadTender() {
  const [selected, setSelected] = useState(1);
  const [isLoading, setLoader] = useState(false);
  const [services, setServices] = useState([]);
  const [outcomes, setOutcomes] = useState([]);
  const [kpis, setKpis] = useState([]);
  const [stageValues, setStageValues] = useState({});
  const [previewURL, setPreviewURL] = useState("");
  const [tenderSignedURL, setTenderSignedURL] = useState("");
  const [tenderNumber, setTenderNumber] = useState();
  const [isTenderPublished, setTenderPublished] = useState(false);
  const [isShowManageKpi, setShowManageKpi] = useState(false);

  let outcomeHash = {},
    kpisHash = {};

  useEffect(() => {
    getServices();
  }, []);

  const handler = (position) => {
    setSelected(position);
  };

  const handleNavigation = (position) => {
    console.log("handle navigation", position);
    setSelected(position);
  };

  const getServices = () => {
    TenderAPI.getServices({})
      .then((response) => {
        setServices(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOutComes = (selectedServices) => {
    if (outcomeHash[selectedServices]) {
      setOutcomes(outcomeHash[selectedServices]);
      return;
    }

    selectedServices = selectedServices?.join(",");

    TenderAPI.getOutComes({ type: selectedServices })
      .then((response) => {
        setOutcomes(response.data);

        outcomeHash[selectedServices] = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getKpis = (selectedOutcomes) => {
    if (kpisHash[selectedOutcomes]) {
      setKpis(kpisHash[selectedOutcomes]);
      return;
    }

    selectedOutcomes = selectedOutcomes?.join(",");

    TenderAPI.getKpis({ oids: selectedOutcomes })
      .then((response) => {
        setKpis(response);
        kpisHash[selectedOutcomes] = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStageChanges = (values, key) => {
    let valuesOfStage = { ...stageValues };
    valuesOfStage[key] = values;
    setStageValues(valuesOfStage);

    if (key == 1 && values?.selectedServices?.length) {
      getOutComes(values?.selectedServices.map((item) => item?.type));
    }

    if (key == 2 && values?.selectedOutcomes?.length) {
      getKpis(values?.selectedOutcomes || []);
      valuesOfStage[3] = [];
      setStageValues(valuesOfStage);
    }
  };

  const onSubmit = () => {
    let validation = CommonUtils.validateCreateTenderPayload(stageValues[1], 1);
    if (validation.error) {
      setSelected(1);
      return;
    }
   
    let payload = {
      facilityName: stageValues[1].facilityName,
      facilityPostalCode: stageValues[1].facilityPostalCode,
      streetAddress: stageValues[1].streetAddress,
      facilityType: stageValues[1].selectedFacilityType,
      selectedServices: stageValues[1].selectedServices,
      // selectedOutcomes: stageValues[2].selectedOutcomes.map((item) => ({
      //   id: item,
      // })),
      // SelectedOutcomesInfo: stageValues[2].SelectedOutcomesInfo,
      // kpis: {
      //   selectedKpis: stageValues[4].map((item) => {
      //     return {
      //       id: item._id,
      //       thresholdType: "NUMBER",
      //       failThreshold: item.failThreshold,
      //       scale: item.scale,
      //       description: item.description,
      //     };
      //   }),

      //   renumeration: stageValues[5],
      // },
      // siteDetails: stageValues[6],
      // terms: stageValues[7],
      // // questioneries: stageValues[9],
    };
    setLoader(true);
    console.log(payload);
    TenderAPI.createTenderByCustom(payload)
      .then((response) => {
        let url = response?.data.url;
        setStageValues({});
        setTenderNumber(response?.data?.tenderNumber);
        getPreviewUrl(url);
        setTenderSignedURL(url);
      })
      .catch((err) => {
        console.log(err.message);
        setLoader(false);
      });
  };

  const getPreviewUrl = (url) => {
    TenderAPI.getPreviewUrl({ url })
      .then((response) => {
        let url = response?.data.previewUrl;
        setPreviewURL(url);
        setSelected(8);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const getEditUrl = (url) => {
    setLoader(true);
    TenderAPI.getEditUrl({ url: tenderSignedURL, tid: tenderNumber })
      .then((response) => {
        let url = response?.data.editUrl;
        setPreviewURL(url);
        setSelected(8);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const onPublishTender = (url) => {
   const tenderNumber=  window.localStorage.getItem("tenderNumber")
    setLoader(true);
    TenderAPI.publishTender({ tenderNumber })
      .then((response) => {
        window.localStorage.removeItem("tenderNumber")
        console.log(response.data, "console 3");
        window.location.href = response.data.paymentIntent;
        // props.history.push(Path.BASE)
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleShowManageKpi = () => {
    setShowManageKpi(true);
  };

  const handleKpiBack = () => {
    getKpis(stageValues[2]?.selectedOutcomes || []);
    setShowManageKpi(false);
  };

  if (isShowManageKpi) return <KpiComponent back={handleKpiBack} />;

  return (
    <div className="container py-4">
    <div className="container-fluid">
      {isLoading ? <Loader /> : ""}
      <div className="card sshadow-sm obx-blue p-4 mb-4">
        <div className="row">
          <div className="col obx-white">
            <h4>Create Tender</h4>
          </div>
        </div>
      </div>
      <div className="create-tender">
        {selected === 8 && previewURL ? (
          <PreviewTender
            previewURL={previewURL}
            onEditDocument={getEditUrl}
            onPublish={onPublishTender}
            isTenderPublished={isTenderPublished}
          />
        ) : (
          <div className="row ">
            {/* <div className="col-lg-3">
              <LeftPanel
                stage={stageValues}
                key={selected}
                handleClickEvent={handler}
                position={selected}
              />
            </div> */}
            <div className="col-lg-12">
              <RightPanel
                services={services}
                outcomes={outcomes}
                kpis={kpis}
                key={selected + "12"}
                previewURL={previewURL}
                tenderNumber={tenderNumber}
                position={selected}
                handleNavigation={handleNavigation}
                stage={stageValues}
                emitChanges={handleStageChanges}
                onSubmit={onSubmit}
                onEditDocument={getEditUrl}
                onPublish={onPublishTender}
                isTenderPublished={isTenderPublished}
                showManageKpi={handleShowManageKpi}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
  )
}

export default UploadTender
import React, { useState } from 'react';
import './counter-component.scss';

function CounterView({ leftButtonText, onLeftButtonClick, rightButtonText, onRightButtonClick, inputValue, onInputChange }) {

  let [value, setValue] = useState(inputValue)

  const onChangeHandler = (value) => {
    if (value < 0) return
    setValue(+value)
    onInputChange(+value)
  }

  return (
    <div className="cc-container">
      <button className="left-button" onClick={() => onChangeHandler(Number(value || 0) - 1)}>{leftButtonText}</button>
      <input className="center-input" value={value} onChange={(e) => onChangeHandler(e.target?.value)} />
      <button className="right-button" onClick={() => onChangeHandler(Number(value || 0) + 1)}>{rightButtonText}</button>
    </div>
  );
}

export default CounterView;

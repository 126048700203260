import ImageConstants from "../constants/image-constants";

export function getImageSource(image) {
  let imageSrc;

  switch (image) {
    case ImageConstants.OBX_LOGIN:
      imageSrc = require("../assets/images/obx.jpeg");
      break;

    case ImageConstants.FORGOT_PASSWORD:
      imageSrc = require("../assets/images/forgot-password.jpg");
      break;

    case ImageConstants.NO_DATA:
      imageSrc = require("../assets/images/no-data.jpg");
      break;

    case ImageConstants.ALOGO:
      imageSrc = require("../assets/images/alogo.png");
      break;

    case ImageConstants.USER:
      imageSrc = require("../assets/images/user.jpg");
      break;

    case ImageConstants.LOCK:
      imageSrc = require("../assets/images/lock.png");
      break;

    case ImageConstants.RIGHT_ARROW_BLUE:
      imageSrc = require("../assets/images/arrow-right-blue.png");
      break;

    case ImageConstants.EYE_OPEN:
      imageSrc = require("../assets/images/eye-open.png");
      break;

    case ImageConstants.EYE_CLOSE:
      imageSrc = require("../assets/images/eye-close.png");
      break;

    case ImageConstants.CLOSE_RED:
      imageSrc = require("../assets/images/close-red.png");
      break;

    case ImageConstants.CLOSE_GRAY:
      imageSrc = require("../assets/images/close-gray.png");
      break;

    case ImageConstants.TICK_MARK:
      imageSrc = require("../assets/images/tick-mark.png");
      break;

    case ImageConstants.LOGO_BLUE:
      imageSrc = require("../assets/images/obx-dark-logo.png");
      break;

    case ImageConstants.OBX_INTRO:
      imageSrc = require("../assets/images/obx_intro.gif");
      break;

    case ImageConstants.BANNER:
      imageSrc = require("../assets/images/banner.png");
      break;

    case ImageConstants.OBX_CONTRACT:
      imageSrc = require("../assets/images/obx_contract.gif");
      break;

    case ImageConstants.TRANSPARANCY:
      imageSrc = require("../assets/images/transparancy.png");
      break;

    case ImageConstants.SECURITY:
      imageSrc = require("../assets/images/security.png");
      break;

    case ImageConstants.TIME:
      imageSrc = require("../assets/images/time.png");
      break;

    case ImageConstants.INCENTIVE:
      imageSrc = require("../assets/images/incentive.png");
      break;

    case ImageConstants.GROUP:
      imageSrc = require("../assets/images/group.png");
      break;

    case ImageConstants.TENDER:
      imageSrc = require("../assets/images/tender.png");
      break;

    case ImageConstants.VENDOR:
      imageSrc = require("../assets/images/vendor.png");
      break;

    case ImageConstants.NOTEPAD:
      imageSrc = require("../assets/images/notepad.png");
      break;

    case ImageConstants.AWARDED:
      imageSrc = require("../assets/images/AWA.png");
      break;
    case ImageConstants.DOWNLOADWORD:
      imageSrc = require("../assets/images/downloadword.png");
      break;
    case ImageConstants.RECOMMENDEDKPI:
      imageSrc = require("../assets/images/recommendedkpi.png");
  }
  return imageSrc;
}

import React, { useEffect, useState, forwardRef } from 'react';
import CommonUtils from '../../../utility/common-utilities';
import TenderDetails from '../tender-detail/tender-detail';
import './active-bids.scss'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { getImageSource } from '../../../utility/image-source';
import ImageConstants from '../../../constants/image-constants';
import TenderAPI from '../../../apis/tender';
import Pagination from '../../common/pagination/pagination';
import { Table } from 'react-bootstrap';
import CreateBid from '../submit-bid/submit-bid';
import SessionCache from '../../routes/session-cache';
import NoRecords from '../../no-records/no-records';

const ActiveBids = (props) => {

  const [filter, setFilter] = useState({});
  const [isViewTender, setViewTender] = useState(false);
  const [isViewBidDetail, setViewTenderBidDetail] = useState(false);
  const [selectedTender, setSelectedTender] = useState(null);
  const [tenders, setTenders] = useState([])
  const [pageObject, setPageObject] = useState({})

  const [isLoading, setLoading] = useState(true);


  useEffect(() => {
    getTenderList()
  }, [])


  const onApplyFilter = () => {
    getTenderList({ page: 1, f: filter.startDate, t: filter.endDate })
  }

  const onViewTender = (tender) => {
    setViewTender(true)
    setSelectedTender(tender)
  }

  const onViewTenderBidDetail = (event, tender) => {
    event.stopPropagation()
    setViewTenderBidDetail(true)
    setSelectedTender(tender)
  }

  const getTenderList = (payload = {}) => {

    if (!payload.page) payload.page = pageObject.currentPage || 1
    if (props.tender?.tenderNumber) payload.tid = props.tender?.tenderNumber

    TenderAPI.getIttTenderSubmissionList(payload).then((response) => {
      let data = response.data
      setPageObject(response?.pagination || {})
      setTenders(data)
      setLoading(false)
    }).catch((err) => {
      setLoading(false)

    })
  }

  const onPageSelected = (selectedPage, startIndex) => {
    pageObject.currentPage = selectedPage
    pageObject.startIndex = startIndex
    setPageObject(pageObject)

    getTenderList({ page: selectedPage })

  }

  const handleBack = (forceFetch) => {
    setViewTender(false)
    setViewTenderBidDetail(false)

    if (forceFetch) getTenderList()
  }

  const CustomDatePicker = forwardRef(({ value, onClick }, ref) => (
    <button className="obx-date-picker-btn" onClick={onClick} ref={ref}>
      <div style={{ textAlign: "initial" }}> {value}</div>
    </button>
  ));


  const handleFilterChange = (value, key) => {
    let payloadObj = { ...filter }
    payloadObj[key] = value
    setFilter(payloadObj)
  }

  const getClassForStatus = (status) => {

    switch (status) {

      case "NOT AWARDED": return "denied"
      default: return status.toLowerCase()
    }
  }

  const getStatusText = (status) => {

    switch (status) {
      case "NOT AWARDED": return "Not Awarded"
    }
    return CommonUtils.camelCase(status.toLowerCase())
  }


  if (isViewBidDetail) return <CreateBid isAward={props.isAward} isViewOnly tender={selectedTender} showEdit={true} back={handleBack} />

  return (

    <>


      <div className="active-bids">
        <div className="pb-4">
          {
            isViewTender ? <TenderDetails isAward={props.isAward} isForceFetch={true} tender={selectedTender} showEdit={true} back={handleBack} /> :
              <div className="">
                {
                  props.showBack &&
                  <>
                    <div className='row'>
                      <div className='col'>
                        <button onClick={props.back} className='btn btn-light'><i class="bi bi-arrow-left-short"></i>Back</button>
                      </div>
                    </div>
                    <hr />
                  </>
                }

                {
                  tenders?.length ?
                    <div>
                      <div className="table-responsive">
                        <table class="table table-borderless">
                          <thead>
                            <tr>
                              <th className={`text-bg-light align-middle text-center mw-2`}>S/N</th>
                              <th className={`text-bg-light align-middle`}>Tender ID</th>
                              <th className={`text-bg-light align-middle`}>Date of Submission</th>
                              <th className={`text-bg-light align-middle`}>{SessionCache.getUserType() === "VENDOR" ? "Buyer / Tenderee" : "Vendor"}</th>
                              <th className={`text-bg-light align-middle text-center`}>Status</th>
                              <th className={`text-bg-light text-center align-middle`}>View Submission</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              tenders.map((tender, index) => (
                                <tr className="cursor-pointer position-relative" style={{ height: "auto" }} onClick={(e) => onViewTender(tender)}>
                                  <td className="text-center">

                                    <div className="">{index + 1} </div></td>
                                  <td className="">{tender.tenderNumber}</td>
                                  <td className="">{CommonUtils.datePipe(tender.createdDate, "dd/MM/yyyy")}</td>
                                  <td className="">{SessionCache.getUserType() === "VENDOR" ? tender?.buyer?.name : tender?.vendor?.name}</td>
                                  <td className="text-center">
                                    <div className={`d-inline-block bid-status-badge ${getClassForStatus(tender.status)}`}><span className={getClassForStatus(tender.status)}>{getStatusText(tender.status)}</span></div>
                                  </td>
                                  <td className="text-center"> <span className="text-primary cursor-pointer p-2" onClick={(e) => onViewTenderBidDetail(e, tender)}>View </span></td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>


                      {
                        pageObject?.totalPages ?
                          <Pagination
                            startIndex={pageObject?.startIndex || 1}
                            selectedPage={pageObject?.currentPage || 1}
                            totalPages={pageObject?.totalPages || 0}
                            onPageSelected={onPageSelected.bind(this)} /> : ""
                      }
                    </div>
                    : !isLoading ?
                      <NoRecords /> : ""
                }
              </div>
          }
        </div>
      </div>
    </>
  );
};

export default ActiveBids;
